export function SET_REPORT_TAB(payload) {
	return {
		type: 'SET_REPORT_TAB',
		payload: payload,
	};
}

export function SET_REPORT_SELECTED_CLIENT(payload) {
	return {
		type: 'SET_REPORT_SELECTED_CLIENT',
		payload: payload,
	};
}

export function SET_REPORT_SELECTED_ORGANIZATION(payload) {
	return {
		type: 'SET_REPORT_SELECTED_ORGANIZATION',
		payload: payload,
	};
}
