import React from 'react'
import { DebounceInput } from 'react-debounce-input'
import { useTranslation } from 'react-i18next'

function FilterSearch({ filterData, setFilterData, reduxFilterSearch }) {
	const { t } = useTranslation()

	async function requestToServer(value) {
		if (reduxFilterSearch) {
			if (value.length === 0 || value.length > 2) {
				setFilterData({ ...filterData, 'search': value, 'random': filterData?.random + 1 })
			}
			return;
		}
		if (value.length === 0 || value.length > 2) {
			setFilterData({ ...filterData, 'search': value })
		}
	};

	return (
		<div className="position-relative me-2">
			<i className="uil-search input-search-icon"></i>
			<DebounceInput
				value={filterData.search}
				type="text"
				className="form-control"
				placeholder={t('SEARCH') + '...'}
				minLength={3}
				debounceTimeout={1000}
				onChange={(e) => requestToServer(e.target.value)}
				onKeyUp={(e) => {
					if (e.keyCode === 13) {
						requestToServer(e.target.value)
					}
				}}
			/>
		</div>
	)
}

export default FilterSearch
