import React, { useState } from "react"
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { GUESTPOST } from 'helpers/api'

function ForgotPassword() {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const [showActivationCode, setShowActivationCode] = useState(true)
	const [activeInput, setActiveInput] = useState('')
	const [user, setUser] = useState({
		ownerLogin: ""
	})

	async function getActivationCode() {
		await GUESTPOST('/services/uaa/api/account/reset-password/init', user, { loader: true })
		setShowActivationCode(!showActivationCode)
	}

	async function changePassword() {
		const response = await GUESTPOST('/services/uaa/api/account/reset-password/finish', user, { loader: true })
		if (response) {
			toast.success(t('SUCCESS') + ': ' + t('DATA_SUCCESFULLY_CHANGED'))
			navigate("/login")
		}
	}

	return (
		<>
			<div className="authentication-bg">
				<div className="auth-card">
					<div className="text-center my-2">
						<h3 className="">{t('RECOVER_YOUR_PASSWORD')}</h3>
					</div>
					<form className="form-horizontal" action="">
						{showActivationCode ?
							<div className="position-relative">
								<label htmlFor="ownerLogin" className={"auth-label " + (activeInput === 'ownerLogin' || user.ownerLogin ? 'active' : '')}>{t('LOGIN')}</label>
								<input className="login-input"
									autoComplete="off"
									name="ownerLogin"
									value={user.ownerLogin}
									onFocus={() => setActiveInput('ownerLogin')}
									onBlur={() => setActiveInput('')}
									onChange={(e) => setUser({ ...user, 'ownerLogin': e.target.value })} />
								<div className="text-center">
									<button type="button" className="login-button" onClick={getActivationCode}>
										{t('GET_SMS_CODE')}
									</button>
								</div>
							</div>
							:
							<div className="p-2">

								<div className="position-relative">
									<label htmlFor="key" className={"auth-label " + (activeInput === 'key' || user.key ? 'active' : '')}>{t('ACTIVATION_CODE')}</label>
									<input className="login-input"
										autoComplete="off"
										name="key"
										value={user.key}
										onFocus={() => setActiveInput('key')}
										onBlur={() => setActiveInput('')}
										onChange={(e) => setUser({ ...user, 'key': e.target.value })} />
								</div>

								<div className="position-relative">
									<label htmlFor="newPassword" className={"auth-label " + (activeInput === 'newPassword' || user.newPassword ? 'active' : '')}>{t('NEW_PASSWORD')}</label>
									<input className="login-input"
										autoComplete="off"
										name="newPassword"
										value={user.newPassword}
										onFocus={() => setActiveInput('newPassword')}
										onBlur={() => setActiveInput('')}
										onChange={(e) => setUser({ ...user, 'newPassword': e.target.value })} />
								</div>

								<div className="position-relative">
									<label htmlFor="repeatPassword" className={"auth-label " + (activeInput === 'repeatPassword' || user.repeatPassword ? 'active' : '')}>{t('CONFIRM_PASSWORD')}</label>
									<input className="login-input"
										autoComplete="off"
										name="repeatPassword"
										value={user.repeatPassword}
										onFocus={() => setActiveInput('repeatPassword')}
										onBlur={() => setActiveInput('')}
										onChange={(e) => setUser({ ...user, 'repeatPassword': e.target.value })} />
								</div>

								<div className="text-center">
									<button type="button" className="login-button"
										onClick={changePassword}
									>
										{t('CHANGE_PASSWORD')}
									</button>
								</div>
							</div>
						}

						<div className="mt-2 text-center">
							<Link to="/login" className="fw-medium">{t('LOGGING_IN')}</Link>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default ForgotPassword
