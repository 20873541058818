import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { useForm, Controller } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup'
import { validationSchema } from './validation'

import { PGET, GET, POST, PUT } from 'helpers/api'
import { checkRole, findFromArrayById } from 'helpers/helpers'

function Index() {
	const URL = '/services/web/api/expense-pageList'
	const { t } = useTranslation()

	const { register, handleSubmit, formState, control, setValue, getValues, reset } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: {}
	});
	const { errors } = formState

	const canCreate = checkRole('POS_PROMOTION_ADD')
	const canUpdate = checkRole('POS_PROMOTION_EDIT')

	const settings = useSelector(state => state.settings)
	const reduxAccount = useSelector(state => state.account)

	const [showModal, setShowModal] = useState(false)
	const [pageCount, setPageCount] = useState(0)

	const [poses, setPoses] = useState([])
	const [products, setProducts] = useState([])

	const [data, setData] = useState([])

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }

		var response;
		if (validatedData.id) {
			response = await PUT('/services/web/api/promotion', sendData, { loader: true })
		} else {
			response = await POST('/services/web/api/promotion', sendData, { loader: true })
		}

		if (response) {
			getData()
			setShowModal(false)
			reset()
		}
	}

	async function changeStatus(e, index) {
		var dataCopy = [...data]
		dataCopy[index][e.target.name] = e.target.checked
		setData(dataCopy)
		var sendData = {
			id: dataCopy[index]['id'],
			activated: e.target.checked,
		}
		await PUT(`/services/web/api/promotion-activate`, sendData, { loader: true })
	}

	async function openModal(item) {
		for (const [key, value] of Object.entries(item)) {
			setValue(key, value)
		}
		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
		reset()
	}

	async function getData() {
		const response = await PGET(`/services/web/api/promotion-pageList/${reduxAccount.posId}`, {}, { loader: true })
		if (response && response.data) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	async function getProducts() {
		const response = await GET('/services/web/api/product-helper')
		if (response) {
			setProducts(response)
		}
	}

	async function paginate(data) {
		const response = await PGET(`/services/web/api/promotion-pageList/${reduxAccount.posId}`, { page: data.selected, size: settings.size }, { loader: true })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
		getPoses()
		getProducts()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('PROMOTION')}
				</h4>
				<div className="d-flex">
					{canCreate &&
						<button className="btn-rounded btn btn-primary" onClick={() => setShowModal(true)}>
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</button>
					}
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th>{t('NAME')}</th>
									<th>{t('PRODUCT')}</th>
									<th>{t('BARCODE')}</th>
									<th>{t('QUANTITY')}</th>
									<th>{t('FOR_PRESENT')}</th>
									<th>{t('FOR_PRESENT')} {t('BARCODE').toLowerCase()}</th>
									<th>{t('QUANTITY')}</th>
									<th className="text-center">{t('ACTIVATED')}</th>
									<th className="text-end">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{findFromArrayById(poses, item?.posId)}</td>
										<td>{item?.name}</td>
										<td>{findFromArrayById(products, item?.productId)}</td>
										<td>{item?.productBarcode}</td>
										<td>{item?.quantity}</td>
										<td>{findFromArrayById(products, item?.promotionProductId)}</td>
										<td>{item?.promotionProductBarcode}</td>
										<td>{item?.promotionQuantity}</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="form-check form-switch form-switch-lg">
													<input type="checkbox" className="form-check-input"
														name="activated"
														checked={item.activated}
														onChange={(e) => changeStatus(e, index)} />
												</div>
											</div>
										</td>
										<td className="d-flex justify-content-end">
											{canUpdate &&
												<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
													<i className="uil-edit-alt"></i>
												</div>
											}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{getValues('id') ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="form-group">
							<label>{t('POS')}<span className="required-mark">*</span></label>
							<Controller
								name="packageCode"
								control={control}
								render={({ field }) =>
									<Select
										tabIndex={3}
										{...field}
										options={poses}
										value={poses.find(option => option.id === getValues('posId'))}
										onChange={(option) => setValue('posId', option.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>}
							/>
						</div>
						<div className="form-group">
							<label>{t('NAME')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name" {...register('name')} />
							<span className="text-danger text-sm">{errors.name?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('PRODUCT')}<span className="required-mark">*</span></label>
							<Controller
								name="productId"
								control={control}
								render={({ field }) =>
									<Select
										tabIndex={3}
										{...field}
										options={products}
										value={products.find(option => option.id === getValues('productId'))}
										onChange={(option) => setValue('productId', option.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.nameBarcode}
										getOptionValue={(option) => option.id}
									/>}
							/>
						</div>
						<div className="form-group">
							<label>{t('QUANTITY')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="quantity" {...register('quantity')} />
							<span className="text-danger text-sm">{errors.quantity?.message}</span>
						</div>
						<div className="form-group">
							<label>{t('PRODUCT')}<span className="required-mark">*</span></label>
							<Controller
								name="promotionProductId"
								control={control}
								render={({ field }) =>
									<Select
										tabIndex={3}
										{...field}
										options={products}
										value={products.find(option => option.id === getValues('promotionProductId'))}
										onChange={(option) => setValue('promotionProductId', option.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.nameBarcode}
										getOptionValue={(option) => option.id}
									/>}
							/>
						</div>
						<div className="form-group">
							<label>{t('QUANTITY')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="promotionQuantity" {...register('promotionQuantity')} />
							<span className="text-danger text-sm">{errors.promotionQuantity?.message}</span>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
