import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import XLSX from 'xlsx';
import { toast } from 'react-toastify'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { PGET, GET, POST } from 'helpers/api'
import { formatMoney } from 'helpers/helpers'
import { SET_REPORT_SELECTED_ORGANIZATION } from "store/report/actions"

function Index({ selectReportType }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const URL = "/services/web/api/report-organization-pageList"

	const settings = useSelector(state => state.settings)

	const paymentTypes = [{ 'id': 1, 'name': t('CASH') }, { 'id': 3, 'name': t('MONEY_TRANSFER') }]
	const amountTypes = [{ 'id': 2, 'name': t('OUT_BALANCE') }, { 'id': 1, 'name': t('IN_BALANCE') }]
	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]

	const [modalPayment, setModalPayment] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [pageCount, setPageCount] = useState(0)
	const [filterUrl, setFilterUrl] = useState('')
	const [totalAmount, setTotalAmont] = useState([])
	const [posId, setPosId] = useState(0)
	const [poses, setPoses] = useState([])
	const [data, setData] = useState([])

	function selectItem(item) {
		var itemCopy = { ...item }
		itemCopy.paymentTypeId = 1
		itemCopy.amountTypeId = 2
		itemCopy.currencyId = 1
		itemCopy.amountIn = ''
		itemCopy.amountOut = 0
		setSelectedItem(itemCopy)
	}

	async function payment(e) {
		e.preventDefault();

		var selectedItemCopy = { ...selectedItem }

		if (selectedItemCopy.amountTypeId === 2) {
			selectedItemCopy.amountOut = selectedItemCopy.amountIn
			selectedItemCopy.amountIn = 0
		}

		var sendData = {}
		sendData.amountIn = selectedItemCopy.amountIn
		sendData.amountOut = selectedItemCopy.amountOut
		sendData.currencyId = selectedItemCopy.currencyId
		sendData.organizationId = selectedItemCopy.organizationId
		sendData.paymentTypeId = selectedItemCopy.paymentTypeId
		sendData.posId = selectedItemCopy.posId
		sendData.note = selectedItemCopy.note

		var response = await POST('/services/web/api/organization-payment', sendData, { loader: true })

		if (response) {
			showPaymentModal(false)
			getData()
		}
	}

	function showPaymentModal(bool) {
		if (!bool) {
			var selectedItemCopy = { ...selectedItem }
			selectedItemCopy.paymentTypeId = 1
			selectedItemCopy.amountTypeId = 1
			selectedItemCopy.currencyId = 1
			selectedItemCopy.amountIn = ''
			selectedItemCopy.amountOut = 0
		}

		if (!selectedItem?.organizationId) {
			toast.warning(t('NO_SUPPLIER_SELECTED'))
			return
		}

		setModalPayment(bool)
	}

	function redirectToInner(item) {
		dispatch(SET_REPORT_SELECTED_ORGANIZATION(item))
		selectReportType(13)
	}

	async function exportToExcel() {
		const response = await GET('/services/web/api/report-organization-excel')

		var excelHeaders = [[t('ORGANIZATION'), t('POS'), t('DEBT') + (' (Сум)'), t('DEBT') + (' (USD)')]]
		for (let i = 0; i < response.length; i++) {
			var excelItems = []
			excelItems.push(response[i].organizationName)
			excelItems.push(response[i].posName)
			for (let j = 0; j < response[i]['balanceList'].length; j++) {
				if (response[i]['balanceList'].length === 1) {
					if (response[i]['balanceList'][j]['currencyName'] === "USD") {
						excelItems.push(0)
						excelItems.push(response[i]['balanceList'][j]['totalAmount'])
					}
					if (response[i]['balanceList'][j]['currencyName'] === "Сум") {
						excelItems.push(response[i]['balanceList'][j]['totalAmount'])
						excelItems.push(0)
					}
				}
				if (response[i]['balanceList'].length === 2) {
					if (response[i]['balanceList'][j]['currencyName'] === "USD") {
						excelItems.push(response[i]['balanceList'][j]['totalAmount'])
					}
					if (response[i]['balanceList'][j]['currencyName'] === "Сум") {
						excelItems.push(response[i]['balanceList'][j]['totalAmount'])
					}
				}
			}

			excelHeaders.push(excelItems)
		}
		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('SUPPLIERS') + ".xlsx");
	}

	async function paginate(data) {
		const response = await PGET(URL + filterUrl, { page: data.selected, size: settings.size })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);

		const temporaryTotalAmount = await GET('/services/web/api/report-organization-total?search=' + searchValue)
		setTotalAmont(temporaryTotalAmount)

		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	async function getData(selectedPosId) {
		var filterUrl = ''
		if (selectedPosId) {
			filterUrl = `?posId=${selectedPosId}`
			setFilterUrl(filterUrl)
		}
		const response = await PGET(`/services/web/api/report-organization-pageList${filterUrl}`, {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}

		const temporaryTotalAmount = await GET(`/services/web/api/report-organization-total${filterUrl}`)
		setTotalAmont(temporaryTotalAmount)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			response.unshift({ 'id': 0, 'name': t('ALL') })
			setPoses(response)
		}
	}

	async function selectPos(option) {
		if (option === 0) {
			getData()
		} else {
			getData(option.id)
			setPosId(option.id)
		}
	}

	useEffect(() => {
		getData()
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex">
							<Search url={URL} search={search} getData={getData}></Search>
							<div>
								<button className="btn btn-outline-primary btn-wide me-2" onClick={showPaymentModal}>
									{t('PAY')}
								</button>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide me-2" onClick={exportToExcel}>
									EXCEL
								</button>
							</div>
							<div style={{ 'width': '207px' }}>
								<Select
									classNamePrefix="react-select"
									options={poses}
									value={poses.find(option => option.id === posId)}
									onChange={(option) => selectPos(option)}
									placeholder=""
									noOptionsMessage={() => t('LIST_IS_EMPTY')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
						</div>
						<div className="d-flex">
							<div className="vertical-center me-2">
								<h4>
									<b>{t('TOTAL_AMOUNT')}</b>:
								</h4>
							</div>
							<div className="vertical-center">
								{totalAmount.map((item, index) => (
									<h5 className="text-end" key={index}>
										<span className="me-1">{formatMoney(item.totalAmount)}</span>
										<b>{item.currencyName}</b>
									</h5>
								))
								}
							</div>
						</div>
					</div>

					<div className="table-responsive">
						<table className="table table-hover cursor mb-0">
							<thead>
								<tr>
									<th>{t('SUPPLIER')}</th>
									<th>{t('POS')}</th>
									<th className="text-center">{t('STATUS')}</th>
									<th className="text-end">{t('AMOUNT')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr className={'tr-middle ' + (
										(selectedItem &&
											selectedItem.posId === item.posId &&
											selectedItem.organizationId === item.organizationId) ? 'table-tr-active' : '')}
										key={index} onClick={() => selectItem(item)}>
										<td>
											<span className="text-primary cursor table-first-column-clickable"
												onClick={() => redirectToInner(item)}>
												{item.rowNum}. {item.organizationName}
											</span>
										</td>
										<td>{item.posName}</td>
										<td className="text-center">
											{item.expired ?
												<span className="bg-danger bg-border-square"></span>
												:
												<span></span>
											}
										</td>
										<td className="text-end">
											{item?.balanceList?.map((item, index) => (
												<div className={item.totalAmount > 0 ? 'text-primary' : 'text-danger'} key={index}>
													<span className="me-1">{formatMoney(item.totalAmount)}</span>
													<b>{item.currencyName}</b>
												</div>
											))
											}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* MODAL PAYMENT */}
			<Modal show={modalPayment} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => showPaymentModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('PAY')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<span>{t('POS')}</span>
						<span>{selectedItem.posName}</span>
					</div>
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<span>{t('ORGANIZATION')}</span>
						<span>{selectedItem.organizationName}</span>
					</div>
					<form onSubmit={payment} autoComplete="off">
						<div className="form-group">
							<label>{t('PAYMENT_METHOD')}</label>
							<Select
								classNamePrefix="react-select"
								options={paymentTypes}
								value={paymentTypes.find(option => option.id === selectedItem.paymentTypeId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'paymentTypeId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('CHOOSE_PAYMENT_TYPE')}</label>
							<Select
								classNamePrefix="react-select"
								options={currencies}
								value={currencies.find(option => option.id === selectedItem.currencyId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'currencyId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('CHOOSE_PAYMENT_TYPE')}</label>
							<Select
								classNamePrefix="react-select"
								options={amountTypes}
								value={amountTypes.find(option => option.id === selectedItem.amountTypeId)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'amountTypeId': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="form-group">
							<label>{t('AMOUNT')}<span className="required-mark">*</span></label>
							<input type="number" className="form-control" name="amountIn" autoFocus
								onChange={(e) => setSelectedItem({ ...selectedItem, 'amountIn': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('NOTE')}</label>
							<input type="text" className="form-control" name="note"
								onChange={(e) => setSelectedItem({ ...selectedItem, 'note': e.target.value })} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2"
								onClick={() => showPaymentModal(false)} tabIndex="-1">{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100" disabled={!selectedItem.amountIn && !selectedItem.amountOut}>
								{t('SAVE')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT */}
		</>
	)
}

export default Index
