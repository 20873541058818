import React, { useEffect, useState } from "react"
import { useNavigate, Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'

import TableControl from 'pages/parts/TableControl'

import { GET, PUT } from 'helpers/api'
import { formatMoney, formatDate } from 'helpers/helpers'

function Preview() {
	const { t } = useTranslation()
	const { id } = useParams();
	const navigate = useNavigate();

	const reduxSettings = useSelector(state => state.settings)

	const URL = "/services/web/api/transfer/" + id

	const [totalAmount, setTotalAmount] = useState({ divergence: 0, differenceAmount: 0 })
	const [data, setData] = useState({})
	const [modalConfirm, setModalConfirm] = useState(false)
	const [modalTableControl, setModalTableControl] = useState(false)

	function handleProductListChange(e, index) {
		var dataCopy = { ...data }
		dataCopy.productList[index][e.target.name] = e.target.value
		setData(dataCopy)

		countTotalAmount(dataCopy)
	}

	function countTotalAmount(dataCopyFrom) {
		var dataCopy = { ...dataCopyFrom }

		var temporaryTotalAmount = 0
		var temporaryTotalAmount2 = 0
		for (let i = 0; i < dataCopy.productList.length; i++) {
			if (dataCopy.productList[i]['quantity'] && dataCopy.productList[i]['price']) {
				temporaryTotalAmount += dataCopy.productList[i]['quantity'] * dataCopy.productList[i]['price']
				temporaryTotalAmount2 += dataCopy.productList[i]['actualBalance'] * dataCopy.productList[i]['salePrice']
				dataCopy.productList[i]['divergence'] = dataCopy.productList[i]['actualBalance'] - dataCopy.productList[i]['quantity']
				dataCopy.productList[i]['differenceAmount'] = (dataCopy.productList[i]['actualBalance'] - dataCopy.productList[i]['quantity']) * dataCopy.productList[i]['price']
			}
		}

		setTotalAmount({ divergence: temporaryTotalAmount, differenceAmount: temporaryTotalAmount2 })
		setData(dataCopy)
	}

	async function update() {
		const response = await PUT('/services/web/api/transfer-received', data, { loader: true })
		if (response) {
			navigate('/transfer')
		}
	}

	async function close() {
		const response = await PUT('/services/web/api/transfer-completed', data, { loader: true })
		if (response) {
			navigate('/transfer')
		}
	}

	async function getData() {
		const response = await GET(URL)
		if (response) {
			for (let i = 0; i < response['productList'].length; i++) {
				if (response['productList'][i]['actualBalance'] === 0) {
					response['productList'][i]['actualBalance'] = response['productList'][i]['quantity']
				}
			}

			setData(response)
			countTotalAmount(response)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('ORDERS')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('PREVIEW')}</h6>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<h4 className="fw-600 mb-3">{t('DOCUMENT_FROM')} {formatDate(data.createdDate)}</h4>
					<div className="d-flex flex-wrap">
						<div className="me-3">
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('SENDER_POS')}:</div>
								<div className="w-100 text-end text-nowrap">{data.fromPosName}</div>
							</div>
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('RECIPIENT_POS')}:</div>
								<div className="w-100 text-end text-nowrap">{data.toPosName}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped">
							<thead>
								<tr>
									<th>{t('NAME_OF_PRODUCT')}</th>
									<th className="text-center">{t('BARCODE')}</th>
									{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.artikul) &&
										<th className="text-center">{t('ARTIKUL')}</th>
									}
									<th className="text-center">{t("ADMISSION_PRICE")}</th>
									{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.wholesalePrice) &&
										<th className="text-center">{t('WHOLESALE_PRICE')}</th>
									}
									{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.bankPrice) &&
										<th className="text-center">{t('BANK_PRICE')}</th>
									}
									<th className="text-center">{t("UNIT_PRICE")}</th>
									{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.serial) &&
										<th className="text-center">{t('SERIAL')}</th>
									}
									{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.expDate) &&
										<th className="text-center">{t('EXPIRATION_DATE')}</th>
									}
									{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.vat) &&
										<th className="text-center">{t('VAT')}</th>
									}
									<th className="text-center">{t('SENDED')}</th>
									<th className="text-center">{t('TOTAL_AMOUNT')}</th>
									<th className="text-center">{t('RECEIVED')}</th>
									<th className="text-center">{t('DIVERGENCE')}</th>
									<th className="text-center table-control">
										<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
										{t('COST_DIFFERENCE')}
									</th>
								</tr>
							</thead>
							<tbody>
								{data?.productList?.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}. {item.productName}</td>
										<td className="text-center">{item.productBarcode}</td>
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.artikul) &&
											<td className="text-center">{item.productArtikul}</td>
										}
										<td className="text-center">{formatMoney(item.price)}</td>
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.wholesalePrice) &&
											<td className="text-center">
												{data.status === 0 ?
													<input type="number" className={"auto-width-input " + (!item.wholesalePrice && "required-border")}
														name="wholesalePrice" value={item.wholesalePrice}
														onChange={(e) => {
															handleProductListChange(e, index)
														}} />
													:
													<span>{formatMoney(item.wholesalePrice)}</span>
												}
											</td>
										}
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.bankPrice) &&
											<td className="text-center">
												{data.status === 0 ?
													<input type="number" className={"auto-width-input " + (!item.bankPrice && "required-border")}
														name="bankPrice" value={item.bankPrice}
														onChange={(e) => {
															handleProductListChange(e, index)
														}} />
													:
													<span>{formatMoney(item.bankPrice)}</span>
												}
											</td>
										}
										<td className="text-center">
											{data.status === 0 ?
												<input type="number" className={"auto-width-input " + (!item.salePrice && "required-border")}
													name="salePrice" value={item.salePrice}
													onChange={(e) => {
														handleProductListChange(e, index)
													}} />
												:
												<span>{formatMoney(item.salePrice)}</span>
											}
										</td>
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.serial) &&
											<td className="text-center">{item.serial}</td>
										}
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.expDate) &&
											<td className="text-center">{formatDate(item.expDate)}</td>
										}
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.vat) &&
											<td className="text-center">{item.vat}</td>
										}
										<td className="text-center">{formatMoney(item.quantity)} {item.uomName}</td>
										<td className="text-center">{formatMoney(item.quantity * item.price)}</td>
										<td className="text-center">
											{data.status === 0 ?
												<input type="number" className={"auto-width-input " + (!item.actualBalance && "required-border")}
													name="actualBalance" value={item.actualBalance}
													onChange={(e) => {
														if (e.target.value <= item.quantity) {
															handleProductListChange(e, index)
														}
													}} />
												:
												<span>{formatMoney(item.actualBalance)}</span>
											}
										</td>
										<td className="text-center">{formatMoney(item.divergence)}</td>
										<td className="text-center">{formatMoney(item.differenceAmount)}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<div className="w-100 d-flex justify-content-end">
						<div className="flex-column w-25">
							<h5 className="d-flex justify-content-between">
								<strong className="me-2">{t('RECEIPT_AMOUNT')}:</strong>{formatMoney(totalAmount.divergence)}
							</h5>
							<h5 className="d-flex justify-content-between">
								<strong className="me-2">{t('SALE_AMOUNT')}:</strong>{formatMoney(totalAmount.differenceAmount)}
							</h5>
						</div>
					</div>

					<div className="d-flex justify-content-end mt-5">
						<div>
							<Link className="btn btn-warning btn-rounded btn-wide me-2" to="/transfer">
								{t('CANCEL')}
							</Link>
							{data.status === 0 &&
								<button className="btn btn-primary btn-rounded btn-wide" onClick={() => setModalConfirm(true)}>
									{t('ACCEPT')}
								</button>
							}
							{((data.status === 1 || data.status === 2) && data.received) &&
								<button className="btn btn-primary btn-rounded btn-wide" onClick={() => setModalConfirm(true)}>
									{t('CLOSE')}
								</button>
							}
						</div>
					</div>
				</div>
			</div>

			<Modal show={modalConfirm} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalConfirm(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('CONFIRM')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('ARE_YOU_SURE_YOU_WANT')}?
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setModalConfirm(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => {
							if (data.status === 0) {
								update()
							}
							if (data.status === 1 && data.received) {
								close()
							}
						}}>
							{t('CONFIRM')}
						</button>
					</div>
				</Modal.Footer>
			</Modal>

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableTransfer'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Preview
