export function SET_PAGINATION_SIZE(payload) {
	return {
		type: 'SET_PAGINATION_SIZE',
		payload: payload,
	};
}

export function SET_DECIMAL_POINT(payload) {
	return {
		type: 'SET_DECIMAL_POINT',
		payload: payload,
	};
}

export function SET_SETTINGS(payload) {
	return {
		type: 'SET_SETTINGS',
		payload: payload,
	};
}

export function SET_DOCUMENTS_IN_CREATE(payload) {
	return {
		type: 'SET_DOCUMENTS_IN_CREATE',
		payload: payload,
	};
}

export function SET_REMEMBER_LAST_DOCUMENTIN_PREVIEW(payload) {
	return {
		type: 'SET_REMEMBER_LAST_DOCUMENTIN_PREVIEW',
		payload: payload,
	};
}

export function SET_LOCK_SCREEN() {
  return {
    type: 'SET_LOCK_SCREEN',
  };
}

export function SET_UNLOCK_SCREEN() {
  return {
    type: 'SET_UNLOCK_SCREEN',
  };
}