import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { DebounceInput } from 'react-debounce-input'
import Select from 'react-select'
import { toast } from 'react-toastify'

import { GET, POST, PUT } from 'helpers/api'

function CreateUpdate() {
	const { paramsPosId, paramsProductGroupId } = useParams();

	const { t } = useTranslation();
	const navigate = useNavigate();

	const reduxSettings = useSelector(state => state.settings)

	const [posId, setPosId] = useState([])
	const [poses, setPoses] = useState([])
	const [search, setSearch] = useState('')
	const [searchChild, setSearchChild] = useState('')
	const [searchDataExist, setSearchDataExist] = useState(false)
	const [searchData, setSearchData] = useState([])
	const [searchDataChild, setSearchDataChild] = useState([])
	const [data, setData] = useState([])
	const [dataChild, setDataChild] = useState([])

	async function create() {
		var sendData = {
			'posId': null,
			'productGroupId': null,
			'productList': [],
		}
		sendData.posId = posId
		sendData.productDB = data[0]['productDB']
		sendData.productGroupName = data[0]['productName']
		sendData.productGroupId = data[0]['productId']
		sendData.productGroupBarcode = data[0]['productBarcode']
		sendData.productUomCode = data[0]['productUomCode']
		sendData.productCategoryCode = data[0]['productCategoryCode']

		for (let i = 0; i < dataChild.length; i++) {
			sendData.productList.push({
				'productId': dataChild[i]['productId'],
				'productBarcode': dataChild[i]['productBarcode'],
				'productName': dataChild[i]['productName'],
				'productGroupName': data[0]['productName'],
				'productUomCode': dataChild[i]['productUomCode'],
				'productCategoryCode': dataChild[i]['productCategoryCode'],
				'productDB': dataChild[i]['productDB'],
				'id': dataChild[i]['id'] ? dataChild[i]['productId'] : null,
			})
		}

		if (paramsPosId) {
			const response = await PUT('/services/web/api/product-group', sendData, { loader: true })
			if (response) {
				navigate('/grouping')
			}
		} else {
			const response = await POST('/services/web/api/product-group', sendData, { loader: true })
			if (response) {
				navigate('/grouping')
			}
		}
	}

	async function searchProduct(value) {
		setSearchDataExist(0)
		setSearch(value)
		if (value.length === 0) {
			setSearchData([])
			setSearchDataExist(0)
		} else {
			const response = await GET(
				"/services/web/api/product-group-parent-helper/" + posId,
				{ 'search': value },
				{ loader: true }
			)

			if (response.length === 0) {
				setSearchDataExist(1)
				return
			}

			if (/^\d+$/.test(value) && response.length === 1) {
				setSearchData(response)
				addToList(0, response)
				setSearch('')
			} else {
				setSearchData(response)
			}
		}
	}

	async function searchProductChild(value) {
		setSearchChild(value)
		if (value.length === 0) {
			setSearchDataChild([])
		} else {
			var response = await GET(
				"/services/web/api/product-group-child-helper/" + posId,
				{ 'search': value },
				{ loader: true }
			)

			if (response.length > 0) {
				// new product
				var productExistOnResponse = false
				for (let i = 0; i < response.length; i++) {
					if (response[i]['productBarcode'] !== value) {
						productExistOnResponse = true
					}
				}

				if (productExistOnResponse) {
					response.unshift({
						productBarcode: value,
						productDB: false,
						productName: value
					})
				}
				// new product

				response = response.filter(e => e.productBarcode !== data[0]['productBarcode'])
			}

			var responseCopy = [...response]
			var childDataCopy = [...dataChild]
			for (let i = 0; i < childDataCopy.length; i++) {
				responseCopy = responseCopy.filter(e => e.productBarcode !== childDataCopy[i]['productBarcode'])
			}
			response = [...responseCopy]

			if (response.length === 0) {
				var productExistNoResponse = false
				if (value !== data[0]['productBarcode']) {
					productExistNoResponse = true
				}
				if (productExistNoResponse) {
					for (let i = 0; i < childDataCopy.length; i++) {
						if (childDataCopy[i]['productBarcode'] === value) {
							productExistNoResponse = false
						}
					}
					if (!productExistNoResponse) {
						toast.error(t('ERROR') + ': ' + t('PRODUCT_ADDED'))
					}
				}
				if (productExistNoResponse) {
					response.push({
						productBarcode: value,
						productDB: false,
						productName: value
					})
					addToListChild(0, response)
					setSearchChild('')
				}
				return
			}

			if (/^\d+$/.test(value) && response.length === 1) {
				setSearchDataChild(response)
				addToListChild(0, response)
				setSearchChild('')
			} else {
				setSearchDataChild(response)
			}
		}
	}

	function addToList(index, arr = []) {
		var searchDataCopy;
		if (arr.length > 0) {
			searchDataCopy = arr
		} else {
			searchDataCopy = [...searchData]
		}

		var dataCopy = [...data]
		dataCopy.push(searchDataCopy[index])

		setData(dataCopy)
		setSearchData([])
		setSearch('')
	}

	function addToListChild(index, arr = []) {
		var searchDataCopy;
		if (arr.length > 0) {
			searchDataCopy = arr
		} else {
			searchDataCopy = [...searchDataChild]
		}

		var dataCopy = [...dataChild]
		dataCopy.push(searchDataCopy[index])

		setDataChild(dataCopy)


		searchDataCopy.splice(index, 1)
		setSearchDataChild(searchDataCopy)

		setSearch('')
	}

	function deleteParent() {
		setData([])
	}

	function deleteChild(index) {
		var dataChildCopy = [...dataChild]
		dataChildCopy.splice(index, 1)
		setDataChild(dataChildCopy)
	}

	async function getData() {
		const response = await GET(`/services/web/api/product-group/${paramsPosId}/${paramsProductGroupId}`, {}, { loader: true })
		if (response) {
			setDataChild(response.productList)
			var temporaryArray = [{
				productId: response.productGroupId,
				productBarcode: response.productGroupBarcode,
				productName: response.productGroupName,
			}]
			setData(temporaryArray)
			setPosId(response.posId)
		}
	}

	async function getPoses() {
		const temporaryPoses = await GET('/services/web/api/pos-helper')
		if (temporaryPoses) {
			setPoses(temporaryPoses)
		}

		if (temporaryPoses.length > 0) {
			setPosId(reduxSettings.posId ? reduxSettings.posId : temporaryPoses[0]['id'])
		}
	}

	useEffect(() => {
		getPoses()
		if (paramsPosId) {
			getData()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('GROUPING')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{paramsPosId ? t('EDIT') : t('CREATE')}</h6>
					</div>
				</div>
			</div>

			<div className="row">
				<div className="col-md-6">
					<div className="card min-h500">
						<div className="card-body">
							<h3 className="mb-2">{t('SELECT_PRODUCT')}</h3>
							<hr />

							<div className="form-group">
								<Select
									isDisabled={data.length === 1}
									options={poses}
									value={poses.find(option => option.id === posId)}
									onChange={(option) => setPosId(option.id)}
									placeholder=""
									noOptionsMessage={() => t('LIST_IS_EMPTY')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>

							<div className="form-group position-relative mb-0">
								<DebounceInput
									disabled={data.length === 1}
									value={search}
									onChange={(e) => searchProduct(e.target.value)}
									type="text"
									className="form-control"
									placeholder={t('SEARCH') + '...'}
									debounceTimeout={1000}
								/>

								{searchData.length > 0 &&
									<i className="uil uil-times search-barcode" onClick={() => { setSearchData([]); setSearch('') }}></i>
								}

								{searchData.length > 0 &&
									<span className="dropdown-search-menu">
										<div className="dropdown-menu-list">
											<span className="dropdown-menu-result">
												<div className="table-responsive p-3">
													<table className="table-striped table">
														<thead>
															<tr>
																<th>{t('NAME_OF_PRODUCT')}</th>
																<th className="text-center">{t('BARCODE')}</th>
																<th className="text-center">{t('ACTION')}</th>
															</tr>
														</thead>
														<tbody>
															{searchData.map((item, index) => (
																<tr key={index}>
																	<td>{item.productName}</td>
																	<td className="text-center">{item.productBarcode}</td>
																	<td className="d-flex justify-content-center" onClick={() => addToList(index)}>
																		<div className="table-action-button table-action-primary-button">
																			<i className="uil uil-plus"></i>
																		</div>
																	</td>
																</tr>
															))
															}
														</tbody>
													</table>
												</div>
											</span>
										</div>
									</span>
								}

								{(searchData.length === 0 && searchDataExist === 1) &&
									<span className="dropdown-search-menu">
										<div className="dropdown-menu-list d-flex justify-content-between p-2">
											<span className="dropdown-menu-result fz20">{t('PRODUCT_NOT_FOUND')}</span>
										</div>
									</span>
								}

							</div>

							<div className="table-responsive">
								<table className="table">
									<thead>
										<tr>
											<th>{t('NAME_OF_PRODUCT')}</th>
											<th className="text-center">{t('BARCODE')}</th>
											{!paramsPosId &&
												<th className="text-center">{t('ACTION')}</th>
											}
										</tr>
									</thead>
									<tbody>
										{data.map((item, index) => (
											<tr key={index}>
												<td>{item.productName}</td>
												<td className="text-center">{item.productBarcode}</td>
												{!paramsPosId &&
													<td>
														<div className="d-flex justify-content-center" onClick={() => deleteParent(index)}>
															<div className="table-action-button table-action-danger-button" >
																<i className="uil-times"></i>
															</div>
														</div>
													</td>
												}
											</tr>
										))
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="card min-h500 p-3 vertical-between">
						<div className="h-100">
							<h3 className="mb-2">{t('PRODUCT_SELECTION')}</h3>
							<hr />
							<div className="form-group position-relative mb-0">
								<DebounceInput
									disabled={data.length === 0}
									value={searchChild}
									onChange={(e) => searchProductChild(e.target.value)}
									type="text"
									className="form-control"
									placeholder={t('SEARCH') + '...'}
									debounceTimeout={1000}
								/>

								{searchDataChild.length > 0 &&
									<i className="uil uil-times search-barcode" onClick={() => { setSearchDataChild([]); setSearchChild('') }}></i>
								}

								{searchDataChild.length > 0 &&
									<span className="dropdown-search-menu">
										<div className="dropdown-menu-list">
											<span className="dropdown-menu-result">
												<div className="table-responsive p-3">
													<table className="table-striped table">
														<thead>
															<tr>
																<th>{t('NAME_OF_PRODUCT')}</th>
																<th className="text-center">{t('BARCODE')}</th>
																<th className="text-center">{t('ACTION')}</th>
															</tr>
														</thead>
														<tbody>
															{searchDataChild.map((item, index) => (
																<tr key={index}>
																	<td>{item.productName}</td>
																	<td className="text-center">{item.productBarcode}</td>
																	<td className="d-flex justify-content-center">
																		<div className="table-action-button table-action-primary-button" onClick={() => addToListChild(index)}>
																			<i className="uil uil-plus"></i>
																		</div>
																	</td>
																</tr>
															))
															}
														</tbody>
													</table>
												</div>
											</span>
										</div>
									</span>
								}

							</div>

							<div className="table-responsive grouping-table">
								<table className="table">
									<thead>
										<tr>
											<th>{t('NAME_OF_PRODUCT')}</th>
											<th className="text-center">{t('BARCODE')}</th>
											<th className="text-center">{t('ACTION')}</th>
										</tr>
									</thead>
									<tbody>
										{dataChild.map((item, index) => (
											<tr key={index}>
												<td>{item.productName}</td>
												<td className="text-center">{item.productBarcode}</td>
												<td>
													<div className="d-flex justify-content-center">
														<div className="table-action-button table-action-danger-button" onClick={() => deleteChild(index)}>
															<i className="uil-times"></i>
														</div>
													</div>
												</td>
											</tr>
										))
										}
									</tbody>
								</table>
							</div>
						</div>

						<div className="d-flex flex-wrap items-center justify-content-end">
							<button type="button" className="btn btn-primary btn-rounded btn-wide"
								disabled={data.length === 0 || dataChild.length === 0}
								onClick={create}>
								{t("SAVE")}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate

