const directory = [
	{
		icon: "uil-book-open",
		title: 'GENERAL',
		show: false,
		children: [
			{
				icon: "uil-book-open",
				title: 'GENERAL',
				show: false,
				children: [
					{
						url: "/workers",
						i18n: "MY_USERS",
						icon: "uil-user-square",
						role: "MAIN_USERS"
					},
					{
						url: "/products",
						i18n: "PRODUCTS",
						icon: "uil-shopping-cart",
						role: "MAIN_PRODUCTS"
					},
					{
						url: "/suppliers",
						i18n: "SUPPLIERS",
						icon: "uil-truck-loading",
						role: "MAIN_ORGANIZATION"
					},
					{
						url: "/clients",
						i18n: "CLIENTS",
						icon: "uil-users-alt",
						role: "MAIN_CLIENTS"
					},
					{
						url: "/currency",
						i18n: "CURRENCY",
						icon: "uil-money-bill",
						role: "MAIN_CURRENCY"
					},
					{
						url: "/expense",
						i18n: "EXPENSES",
						icon: "uil-money-insert",
						role: "MAIN_EXPENSE"
					},
				]
			},
		]
	}
]

export default directory