import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'

import { SET_REPORT_TAB } from 'store/report/actions';
import Balance from './Balance'
import Incomes from './Incomes'
import WriteOff from './WriteOff'
import Sales from './Sales'
import Expenses from './Expenses'
import ExpirationDate from './ExpirationDate'
import ReturnedItems from './ReturnedItems'
import GrossIncome from './GrossIncome'
import CategoryManagement from './CategoryManagement'
import FrozenFunds from './FrozenFunds'
import RecommendedOrders from './RecommendedOrders'
import Organizations from './Organizations'
import OrganizationsInner from './OrganizationsInner'
import Clients from './Clients'
import ClientsInnerDebts from './ClientsInnerDebts'
import ClientsInnerCheques from './ClientsInnerCheques'
import SummaryKPI from './SummaryKPI'
import Summary from './Summary'

function Index() {
	const role = localStorage.getItem('role');

	const { t } = useTranslation()
	const dispatch = useDispatch()

	const reduxReport = useSelector(state => state.report)

	const [reportType, setReportType] = useState(reduxReport.reportTab)

	function selectReportType(type) {
		dispatch(SET_REPORT_TAB(type))
		setReportType(type)
	}

	useEffect(() => {
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{reportType === 1 &&
						<span>{t('BALANCE_REPORT')}</span>
					}
					{reportType === 2 &&
						<span>{t('INCOME_REPORT')}</span>
					}
					{reportType === 3 &&
						<span>{t('SALES_REPORT')}</span>
					}
					{reportType === 4 &&
						<span>{t('EXPENSE_REPORT')}</span>
					}
					{reportType === 5 &&
						<span>{t('EXPIRATION_DATE_REPORT')}</span>
					}
					{reportType === 6 &&
						<span>{t('RETURN_OF_GOODS')}</span>
					}
					{reportType === 7 &&
						<span>{t('GROSS_INCOME')}</span>
					}
					{reportType === 8 &&
						<span>{t('CATEGORY_MANAGEMENT')}</span>
					}
					{reportType === 9 &&
						<span>{t('FROZEN_FUNDS')}</span>
					}
					{reportType === 10 &&
						<span>{t('RECOMMENDED_ORDERS')}</span>
					}
					{reportType === 11 &&
						<span>{t('SUPPLIERS')}</span>
					}
					{reportType === 12 &&
						<span>{t('CLIENTS')}</span>
					}
					{reportType === 13 &&
						<div className="d-flex text-truncate">
							<h4 className="vertical-center cursor" onClick={() => selectReportType(11)}>
								<span className="text-primary cursor table-first-column-clickable">
									{t('SUPPLIERS')}
								</span>
							</h4>
							<div className="vertical-center mt-1">
								<i className="uil uil-angle-double-right fz-20"></i>
							</div>
							<div className="vertical-center mt-1">
								<h6>{reduxReport.reportSelectedOrganization.organizationName}</h6>
							</div>
						</div>
					}
					{reportType === 14 &&
						<div className="d-flex text-truncate">
							<h4 className="vertical-center cursor" onClick={() => selectReportType(12)}>
								<span className="text-primary cursor table-first-column-clickable">
									{t('CLIENTS')}
								</span>
							</h4>
							<div className="vertical-center mt-1">
								<i className="uil uil-angle-double-right fz-20"></i>
							</div>
							<div className="vertical-center mt-1">
								<h6>{reduxReport.reportSelectedClient.name}</h6>
							</div>
						</div>
					}
					{reportType === 15 &&
						<div className="d-flex text-truncate">
							<h4 className="vertical-center cursor" onClick={() => selectReportType(12)}>
								<span className="text-primary cursor table-first-column-clickable">
									{t('CLIENTS')}
								</span>
							</h4>
							<div className="vertical-center mt-1">
								<i className="uil uil-angle-double-right fz-20"></i>
							</div>
							<div className="vertical-center mt-1">
								<h6>{reduxReport.reportSelectedClient.name}</h6>
							</div>
						</div>
					}
					{reportType === 16 &&
						<span>{t('SUMMARY_REPORT')} 1</span>
					}
					{reportType === 17 &&
						<span>{t('TOTAL_REPORT')} 2</span>
					}
					{reportType === 18 &&
						<span>{t('WRITE_OFF_REPORT')}</span>
					}
				</h4>
				<div className="d-flex">
					<DropdownButton title={t('REPORT_TYPE')}>
						<Dropdown.Item onClick={() => selectReportType(1)}>
							{t('BALANCE_REPORT')}
						</Dropdown.Item>
						<Dropdown.Item onClick={() => selectReportType(2)}>
							{t('INCOME_REPORT')}
						</Dropdown.Item>
						<Dropdown.Item onClick={() => selectReportType(18)}>
							{t('WRITE_OFF_REPORT')}
						</Dropdown.Item>
						{(role === "ROLE_OWNER" || role === "ROLE_REPORT") &&
							<>
								<Dropdown.Item onClick={() => selectReportType(3)}>
									{t('SALES_REPORT')}
								</Dropdown.Item>
								<Dropdown.Item onClick={() => selectReportType(4)}>
									{t('EXPENSE_REPORT')}
								</Dropdown.Item>
							</>
						}
						<Dropdown.Item onClick={() => selectReportType(5)}>
							{t('EXPIRATION_DATE_REPORT')}
						</Dropdown.Item>
						{(role === "ROLE_OWNER" || role === "ROLE_REPORT") &&
							<>
								<Dropdown.Item onClick={() => selectReportType(6)}>
									{t('RETURN_OF_GOODS')}
								</Dropdown.Item>
								<Dropdown.Item onClick={() => selectReportType(7)}>
									{t('GROSS_INCOME')}
								</Dropdown.Item>
								<Dropdown.Item onClick={() => selectReportType(8)}>
									{t('CATEGORY_MANAGEMENT')}
								</Dropdown.Item>
							</>
						}
						<Dropdown.Item onClick={() => selectReportType(9)}>
							{t('FROZEN_FUNDS')}
						</Dropdown.Item>
						<Dropdown.Item onClick={() => selectReportType(10)}>
							{t('RECOMMENDED_ORDERS')}
						</Dropdown.Item>
						{role !== "ROLE_REPORT" &&
							<Dropdown.Item onClick={() => selectReportType(11)}>
								{t('SUPPLIERS')}
							</Dropdown.Item>
						}
						{(role === "ROLE_OWNER" || role === "ROLE_REPORT") &&
							<>
								{role !== "ROLE_REPORT" &&
									<Dropdown.Item onClick={() => selectReportType(12)}>
										{t('CLIENTS')}
									</Dropdown.Item>
								}
								<Dropdown.Item onClick={() => selectReportType(16)}>
									{t('SUMMARY_REPORT')} 1
								</Dropdown.Item>
								<Dropdown.Item onClick={() => selectReportType(17)}>
									{t('SUMMARY_REPORT')} 2
								</Dropdown.Item>
							</>
						}
					</DropdownButton>
				</div>
			</div>

			{reportType === 1 &&
				<Balance />
			}
			{reportType === 2 &&
				<Incomes />
			}
			{reportType === 3 &&
				<Sales />
			}
			{reportType === 4 &&
				<Expenses />
			}
			{reportType === 5 &&
				<ExpirationDate />
			}
			{reportType === 6 &&
				<ReturnedItems />
			}
			{reportType === 7 &&
				<GrossIncome />
			}
			{reportType === 8 &&
				<CategoryManagement />
			}
			{reportType === 9 &&
				<FrozenFunds />
			}
			{reportType === 10 &&
				<RecommendedOrders />
			}
			{reportType === 11 &&
				<Organizations selectReportType={selectReportType} />
			}
			{reportType === 12 &&
				<Clients selectReportType={selectReportType} />
			}
			{reportType === 13 &&
				<OrganizationsInner />
			}
			{reportType === 14 &&
				<ClientsInnerDebts />
			}
			{reportType === 15 &&
				<ClientsInnerCheques />
			}
			{reportType === 16 &&
				<SummaryKPI />
			}
			{reportType === 17 &&
				<Summary />
			}
			{reportType === 18 &&
				<WriteOff />
			}
		</>
	)
}

export default Index
