import React from "react";
import { Link } from "react-router-dom";

import SidebarContent from "./SidebarContent";
import logo from "assets/images/logo.svg";

function Sidebar() {
	return (
		<div className="vertical-menu">
			<div className="logo logo-dark text-center">
				<Link to='/dashboard'>
					<img src={logo} alt="logo" width="40" />
				</Link>
			</div>
			<div data-simplebar className="h-100">
				<SidebarContent />
			</div>
		</div>
	);
};

export default Sidebar