
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Select, { components } from 'react-select'
import FilterSearch from 'pages/parts/FilterSearch';
import { Dropdown } from 'react-bootstrap';
import DatePicker from "react-datepicker"

import useDidMountEffect from 'pages/parts/useDidMountEffect';

import { PGET, GET, DOWNLOAD_FILE } from 'helpers/api';
import { findFromArrayById, formatDate, formatDateBackend, formatMoney } from 'helpers/helpers';
import Pagination from 'pages/parts/Pagination';

function Abc() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const reduxAccount = useSelector(state => state.account)
	const reduxFilterData = useSelector(state => state.filterData)
	const reduxSettings = useSelector(state => state.settings)

	const URL = `/services/web/api/analytics-abc-pageList/`

	const [pageCount, setPageCount] = useState(0)

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [isOpen, setIsOpen] = useState(false)

	const [poses, setPoses] = useState([])
	const [categories, setCategories] = useState([])
	const [categoriesInitial, setCategoriesInitial] = useState([])
	const [data, setData] = useState([])

	const [filterData, setFilterData] = useState({
		posId: reduxAccount.posId,
		search: '',
		productCategoryId: '',
		dateBalance: '',
		page: 0,
		random: 0,
	})

	async function exportToExcel() {
		await DOWNLOAD_FILE(`/services/web/api/analytics-abc-excel/${filterData.posId}`, t('ABC'), filterData)
	}

	function resetFilter() {
		setFilterData({
			posId: reduxAccount.posId,
			search: '',
			productCategoryId: '',
			dateBalance: '',
			page: 0,
			random: filterData.random + 1,
		})
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.search === '' &&
			filterData.productCategoryId === '' &&
			filterData.dateBalance === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	async function getData() {
		checkFilter()
		const response = await PGET(URL + filterData.posId, filterData, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL + filterData.posId, { ...filterData, page: data.selected, size: reduxSettings.size }, { loader: true })
		setFilterData({ ...filterData, page: data.selected })
		setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
		setData(response.data);
	}

	async function getCategories() {
		const response = await GET('/services/web/api/product-category-helper')
		var arr = []
		for (let i = 0; i < response.length; i++) {
			response[i]['label'] = ' ' + response[i]['name']
			response[i].child = false;
			if (response[i].parentId !== 0) {
				const index = response.findIndex(x => x.id === response[i].parentId)
				response[i].child = true;
				if (response[index].options && response[index].options.length > 0) {
					response[index].options.push(response[i])
				} else {
					response[index].options = [response[i]]
				}
			} else {
				arr.push(response[i])
			}
		}
		setCategories(arr)
		setCategoriesInitial(response)
	}

	async function getPoses() {
		const response = await GET("/services/web/api/pos-helper")
		if (response) {
			setPoses(response)
		}
	}

	useEffect(() => {
		if (reduxFilterData?.abc) {
			console.log(filterData);
			setFilterData({ ...filterData, ...reduxFilterData?.abc, random: filterData.random + 1 })
		} else {
			getData(true)
		}
		getPoses()
		getCategories()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		dispatch({ type: 'SET_FILTER_DATA', payload: { key: 'abc', value: { ...filterData } } })
	}, [filterData]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getData()
	}, [filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	async function selectCategory(option) {
		setIsOpen(false)
		if (option === null) {
			setFilterData({ ...filterData, 'productCategoryId': null })
			return
		}
		setFilterData({ ...filterData, 'productCategoryId': option.id })
	}

	const GroupHead = props => {
		return (
			<div style={{
				padding: '0',
				color: filterData.productCategoryId === props.data.id ? '#fff' : '#999',
				fontWeight: 'bold',
				backgroundColor: filterData.productCategoryId === props.data.id ? '#2684FF' : '#fff',
				fontSize: '.8rem'
			}}
				onClick={(e) => selectCategory(props.data)}>
				<components.Option {...props} />
			</div>
		);
	};

	const Option = props => {
		return (
			<div style={{
				padding: props.data.child ? '0 0 0 20px' : '0', color: '#999',
				fontWeight: props.data.parentId === 0 ? 'bold' : 'normal', fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('ABC')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}
								reduxFilterSearch={true}
							/>
						</div>
						<div>
							<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('POS')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.posId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>{t('CATEGORY')}</label>
											<Select
												isClearable
												components={{ GroupHeading: GroupHead, Option }}
												classNamePrefix="react-select"
												options={categories}
												value={categoriesInitial.find(option => option.id === filterData.productCategoryId) || ''}
												onChange={(option) => selectCategory(option)}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.label}
												getOptionValue={(option) => option.id}
												onMenuOpen={() => setIsOpen(true)}
												onBlur={() => setIsOpen(false)}
												menuIsOpen={isOpen}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>{t('DATE')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.dateBalance ? new Date(filterData.dateBalance) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'dateBalance': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button type="button" className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button type="button" className="btn btn-primary" onClick={() => setFilterData({ ...filterData, page: 0, random: filterData.random + 1 })}>
													{t('APPLY')}
												</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<div>
							<button className="btn btn-outline-primary btn-wide" onClick={() => exportToExcel()}>EXCEL</button>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block my-3">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.productCategoryId &&
									<span className="me-2">
										<b>{t('CATEGORY')}: </b> {findFromArrayById(categoriesInitial, filterData.productCategoryId)}
									</span>
								}
								{filterData.dateBalance &&
									<span className="me-2">
										<b>{t('DATE')}: </b>
										<span>{formatDate(filterData.dateBalance)}</span>
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}
					<div className="table-responsive">
						<table className="table table-bordered mb-0">
							<thead>
								<tr>
									<th>№</th>
									<th>{t('NAME_OF_PRODUCT')}</th>
									<th className="text-center">{t('BARCODE')}</th>
									<th className="text-center">{t('ARTIKUL')}</th>
									<th className="text-center">{t('NUMBER_OF_SALES')} <br /> (90-60)</th>
									<th className="text-center">{t('NUMBER_OF_SALES')} <br /> (60-30)</th>
									<th className="text-center">{t('NUMBER_OF_SALES')} <br /> (30-0)</th>
									<th className="text-center">{t('NUMBER_OF_SALES')}</th>

									<th className="text-center">{t('SALE_AMOUNT')} <br /> (90-60)</th>
									<th className="text-center">{t('SALE_AMOUNT')} <br /> (60-30)</th>
									<th className="text-center">{t('SALE_AMOUNT')} <br /> (30-0)</th>
									<th className="text-center">{t('SALE_AMOUNT')}</th>

									<th className="text-center">{t('INCOME')} <br /> (90-60)</th>
									<th className="text-center">{t('INCOME')} <br /> (60-30)</th>
									<th className="text-center">{t('INCOME')} <br /> (30-0)</th>
									<th className="text-center">{t('INCOME')}</th>

									<th className="text-center">{t('ANALYSIS_QUANTITY')}</th>
									<th className="text-center">{t('ANALYSIS_AMOUNT')}</th>
									<th className="text-center">{t('ANALYSIS_INCOME')}</th>

									<th className="text-center">{t('STABILITY_QUANTITY')}</th>
									<th className="text-center">{t('STABILITY_AMOUNT')}</th>
									<th className="text-center">{t('STABILITY_INCOME')}</th>

									<th className="text-center">{t('RESIDUE')}</th>

									<th className="text-center">{t('BALANCE_DAY')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}.</td>
										<td>{item.productName.substring(0, 25)}</td>
										<td className="text-end">{item.productBarcode}</td>
										<td className="text-end">{item.productArtikul}</td>

										<td className="text-end text-nowrap">{formatMoney(item.totalQuantityThirdMonth)}</td>
										<td className="text-end text-nowrap">{formatMoney(item.totalQuantitySecondMonth)}</td>
										<td className="text-end text-nowrap">{formatMoney(item.totalQuantityFirstMonth)}</td>
										<td className="text-end text-nowrap">{formatMoney(item.totalQuantity)}</td>

										<td className="text-end text-nowrap">{formatMoney(item.totalAmountThirdMonth)}</td>
										<td className="text-end text-nowrap">{formatMoney(item.totalAmountSecondMonth)}</td>
										<td className="text-end text-nowrap">{formatMoney(item.totalAmountFirstMonth)}</td>
										<td className="text-end text-nowrap">{formatMoney(item.totalAmount)}</td>

										<td className="text-end text-nowrap">{formatMoney(item.totalIncomeThirdMonth)}</td>
										<td className="text-end text-nowrap">{formatMoney(item.totalIncomeSecondMonth)}</td>
										<td className="text-end text-nowrap">{formatMoney(item.totalIncomeFirstMonth)}</td>
										<td className="text-end text-nowrap">{formatMoney(item.totalIncome)}</td>

										<td className={"text-center " + (item.abcQuantity === 'A' ? 'text-success' : item.abcQuantity === 'B' ? 'text-warning' : 'text-danger')}>
											<b>{item.abcQuantity}</b>
										</td>
										<td className={"text-center " + (item.abcAmount === 'A' ? 'text-success' : item.abcAmount === 'B' ? 'text-warning' : 'text-danger')}>
											<b>{item.abcAmount}</b>
										</td>
										<td className={"text-center " + (item.abcIncome === 'A' ? 'text-success' : item.abcIncome === 'B' ? 'text-warning' : 'text-danger')}>
											<b>{item.abcIncome}</b>
										</td>

										<td className={"text-center " + (item.xyzQuantity === 'X' ? 'text-success' : item.xyzQuantity === 'Z' ? 'text-warning' : 'text-danger')}>
											<b>{item.xyzQuantity}</b>
										</td>
										<td className={"text-center " + (item.xyzAmount === 'X' ? 'text-success' : item.xyzAmount === 'Z' ? 'text-warning' : 'text-danger')}>
											<b>{item.xyzAmount}</b>
										</td>
										<td className={"text-center " + (item.xyzIncome === 'X' ? 'text-success' : item.xyzIncome === 'Z' ? 'text-warning' : 'text-danger')}>
											<b>{item.xyzIncome}</b>
										</td>

										<td className="text-end text-nowrap">{formatMoney(item.productBalance)}</td>
										<td className="text-center">{item.amountDayNotZero}</td>

									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate} reduxPage={filterData.page}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Abc