import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Pagination from 'pages/parts/Pagination'

import { PGET } from 'helpers/api'
import { formatMoney, formatDate } from 'helpers/helpers'

function Preview() {
	const { t } = useTranslation()
	const { id } = useParams();
	const navigate = useNavigate();

	const URL = "/services/web/api/documents-out/" + id

	const settings = useSelector(state => state.settings)
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState({})

	async function getData() {
		const response = await PGET(URL, {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL, { page: data.selected, size: settings.size })
		setData(response.data);
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('RETURN')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('PREVIEW')}</h6>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<h4 className="fw-600 mb-3">{t('DOCUMENT_FROM')} {formatDate(data.createdDate)}</h4>
					<div className="d-flex flex-wrap">
						<div className="me-3">
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('POS')}:</div>
								<div className="w-100 text-end text-nowrap">{data.posName}</div>
							</div>
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('SUPPLIER')}:</div>
								<div className="w-100 text-end text-nowrap">{data.organizationName}</div>
							</div>
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('CURRENCY')}:</div>
								<div className="w-100 text-end text-nowrap">{data.currencyName}</div>
							</div>
						</div>
						<div className="me-3">
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('RETURN')} №:</div>
								<div className="w-100 text-end text-nowrap">{data.outNumber}</div>
							</div>
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('NOTE')}:</div>
								<div className="w-100 text-end text-nowrap">{data.note}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped">
							<thead>
								<tr>
									<th>{t('NAME_OF_PRODUCT')}</th>
									<th>{t('CATEGORY')}</th>
									<th className="text-center">{t('BARCODE')}</th>
									<th className="text-center">{t('ARTIKUL')}</th>
									<th className="text-center">{t('SERIAL')}</th>
									<th className="text-center">{t('EXPIRATION_DATE')}</th>
									<th className="text-center">{t('RETURN_QUANTITY')}</th>
									<th className="text-center">{t('ADMISSION_PRICE')}</th>
									<th className="text-end">{t('TOTAL_AMOUNT')}</th>
								</tr>
							</thead>
							<tbody>
								{data.productList?.map((item, index) => (
									<tr key={index}>
										<td>{item.productName}</td>
										<td>{item.categoryName}</td>
										<td className="text-center">{item.barcode}</td>
										<td className="text-center">{item.artikul}</td>
										<td className="text-center">{item.serial}</td>
										<td className="text-center">{formatDate(item.expDate)}</td>
										<td className="text-center">{item.quantity} {item.uomName}</td>
										<td className="text-center">{formatMoney(item.price)}</td>
										<td className="text-end">{formatMoney(item.quantity * item.price)}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<div className="w-100 d-flex justify-content-end">
						<div className="flex-column w-25">
							<h5 className="d-flex justify-content-between">
								<strong className="me-2">{t('TOTAL')}:</strong>{formatMoney(data.totalAmount)}
							</h5>
						</div>
					</div>

					<div className="d-flex justify-content-between mt-5">
						<div className="vertical-end">
							<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
						</div>
						<div>
							<button type="button" className="btn btn-warning btn-rounded btn-wide" onClick={() => navigate(-1)}>
								{t('CANCEL')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Preview
