import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { PGET } from 'helpers/api'
import { formatMoney, formatDate, checkRole } from 'helpers/helpers'

function Index() {
	const URL = "/services/wms/api/documents-out-pageList"
	const { t } = useTranslation()

	const settings = useSelector(state => state.settings)

	const canCreate = checkRole('WMS_DOCUMENT_OUT_ADD')

	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function getData() {
		const response = await PGET(URL)
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL, { page: data.selected, size: settings.size })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('RETURN')}
				</h4>
				<div className="d-flex">
					{canCreate &&
						<Link className="btn-rounded btn btn-primary" to="/wms/documents-out/create">
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</Link>
					}
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th>{t('SUPPLIER')}</th>
									<th className="text-center">№ {t('RETURN')}</th>
									<th className="text-center">{t('AMOUNT')}</th>
									<th className="text-center">{t('DATE_OF_RETURN')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}. {item.wmsName}</td>
										<td>{item.organizationName}</td>
										<td className="text-center">{item.outNumber}</td>
										<td className="text-center">{formatMoney(item.totalAmount)}</td>
										<td className="text-center">{formatDate(item.createdDate)}</td>
										<td className="d-flex justify-content-center">
											<Link to={`/wms/documents-out/preview/${item.id}`}>
												<div className="table-action-button table-action-primary-button">
													<i className="uil-eye"></i>
												</div>
											</Link>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
