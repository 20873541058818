import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import XLSX from 'xlsx';

import { Dropdown } from 'react-bootstrap'
import Select from 'react-select'
import FilterSearch from 'pages/parts/FilterSearch'
import Pagination from 'pages/parts/Pagination'
import DatePicker from "react-datepicker"

import { PGET, GET } from 'helpers/api'
import { formatMoney, formatDateWithTime, checkRole, findFromArrayById, formatDateBackend, formatDate } from 'helpers/helpers'

function Index() {

	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)
	const reduxAccount = useSelector(state => state.account)

	const URL = `/services/web/api/inventory-pageList/`
	const date = new Date()

	const canCreate = checkRole('POS_INVENTORY_ADD')
	const canUpdate = checkRole('POS_INVENTORY_EDIT')

	const [pageCount, setPageCount] = useState(0)

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: reduxAccount.posId,
		search: '',
		startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth() - 1, date.getDate())),
		endDate: formatDateBackend(new Date()),
	})
	const [poses, setPoses] = useState([])
	const [data, setData] = useState([])

	async function exportToExcel(item) {
		const response = await GET('/services/web/api/inventory/' + item.id)

		var excelHeaders = []
		excelHeaders.push([t('DATE'), response['beginDate']])
		excelHeaders.push([t('POS'), response['posName']])
		excelHeaders.push([t('NOTE'), response['note']])
		excelHeaders.push([t('LACK_OF'), formatMoney(response['shortage'])])
		excelHeaders.push([t('SURPLUS'), formatMoney(response['surplus'])])
		excelHeaders.push([
			t('NAME_OF_PRODUCT'), t('BARCODE'), t('UNIT'), t('PRICE'), t('EXPECTED_BALANCE'),
			t('COUNTED'), t('DIVERGENCE'), t('COST_DIFFERENCE')
		])

		for (let i = 0; i < response.productList.length; i++) {
			var excelItems = []
			excelItems.push(response.productList[i]['productName'])
			excelItems.push(response.productList[i]['barcode'])
			excelItems.push(response.productList[i].uomName)
			excelItems.push(formatMoney(response.productList[i]['price']))
			excelItems.push(response.productList[i]['balance'])
			excelItems.push(response.productList[i]['actualBalance'])
			excelItems.push(response.productList[i]['divergence'])
			excelItems.push(formatMoney(response.productList[i]['differenceAmount']))
			excelHeaders.push(excelItems)
		}
		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('INVENTORY') + ".xlsx");
	}

	async function getData() {
		checkFilter()
		const response = await PGET(URL + filterData.posId, filterData)
		if (response && response.data) {
			setPageCount(Math.ceil((response?.headers['x-total-count']) / reduxSettings.size))
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	function resetFilter() {
		setFilterData({
			...filterData,
			search: '',
			startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth() - 1, date.getDate())),
			endDate: formatDateBackend(new Date()),
		})
		getData()
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL + filterData.posId, { ...filterData, page: data.selected, size: reduxSettings.size })
		setData(response.data);
	}

	async function getPoses() {
		const response = await GET("/services/web/api/pos-helper", {}, { loader: true })
		if (response) {
			setPoses(response)
		}

	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getData(true)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('INVENTORY')}
				</h4>
				<div className="d-flex">
					{canCreate &&
						<Link className="btn-rounded btn btn-primary" to="/inventory/create">
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</Link>
					}
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}>
							</FilterSearch>
						</div>
						<div>
							<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-12">
											<label>{t('POS')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.posId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>{t('PERIOD')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.startDate ? new Date(filterData.startDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.endDate ? new Date(filterData.endDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button type="button" className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button type="button" className="btn btn-primary" onClick={() => getData(true)}>{t('APPLY')}</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block mb-3">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.startDate || filterData.endDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.startDate &&
											<span>{formatDate(filterData.startDate)}</span>
										}
										{(filterData.startDate && filterData.endDate) &&
											<span>|</span>
										}
										{filterData.endDate &&
											<span>{formatDate(filterData.endDate)}</span>
										}
									</span>
								}
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>№</th>
									<th>{t('POS')}</th>
									<th className="text-center">№ {t('DOCUMENT')}</th>
									<th className="text-center">{t('BEGIN_DATE')}</th>
									<th className="text-center">{t('END_DATE')}</th>
									<th className="text-center">{t('STATUS')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}</td>
										<td>{item.posName}</td>
										<td className="text-center">{item.inventoryNumber}</td>
										<td className="text-center">{formatDateWithTime(item.beginDate)}</td>
										<td className="text-center">{formatDateWithTime(item.endDate)}</td>
										<td className="text-center">
											{!item.completed ?
												<span className="bg-secondary bg-border text-white">{t('EXPECTED')}</span>
												:
												<span className="bg-success bg-border text-white">{t('CLOSED')}</span>
											}
										</td>
										<td className="d-flex justify-content-center">
											{item.completed ?
												<Link to={'/inventory/preview/' + item.id} className="me-2">
													<div className="table-action-button table-action-primary-button">
														<i className="uil-eye"></i>
													</div>
												</Link>
												:
												<>
													<Link to={'/inventory/update-socket/' + item.id} className="me-2">
														<div className="table-action-button table-action-primary-button">
															<i className="uil-clouds"></i>
														</div>
													</Link>
													{canUpdate &&
														<Link to={'/inventory/update/' + item.id} className="me-2">
															<div className="table-action-button table-action-primary-button">
																<i className="uil-edit-alt"></i>
															</div>
														</Link>
													}
												</>

											}
											<div className="table-action-button table-action-success-button" onClick={() => exportToExcel(item)}>
												<i className="uil-file-alt"></i>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
