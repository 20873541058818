import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { PGET } from 'helpers/api'
import { checkRole, findFromArrayById, formatMoney, regexPhoneNumber } from 'helpers/helpers'
import { Link } from "react-router-dom"

function Index() {
	const URL = "/services/web/api/users-pageList"
	const { t } = useTranslation()

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]

	const settings = useSelector(state => state.settings)

	const canCreate = checkRole('MAIN_USERS_ADD')
	const canUpdate = checkRole('MAIN_USERS_EDIT')

	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function getData() {
		const response = await PGET(URL, {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL, { page: data.selected, size: settings.size })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('MY_USERS')}
				</h4>
				{canCreate &&
					<div className="d-flex">
						<Link className="btn-rounded btn btn-primary" to={`/workers/create`}>
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</Link>
					</div>
				}
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('FIO')}</th>
									<th>{t('LOGIN')}</th>
									<th>{t('PHONE')}</th>
									<th className="text-end">{t('ROLE')}</th>
									<th className="text-end">{t('CURRENCY')}</th>
									<th className="text-end">{t('SALARY')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}. {item.firstName} {item.lastName}</td>
										<td>{item.userLogin}</td>
										<td>{regexPhoneNumber(item.phone)}</td>
										<td className="text-end">{item.roleName ?? '-'}</td>
										<td className="text-end">{findFromArrayById(currencies, item.salaryCurrencyId)}</td>
										<td className="text-end">{formatMoney(item.salaryAmout)}</td>
										<td className="d-flex justify-content-center">
											{canUpdate &&
												<Link className="table-action-button table-action-primary-button" to={`/workers/update/${item.id}`}>
													<i className="uil-edit-alt"></i>
												</Link>
											}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
