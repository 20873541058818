import React, { useEffect, useState, useRef } from "react"
import { useNavigate, Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DebounceInput } from 'react-debounce-input'
import Select from 'react-select'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import TableControl from 'pages/parts/TableControl'

import { PGET, GET, PUT } from 'helpers/api'
import { formatDate, formatMoney } from 'helpers/helpers'

function CreateUpdate() {
	const { t } = useTranslation()
	const { id } = useParams();
	const navigate = useNavigate();

	const reduxSettings = useSelector(state => state.settings)
	const searchRef = useRef(null);

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]
	const paymentTypes = [{ 'id': 1, 'name': t('CASH') }, { 'id': 3, 'name': t('MONEY_TRANSFER') }]
	const deliveryStatuses = [{ 'id': 1, 'name': t('NOT_DELIVERED') }, { 'id': 2, 'name': t('DELIVERED') }]

	const [poses, setPoses] = useState([])
	const [wms, setWms] = useState([])
	const [organizations, setOrganizations] = useState([])
	const [searchData, setSearchData] = useState([])
	const [search, setSearch] = useState('')
	const [searchDataExist, setSearchDataExist] = useState(0)
	const [barcode, setBarcode] = useState(false)
	const [modalConfirm, setModalConfirm] = useState(false)
	const [modalTableControl, setModalTableControl] = useState(false)
	const [data, setData] = useState({
		productList: [],
		posId: null,
		totalAmount: 0,
		deliveryStatus: 1,
		currencyId: 1,
		status: 0,
	})

	async function saveComplete(completed = false) {
		var sendData = { ...data }

		for (let i = 0; i < sendData.productList.length; i++) {
			sendData.productList[i]['totalAmount'] = sendData.productList[i]['price'] * sendData.productList[i]['quantity']

			if (!sendData.productList[i]['quantity']) {
				sendData.error = true
				sendData.errorLine = i + 1
				break
			}
			if (data.deliveryStatus === 2) {
				if (sendData.productList[i]['price'] === "" || sendData.productList[i]['price'] < 0.0001) {
					sendData.error = true
					break
				}
				if (sendData.productList[i]['wholesalePrice'] === "" || (Number(sendData.productList[i]['wholesalePrice']) < Number(sendData.productList[i]['price']))) {
					sendData.error = true
					break
				}
				if (sendData.productList[i]['wholesalePrice'] === "" || (Number(sendData.productList[i]['salePrice']) < Number(sendData.productList[i]['wholesalePrice']))) {
					sendData.error = true
					break
				}
			}
		}

		if (sendData.error) {
			toast.error(`${t('CHECK_FILLED_INPUTS')} ${sendData.errorLine}`)
			return
		}

		var response;
		if (!id && !completed) {
			response = await PUT('/services/web/api/order-wms', sendData, { loader: true })
			if (response && response?.success) {
				navigate('/orders')
			}
		}

		if (id && !completed) {
			response = await PUT('/services/web/api/order-wms', sendData, { loader: true })
			if (response && response?.success) {
				navigate('/orders')
			}
		}

		if (id && completed) {
			sendData.completed = true
			response = await PUT('/services/web/api/order-wms-send', sendData, { loader: true })
			if (response && response?.success) {
				navigate('/orders')
			}
		}

		if (!id && completed) {
			response = await PUT('/services/web/api/order-wms-send', sendData, { loader: true })
			if (response && response?.success) {
				navigate('/orders')
			}
		}
	}

	function handleProductListChange(e, index) {
		var dataCopy = { ...data }
		dataCopy.productList[index][e.target.name] = e.target.value

		if (e.target.name === 'quantity') {
			var temporaryTotalAmount = 0

			for (let i = 0; i < dataCopy.productList.length; i++) {
				if (dataCopy.productList[i]['price'] && dataCopy.productList[i]['quantity']) {
					temporaryTotalAmount += data.productList[i]['price'] * data.productList[i]['quantity']
					dataCopy.totalAmount = temporaryTotalAmount
				} else {
					dataCopy.totalAmount = 0
				}
			}
		}

		setData(dataCopy)
	}

	async function searchProduct(value) {
		setSearchDataExist(0)
		setSearch(value)
		if (value.length === 0) {
			setSearchData([])
			setSearchDataExist(0)
		} else {
			const response = await PGET("/services/web/api/product-in-helper",
				{ 'name': value, 'posId': data.posId, 'wmsId': data.wmsId, 'barcode': barcode, 'currencyId': data.currencyId, },
				{ loader: true })

			var dataCopy = { ...data }
			for (let i = 0; i < response.data.length; i++) {
				for (let j = 0; j < dataCopy.productList.length; j++) {
					if (
						response.data[i]['productId'] === dataCopy.productList[j]['productId'] &&
						response.data[i]['fromCurrencyId'] === dataCopy.productList[j]['fromCurrencyId'] &&
						response.data[i]['toCurrencyId'] === dataCopy.productList[j]['toCurrencyId']
					) {
						if (response.data.length === 1) {
							setSearchDataExist(2)
							setSearch('')
						}
						response.data.splice(i, 1)
					}
				}
			}

			if (response.data.length === 0) {
				setSearchDataExist(1)
				return
			}

			if (/^\d+$/.test(value) && response.data.length === 1) {
				setSearchData(response.data)
				addToList(0, response.data)
				setSearch('')
			} else {
				setSearchData(response.data)
			}
		}
	}

	async function getRecommendedOrders() {
		const response = await GET(`services/web/api/product-in-recommended?posId=${data.posId}&organizationId=${data.organizationId}&currencyId=${data.currencyId}`, {}, { loader: true });
		if (response.length > 0) {
			var dataCopy = { ...data }
			for (let i = 0; i < response.length; i++) {
				var found = dataCopy.productList.find(item => item.barcode === response[i]['barcode']);
				if (found?.name) {
					toast.error(t('PRODUCT_ADDED'))
					break;
				} else {
					response[i].fromCurrencyIdCopy = response[i]['fromCurrencyId']
					response[i].currencyId = response[i]['toCurrencyId']
					dataCopy.productList.push(response[i])
				}
			}
			setData(dataCopy)
		} else {
			toast.error(t('NOTHING_FOUND'))
		}
	}

	function addToList(index, arr = []) {
		var searchDataCopy;
		if (arr.length > 0) {
			searchDataCopy = arr
		} else {
			searchDataCopy = [...searchData]
		}
		var dataCopy = { ...data }

		var found = dataCopy.productList.find(item => item.productBarcode === searchDataCopy[index]['productBarcode']);
		if (found?.id) {
			toast.error(t('PRODUCT_ADDED'))
			return
		}

		searchDataCopy[index].fromCurrencyIdCopy = searchDataCopy[index]['fromCurrencyId']
		searchDataCopy[index].currencyId = searchDataCopy[index]['toCurrencyId']
		searchDataCopy[index].quantity = ''
		dataCopy.productList.push(searchDataCopy[index])
		setData(dataCopy)

		searchDataCopy.splice(index, 1)
		setSearchData(searchDataCopy)

		if (searchDataCopy.length === 0) {
			setSearch('')
		}

		searchRef.current.focus()
	}

	function deleteProduct(index) {
		var dataCopy = { ...data }
		dataCopy.productList.splice(index, 1)
		setData(dataCopy)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		setPoses(response)

		if (id) {
			const temporaryData = await GET('/services/web/api/order-wms-send/' + id)
			setData(temporaryData)
		}
	}

	async function getWms() {
		const response = await GET(`/services/web/api/wms-helper`)
		setWms(response)
	}

	async function getOrganizations() {
		const response = await GET(`/services/web/api/organization-helper`)
		setOrganizations(response)
	}

	useEffect(() => {
		getPoses()
		getWms()
		getOrganizations()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('ORDERS')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('CREATE')}</h6>
					</div>
				</div>
			</div>

			<form autoComplete="off">
				<div className="card mb-3">
					<div className="card-body">
						<div className="row pt-2">
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('POS')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={!(data.status === 0)}
										options={poses}
										value={poses.find(option => option.id === data.posId)}
										onChange={(option) => setData({ ...data, 'posId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('WAREHOUSE')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={!(data.status === 0)}
										options={wms}
										value={wms.find(option => option.id === data.wmsId) || ''}
										onChange={(option) => setData({ ...data, 'wmsId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('CURRENCY')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={!(data.status === 0)}
										options={currencies}
										value={currencies.find(option => option.id === data.currencyId)}
										onChange={(option) => setData({ ...data, 'currencyId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('STATUS')}<span className="required-mark">*</span></label>
									<Select
										isDisabled={!(data.status === 0)}
										options={deliveryStatuses}
										value={deliveryStatuses.find(option => option.id === data.deliveryStatus)}
										onChange={(option) => setData({ ...data, 'deliveryStatus': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('ORGANIZATION')}</label>
									<Select
										isDisabled={!(data.status === 0)}
										options={organizations}
										value={organizations.find(option => option.id === data.organizationId) || ''}
										onChange={(option) => setData({ ...data, 'organizationId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
							<div className="col-md-2">
								<div className="form-group">
									<label>{t('PAYMENT_METHOD')}</label>
									<Select
										isDisabled={!(data.status === 0)}
										options={paymentTypes}
										value={paymentTypes.find(option => option.id === data.paymentTypeId) || ''}
										onChange={(option) => setData({ ...data, 'paymentTypeId': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
									/>
								</div>
							</div>
						</div>
						<div className="import-icon">
							<button className="table-action-button table-action-primary-button" type="button"
								onClick={(e) => getRecommendedOrders()} title={t('RECOMMENDED_ORDERS')} disabled={(!data.posId || !data.organizationId || !data.wmsId || !data.currencyId)}>
								<i className="uil-import"></i>
							</button>
						</div>
					</div>
				</div>

				<div className="card">
					<div className="card-body">
						<div className="table-responsive mb-3">
							<table className="table table-striped mb-0">
								<thead>
									<tr>
										<th>{t('NAME_OF_PRODUCT')}</th>
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.categoryName) &&
											<th>{t('CATEGORY')}</th>
										}
										<th className="text-center">{t('BARCODE')}</th>
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.artikul) &&
											<th className="text-center">{t('ARTIKUL')}</th>
										}
										<th className="text-center">{t('UNIT')}.</th>
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.serial) &&
											<th className="text-center">{t('SERIAL')}</th>
										}
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.expDate) &&
											<th className="text-center">{t('EXPIRATION_DATE')}</th>
										}
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.vat) &&
											<th className="text-center">{t('VAT')}</th>
										}
										<th className="text-center">{t("PRICE")}</th>
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.wholesalePrice) &&
											<th className="text-center">{t('WHOLESALE_PRICE')}</th>
										}
										{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.bankPrice) &&
											<th className="text-center">{t('BANK_PRICE')}</th>
										}
										<th className="text-center">{t('UNIT_PRICE')}</th>
										<th className="text-center">{t('RESIDUE')}</th>
										<th className="text-center">{t('QUANTITY')}</th>
										<th className="text-center table-control">
											<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
											{t('ACTION')}
										</th>
									</tr>
								</thead>
								<tbody>
									{data.productList?.map((item, index) => (
										<tr key={index}>
											<td>{index + 1}. {item.name}</td>
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.categoryName) &&
												<td>{item.categoryName}</td>
											}
											<td className="text-center">{item.barcode}</td>
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.artikul) &&
												<td className="text-center">{item.artikul}</td>
											}
											<td className="text-center">{item.uomName}</td>
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.serial) &&
												<td className="text-center">{item.serial}</td>
											}
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.expDate) &&
												<td className="text-center">{formatDate(item.expDate)}</td>
											}
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.vat) &&
												<td className="text-center">{item.vat}</td>
											}
											<td className="text-center">
												<input type="number" className={"auto-width-input " + (!item.price && "required-border")}
													name="price" value={item.price}
													onChange={(e) => handleProductListChange(e, index)}
												/>
											</td>
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.wholesalePrice) &&
												<td className="text-center">
													<input type="number" className={"auto-width-input " + (!item.wholesalePrice && "required-border")}
														name="wholesalePrice" value={item.wholesalePrice}
														onChange={(e) => handleProductListChange(e, index)}
													/>
												</td>
											}
											{(!reduxSettings.tableTransfer || reduxSettings.tableTransfer?.bankPrice) &&
												<td className="text-center">
													<input type="number" className={"auto-width-input " + (!item.bankPrice && "required-border")}
														name="bankPrice" value={item.bankPrice}
														onChange={(e) => handleProductListChange(e, index)}
													/>
												</td>
											}
											<td className="text-center">
												<input type="number" className={"auto-width-input " + (!item.salePrice && "required-border")}
													name="salePrice" value={item.salePrice}
													onChange={(e) => handleProductListChange(e, index)}
												/>
											</td>
											<td className="text-center">
												<span>{item.balance}</span>
											</td>
											<td className="text-center">
												<input type="number" className={"auto-width-input " + (!item.quantity && "required-border")}
													name="quantity" value={item.quantity}
													disabled={data.status}
													onChange={(e) => handleProductListChange(e, index)} />
											</td>
											<td>
												<div className="d-flex justify-content-center" onClick={() => deleteProduct(index)}>
													{!data.status && <div className="table-action-button table-action-danger-button">
														<i className="uil uil-times"></i>
													</div>
													}
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>

						<div className="row">
							<div className="col-md-6 vertical-center">
								<div className="form-group position-relative m-0">
									{!data.status &&
										<>
											<DebounceInput
												inputRef={searchRef}
												disabled={(!data.posId || !data.wmsId || !data.currencyId || !data.deliveryStatus)}
												value={search}
												onChange={(e) => searchProduct(e.target.value)}
												onKeyUp={(e) => {
													if (e.keyCode === 13) {
														searchProduct(e.target.value)
													}
												}}
												type="text"
												className="form-control"
												placeholder={t('SEARCH') + '...'}
												debounceTimeout={1000}
											/>
											{searchData.length > 0 ?
												<i className="uil uil-times search-barcode" onClick={() => { setSearchData([]); setSearch('') }}></i>
												:
												<i className={'uil uil-qrcode-scan search-barcode ' + (barcode && 'text-primary')} onClick={() => setBarcode(!barcode)}></i>
											}
										</>
									}
									{searchData.length > 0 &&
										<span className="dropdown-search-menu">
											<div className="dropdown-menu-list">
												<span className="dropdown-menu-result">
													<div className="table-responsive p-3">
														<table className="table-striped table">
															<thead>
																<tr>
																	<th>{t('NAME_OF_PRODUCT')}</th>
																	<th>{t('BARCODE')}</th>
																	<th className="text-end">{t('PRICE')}</th>
																	<th className="text-end">{t('RESIDUE')}</th>
																	<th className="text-center">{t('ACTION')}</th>
																</tr>
															</thead>
															<tbody>
																{searchData.map((item, index) => (
																	<tr key={index}>
																		<td>{item.name}</td>
																		<td>{item.barcode}</td>
																		<td className="text-end">{item.price}</td>
																		<td className="text-end">{item.balance}</td>
																		<td className="d-flex justify-content-center" onClick={() => addToList(index)}>
																			<div className="table-action-button table-action-primary-button">
																				<i className="uil uil-plus"></i>
																			</div>
																		</td>
																	</tr>
																))
																}
															</tbody>
														</table>
													</div>
												</span>
											</div>
										</span>
									}

									{(searchData.length === 0 && searchDataExist === 1) &&
										<span className="dropdown-search-menu">
											<div className="dropdown-menu-list d-flex justify-content-between p-2">
												<span className="dropdown-menu-result fz20">{t('PRODUCT_NOT_FOUND')}</span>
											</div>
										</span>
									}
									{(searchData.length === 0 && searchDataExist === 2) &&
										<span className="dropdown-search-menu">
											<div className="dropdown-menu-list d-flex justify-content-between p-2">
												<span className="dropdown-menu-result fz20">{t('PRODUCT_ADDED')}</span>
											</div>
										</span>
									}
								</div>
							</div>
							<div className="col-md-6 d-flex justify-content-end">
								<div className="flex-column">
									<h5 className="d-flex justify-content-between"><strong className="me-2">{t('TOTAL')}:</strong> {formatMoney(data.totalAmount)}</h5>
								</div>
							</div>
						</div>

						<div className="btn-wrapper gap-2">
							<Link className="btn btn-warning btn-rounded btn-wide" to="/orders">
								{t('CANCEL')}
							</Link>
							{!data.status &&
								<button type="button" className="btn btn-success btn-rounded btn-wide"
									disabled={!data.productList.length > 0}
									onClick={() => setModalConfirm(true)}>{t('COMPLETE')}</button>
							}
							{!data.status &&
								<button type="button" className="btn btn-primary btn-rounded btn-wide"
									disabled={!data.productList.length > 0}
									onClick={() => saveComplete(false)}>{t('SAVE_TO_DRAFT')}</button>
							}
						</div>
					</div>
				</div>
			</form>

			<Modal show={modalConfirm} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalConfirm(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('CONFIRM')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('ARE_YOU_SURE_YOU_WANT')}?
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setModalConfirm(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => saveComplete(true)}>{t('CONFIRM')}</button>
					</div>
				</Modal.Footer>
			</Modal>

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableTransfer'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default CreateUpdate

