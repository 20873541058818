const organizationMenu = [
	{
		icon: "uil-chart-pie-alt",
		i18n: 'REPORTS',
		children: [
			{
				url: "/oraganization/balance",
				i18n: "BALANCE_REPORT",
				icon: "uil-file-graph",
				active: false,
			},
			{
				url: "/oraganization/sales",
				i18n: "SALES_REPORT",
				icon: "uil-chart-pie-alt",
				active: false,
			},
		]
	},
];

export default organizationMenu; 