import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import XLSX from 'xlsx';

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import Rightbar from '../cheques/Rightbar'
import { PGET, GET } from 'helpers/api'
import { formatDateWithTime, formatMoney } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const settings = useSelector(state => state.settings)
	const reduxReport = useSelector(state => state.report)

	const URL = "/services/wms/api/cheque-by-clientId-pageList/" + reduxReport.reportSelectedClient.clientId

	const [showRightBar, setShowRightBar] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function toggleRightbar(boolean = false, item, index) {
		var dataCopy = [...data]

		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i]['showSidebar'] = false
		}

		if (item.chequeId !== 0) {
			if (boolean) {
				const response = await GET("/services/wms/api/cheque-byId/" + item.id, {}, { loader: true })
				dataCopy[index]['itemsList'] = response
				dataCopy[index]['showSidebar'] = boolean
				setSelectedItem(response)
				setShowRightBar(boolean)
			} else {
				setShowRightBar(boolean)
				setSelectedItem({})
			}
		}
	}

	async function getData() {
		const response = await PGET(URL, {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function exportToExcel() {
		const response = await GET('/services/wms/api/cheque-by-clientId-excel/' + reduxReport.reportSelectedClient.clientId)

		var excelHeaders = [[t('POS'), t('CASHBOX'), t('SHIFT_NUMBER'), t('CASHIER'), t('CHECK_NUMBER'), t('CHEQUE_DATE'),
		t('SYNC_DATE'), t('STATUS'), t('TOTAL')]]
		for (let i = 0; i < response.length; i++) {
			var excelItems = []
			excelItems.push(response[i].posName)
			excelItems.push(response[i].cashboxName)
			excelItems.push(response[i].shiftNumber)
			excelItems.push(response[i].cashierName)
			excelItems.push(response[i].chequeNumber)
			excelItems.push(formatDateWithTime(response[i].chequeDate))
			excelItems.push(formatDateWithTime(response[i].createdDate))
			if (response[i].returned === 0) excelItems.push(t('SUCCESS'))
			if (response[i].returned === 1) excelItems.push(t('PARTIAL_RETURN'))
			if (response[i].returned === 2) excelItems.push(t('RETURN'))
			excelItems.push(response[i].totalPrice)
			excelHeaders.push(excelItems)
		}
		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('CHEQUES') + ".xlsx");
	}

	async function paginate(data) {
		const response = await PGET(URL, { page: data.selected, size: settings.size })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex">
							<Search url={URL} search={search} getData={getData}></Search>
							<div>
								<button className="btn btn-outline-primary btn-wide" onClick={exportToExcel}>
									EXCEL
								</button>
							</div>
						</div>
					</div>

					<div className="table-responsive">
						<table className="table table-hover cursor mb-0">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th className="text-center">{t('CASHBOX')}</th>
									<th className="text-center">{t('SHIFT_NUMBER')}</th>
									<th className="text-center">{t('CASHIER')}</th>
									<th className="text-center">{t('CHECK_NUMBER')}</th>
									<th className="text-center">{t('CHEQUE_DATE')}</th>
									<th className="text-center">{t('SYNC_DATE')}</th>
									<th className="text-center">{t('STATUS')}</th>
									<th className="text-center">{t('TOTAL')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index} onClick={() => toggleRightbar(true, item, index)}>
										<td>{item.rowNum}. {item.posName}</td>
										<td className="text-center">{item.cashboxName}</td>
										<td className="text-center">{item.shiftNumber}</td>
										<td className="text-center">{item.cashierName}</td>
										<td className="text-center">{item.chequeNumber}</td>
										<td className="text-center">{formatDateWithTime(item.chequeDate)}</td>
										<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
										<td className="d-flex justify-content-center">
											{item.returned === 0 &&
												<span className="bg-success bg-border text-white">{t('SUCCESS')}</span>
											}
											{item.returned === 1 &&
												<span className="bg-warning bg-border text-white text-nowrap">{t('PARTIAL_RETURN')}</span>
											}
											{item.returned === 2 &&
												<span className="bg-danger bg-border text-white">{t('RETURN')}</span>
											}
										</td>
										<td className="text-center text-nowrap">{formatMoney(item.totalPrice)} {item.currencyName}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			<Rightbar
				showRightBar={showRightBar}
				setShowRightBar={setShowRightBar}
				selectedItem={selectedItem}>
			</Rightbar>
		</>
	)
}

export default Index
