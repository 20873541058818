import React, { useEffect, useState } from "react"
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import Pagination from 'pages/parts/Pagination'
import DeleteModal from 'pages/parts/DeleteModal'
import TableControl from 'pages/parts/TableControl'

import { PGET, DELETE } from 'helpers/api'
import { formatMoney, formatDate, checkRole } from 'helpers/helpers'

function Preview() {
	const { t } = useTranslation()
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch()

	const settings = useSelector(state => state.settings)
	const reduxSettings = useSelector(state => state.settings)

	const canDelete = checkRole('POS_DOCUMENT_IN_DELETE')

	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState({})
	const [modalDelete, setModalDelete] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ id: 0, index: 0 })
	const [modalTableControl, setModalTableControl] = useState(false)

	async function deleteItem() {
		const response = await DELETE('/services/web/api/delete-documents-in-product/' + deleteModalParams.id, {}, { loader: true })
		if (response) {
			var dataCopy = { ...data }
			dataCopy.productList.content[deleteModalParams.index]['status'] = 2
			setData(dataCopy)
			setModalDelete(false)
		}
	}

	async function redirectToPriceTagsPrint() {
		var response = await PGET(`/services/web/api/documents-in/${id}?size=100000`, {}, { loader: true })
		for (let i = 0; i < response.data.productList.content.length; i++) {
			response.data.productList.content[i]['salePrice'] =
				response.data.productList.content[i]['salePrice'] * response.data.currencyRate
		}
		dispatch({ type: 'SET_DOCUMENT_IN_PRODUCT_LIST', payload: response.data.productList.content })
		navigate('/price-tags')
	}

	async function getData() {
		const response = await PGET("/services/web/api/documents-in/" + id, {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET("/services/web/api/documents-in/" + id, { page: data.selected, size: settings.size })
		setData(response.data);
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('GOOD_RECEPTION')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('PREVIEW')}</h6>
					</div>
				</div>
				<div className="d-flex">
					<span className="btn-rounded btn btn-primary" onClick={() => redirectToPriceTagsPrint()}>
						{t('PRINT')}
					</span>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body">
					<h4 className="fw-600 mb-3">{t('DOCUMENT_FROM')} {formatDate(data.createdDate)}</h4>
					<div className="d-flex flex-wrap">
						<div className="me-3">
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('POS')}:</div>
								<div className="w-100 text-end text-nowrap">{data.posName}</div>
							</div>
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('SUPPLIER')}:</div>
								<div className="w-100 text-end text-nowrap">{data.organizationName}</div>
							</div>
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('CURRENCY')}:</div>
								<div className="w-100 text-end text-nowrap">{data.currencyName}</div>
							</div>
						</div>
						<div className="me-3">
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">№ {t('OVERHEAD')}:</div>
								<div className="w-100 text-end text-nowrap">{data.inNumber}</div>
							</div>
							<div className="d-flex">
								<div className="fw-600 text-nowrap me-3">{t('NOTE')}:</div>
								<div className="w-100 text-end text-nowrap">{data.note}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive">
						<table className="table table-striped">
							<thead>
								<tr>
									<th>{t('NAME_OF_PRODUCT')}</th>
									<th className="text-center">{t('BARCODE')}</th>
									{(!reduxSettings.tableDocumentsInPreview || reduxSettings.tableDocumentsInPreview?.artikul) &&
										<th className="text-center">{t('ARTIKUL')}</th>
									}
									<th className="text-center">{t('ADMISSION_PRICE')}</th>
									{(!reduxSettings.tableDocumentsInPreview || reduxSettings.tableDocumentsInPreview?.serial) &&
										<th className="text-center">{t('SERIAL')}</th>
									}
									{(!reduxSettings.tableDocumentsInPreview || reduxSettings.tableDocumentsInPreview?.expDate) &&
										<th className="text-center">{t('EXPIRATION_DATE')}</th>
									}
									<th className="text-center">{t('QUANTITY')}</th>
									{(!reduxSettings.tableDocumentsInPreview || reduxSettings.tableDocumentsInPreview?.wholesalePrice) &&
										<th className="text-center">{t('WHOLESALE_PRICE')}</th>
									}
									{(!reduxSettings.tableDocumentsInPreview || reduxSettings.tableDocumentsInPreview?.bankPrice) &&
										<th className="text-center">{t('BANK_PRICE')}</th>
									}
									<th className="text-center">{t('UNIT_PRICE')}</th>
									{(!reduxSettings.tableDocumentsInPreview || reduxSettings.tableDocumentsInPreview?.vat) &&
										<th className="text-center">{t('VAT')}</th>
									}
									<th className="text-center">{t('AMOUNT')}</th>
									{(!reduxSettings.tableDocumentsInPreview || reduxSettings.tableDocumentsInPreview?.marking) &&
										<th className="text-center">{t('MARKING')}</th>
									}
									<th className="text-center table-control">
										<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
										{t('ACTION')}
									</th>
								</tr>
							</thead>
							<tbody>
								{data.productList?.content.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}. {item.productName}</td>
										<td className="text-center">{item.barcode}</td>
										{(!reduxSettings.tableDocumentsInPreview || reduxSettings.tableDocumentsInPreview?.artikul) &&
											<td className="text-center">{item.artikul}</td>
										}
										<td className="text-center">{formatMoney(item.price)}</td>
										{(!reduxSettings.tableDocumentsInPreview || reduxSettings.tableDocumentsInPreview?.serial) &&
											<td className="text-center">{item.serial}</td>
										}
										{(!reduxSettings.tableDocumentsInPreview || reduxSettings.tableDocumentsInPreview?.expDate) &&
											<td className="text-center">{formatDate(item.expDate)}</td>
										}
										<td className="text-center">{formatMoney(item.quantity)} {item.uomName}</td>
										{(!reduxSettings.tableDocumentsInPreview || reduxSettings.tableDocumentsInPreview?.wholesalePrice) &&
											<td className="text-center">{formatMoney(item.wholesalePrice)}</td>
										}
										{(!reduxSettings.tableDocumentsInPreview || reduxSettings.tableDocumentsInPreview?.bankPrice) &&
											<td className="text-center">{formatMoney(item.bankPrice)}</td>
										}
										<td className="text-center">{formatMoney(item.salePrice)}</td>
										{(!reduxSettings.tableDocumentsInPreview || reduxSettings.tableDocumentsInPreview?.vat) &&
											<td className="text-center">{item.vat}</td>
										}
										<td className="text-center">{formatMoney(item.totalAmount)}</td>
										{(!reduxSettings.tableDocumentsInPreview || reduxSettings.tableDocumentsInPreview?.marking) &&
											<td className="text-center">{item.marking ? t('YES') : t('NO')}</td>
										}
										<td className="d-flex justify-content-center">
											{item.status === 1 &&
												<span className="text-danger">{t('USED')}</span>
											}
											{item.status === 2 &&
												<span className="text-danger">{t('DELETED')}</span>
											}
											{(item.status === 0 && canDelete) &&
												<div className="table-action-button table-action-danger-button"
													onClick={() => { setDeleteModalParams({ id: item.id, index: index }); setModalDelete(true) }}>
													<i className="uil uil-times"></i>
												</div>
											}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<div className="w-100 d-flex justify-content-end">
						<div className="flex-column w-25">
							<h5 className="d-flex justify-content-between">
								<strong className="me-2">{t('RECEIPT_AMOUNT')}:</strong>{formatMoney(data.sumPrice)} {data.currencyName}
							</h5>
							<h5 className="d-flex justify-content-between">
								<strong className="me-2">{t('SALE_AMOUNT')}:</strong>{formatMoney(data.sumSalePrice)} {data.currencyName}
							</h5>
							<h5 className="d-flex justify-content-between">
								<strong className="me-2">{t('INCOME')}:</strong>{formatMoney(data.sumDifference)} {data.currencyName}
							</h5>
						</div>
					</div>

					<div className="d-flex justify-content-between mt-5">
						<div className="vertical-end">
							<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
						</div>
						<div>
							<button type="button" className="btn btn-warning btn-rounded btn-wide" onClick={() => navigate(-1)}>
								{t('CANCEL')}
							</button>
						</div>
					</div>
				</div>
			</div>

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableDocumentsInPreview'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Preview
