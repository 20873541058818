export let exportMenu = [
	{
		title: 'TITLE',
		children: [
			{
				icon: 'sidebar-icon uil-chart-bar',
				i18n: "HOME",
				id: 1,
				children: [
					{
						url: "/dashboard",
						i18n: "HOME",
						icon: 'uil-chart-bar',
						active: false,
					},
					{
						url: "/documents-in",
						i18n: "GOOD_RECEPTION",
						icon: 'uil-bring-front',
						active: false,
					},
					{
						url: "/nomenclature",
						i18n: "NOMENCLATURE",
						icon: 'uil-list-ui-alt',
						active: false,
					},
					{
						url: "/revaluation",
						i18n: "REVALUATION",
						icon: 'uil-comparison',
						active: false,
					},
					{
						url: "/transfer",
						i18n: "DISPLACEMENT",
						icon: 'uil-bus-school',
						active: false,
					},
					{
						url: "/documents-out",
						i18n: "RETURN_OF_GOODS",
						icon: 'uil-backward',
						active: false,
					},
					{
						url: "/write-off",
						i18n: "WRITE_OFF",
						icon: 'uil-minus-circle',
						active: false,
					},
					{
						url: "/inventory",
						i18n: "INVENTORY",
						icon: 'uil-archive-alt',
						active: false,
					},
					{
						url: "/notifications",
						i18n: "NOTIFICATIONS",
						icon: 'uil-bell',
						active: false,
					},
					{
						url: "/grouping",
						i18n: "GROUPING",
						icon: 'uil-object-ungroup',
						active: false,
					},
					{
						url: "/scale",
						i18n: "SCALE",
						icon: 'uil-balance-scale',
						active: false,
					},
					{
						url: "/cheques",
						i18n: "CHEQUES",
						icon: 'uil-receipt',
						active: false,
					},
					{
						url: "/price-tags",
						i18n: "PRICE_TAGS",
						icon: 'uil-pricetag-alt',
						active: false,
					},
					{
						url: "/reports",
						i18n: "REPORTS",
						icon: 'uil-file-graph',
						active: false,
					},
				]
			},
			// {
			// 	icon: 'sidebar-icon uil-store',
			// 	id: 2,
			// 	children: [
			// 		{
			// 			url: "/update/dashboard", // wms/dashboard
			// 			i18n: "HOME",
			// 			icon: "uil-chart-growth",
			// 			active: false,
			// 		},
			// 		{
			// 			url: "/wms/documents-in",
			// 			i18n: "GOOD_RECEPTION",
			// 			icon: "uil-bring-front",
			// 			active: false,
			// 		},
			// 		{
			// 			url: "/wms/documents-out",
			// 			i18n: "RETURN_OF_GOODS",
			// 			icon: "uil-backspace",
			// 			active: false,
			// 		},
			// 		{
			// 			url: "/wms/revaluation",
			// 			i18n: "REVALUATION",
			// 			icon: "uil-newspaper",
			// 			active: false,
			// 		},

			// 		{
			// 			url: "/wms/write-off",
			// 			i18n: "WRITE_OFF",
			// 			icon: "uil-trash-alt",
			// 			active: false,
			// 		},
			// 		{
			// 			url: "/update/inventory", //wms/inventory
			// 			i18n: "INVENTORY",
			// 			icon: "uil-calculator-alt",
			// 			active: false,
			// 		},
			// 		{
			// 			url: "/update/transfer", //wms/transfer
			// 			i18n: "DISPLACEMENT",
			// 			icon: "uil-truck",
			// 			active: false,
			// 		},
			// 		{
			// 			url: "/update/import",
			// 			i18n: "Заказы на поставку",
			// 			icon: "uil-import",
			// 			active: false,
			// 		},
			// 		{
			// 			url: "/update/export",
			// 			i18n: "Заказы на отправку",
			// 			icon: "uil-export",
			// 			active: false,
			// 		},
			// 		{
			// 			url: "/wms/scale",
			// 			i18n: "SCALE",
			// 			icon: "uil-balance-scale",
			// 			active: false,
			// 		},
			// 		{
			// 			url: "/wms/grouping",
			// 			i18n: "GROUPING",
			// 			icon: "uil-object-ungroup",
			// 			active: false,
			// 		},
			// 		{
			// 			url: "/wms/notifications",
			// 			i18n: "NOTIFICATIONS",
			// 			icon: "uil-bell",
			// 			active: false,
			// 		},
			// 		{
			// 			url: "/wms/price-tags",
			// 			i18n: "PRICE_TAGS",
			// 			icon: "uil-pricetag-alt",
			// 			active: false,
			// 		},
			// 		{
			// 			url: "/wms/reports", // wms/reports
			// 			i18n: "REPORTS",
			// 			icon: "uil-chart-pie-alt",
			// 			active: false,
			// 		},
			// 	]
			// },
			{
				icon: 'sidebar-icon uil-clipboard-notes',
				i18n: "GENERAL",
				id: 3,
				children: [
					{
						url: "/workers",
						i18n: "MY_USERS",
						icon: "uil-user-square",
						active: false,
					},
					{
						url: "/products",
						i18n: "PRODUCTS",
						icon: "uil-shopping-cart",
						active: false,
					},
					{
						url: "/suppliers",
						i18n: "SUPPLIERS",
						icon: "uil-truck-loading",
						active: false,
					},
					{
						url: "/clients",
						i18n: "CLIENTS",
						icon: "uil-users-alt",
						active: false,
					},
					{
						url: "/currency",
						i18n: "CURRENCY",
						icon: "uil-money-bill",
						active: false,
					},
				]
			},
			{
				icon: 'sidebar-icon uil-cog',
				i18n: "SETTINGS",
				id: 4,
				children: [
					{
						url: "/settings",
						i18n: "SETTINGS",
						icon: "uil-setting",
						active: false,
					},
					{
						url: "/pos",
						i18n: "POSES",
						icon: "uil-shop",
						active: false,
					},
					{
						url: "/cashbox",
						i18n: "POSES_CASHIER",
						icon: "uil-shop",
						active: false,
					},
					// {
					// 	url: "/warehouse",
					// 	i18n: "WAREHOUSE",
					// 	icon: "uil-store",
					// 	active: false,
					// },
					// {
					// 	url: "/update/wms-cashbox", // wms-cashbox
					// 	i18n: "WAREHOUSE_CASHIER",
					// 	icon: "uil-store",
					// 	active: false,
					// },
				],
			},
		]
	}
]