export let exportMenuAdminReport = [
	{
		icon: 'sidebar-icon uil-chart-bar',
		id: 1,
		children: [
			{
				url: "/aadmin/reports",
				i18n: "REPORTS",
				icon: 'uil-file-graph',
				active: false,
			},
		]
	},
]