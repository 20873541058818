import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { PGET } from 'helpers/api'

function Index() {
	const URL = "/services/admin/api/city-pageList"
	const { t } = useTranslation()
	
	
	const settings = useSelector(state => state.settings)
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	async function getData() {
		const response = await PGET(URL)
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL, { page: data.selected, size: settings.size })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('DT_CT')}
				</h4>
				<div className="d-flex">
					<Link className="btn-rounded btn btn-primary" to="/documents-in/create">
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</Link>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>#</th>
									<th>First</th>
									<th>First</th>
									<th>First</th>
								</tr>
							</thead>
								<tbody>
									{data.map((item, index) => (
										<tr key={index}>
											<td>123</td>
											<td>123</td>
											<td>123</td>
											<td>123</td>
										</tr>
									))
									}
								</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>
		</>
	)
}

export default Index
