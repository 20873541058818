import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import { GUESTPOST, GET } from 'helpers/api'
import { clearTemporaryStorage } from 'helpers/helpers'

import logo from "../../assets/images/logo.svg";

function Login() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [user, setUser] = useState({ username: '', password: '' });
	const [activeInput, setActiveInput] = useState('')
	const [showPassword, setShowPassword] = useState(false)

	async function handleSubmit(e) {
		e.preventDefault();

		var sendData = {
			username: user.username.toLowerCase(),
			password: user.password
		}

		clearTemporaryStorage()
		const response = await GUESTPOST('/auth/login', sendData, { loader: true })
		if (response) {
			if (!response.activated) {
				toast.error(t('ERROR') + ': ' + t('YOUR_ACCOUNT_BLOCKED'))
				return
			}
			localStorage.setItem("username", user.username.toLowerCase());
			localStorage.setItem('access_token', response.access_token)
			localStorage.setItem("tokenTime", JSON.stringify(new Date().getTime()));
			localStorage.setItem("authUser", JSON.stringify(user))

			var account = await GET('/services/uaa/api/account')
			localStorage.setItem("authorities", JSON.stringify(account.authorities));

			var checker = false
			for (let i = 0; i < account.authorities.length; i++) {
				if (
					account.authorities[i] === "ROLE_OWNER" ||
					account.authorities[i] === "ROLE_MERCHANDISER" ||
					account.authorities[i] === "ROLE_ADMIN" ||
					account.authorities[i] === "ROLE_REPORT" ||
					account.authorities[i] === "ROLE_ORGANIZATION"
				) {
					account.service = 'web'
					var role = "ROLE_OWNER"
					checker = true
					if (account.authorities[i] === "ROLE_MERCHANDISER")
						role = "ROLE_MERCHANDISER"
					if (account.authorities[i] === "ROLE_ORGANIZATION")
						role = "ROLE_ORGANIZATION"
					if (account.authorities[i] === "ROLE_ADMIN") {
						role = "ROLE_ADMIN"
						account.service = 'admin'
						account.role = role
					}
					if (account.authorities[i] === "ROLE_REPORT") {
						role = "ROLE_REPORT"
						account.service = 'admin'
						account.role = role
					}


					localStorage.setItem("role", role);
				}
			}

			if (checker) {
				dispatch({ type: 'SET_ACCOUNT', payload: { ...account, ...response } })
				if (role === "ROLE_OWNER" || role === "ROLE_MERCHANDISER") {
					const temporarySettings = await GET("/services/web/api/user-settings")
					if (temporarySettings.settings) {
						const parsedSettings = JSON.parse(temporarySettings.settings)
						dispatch({ type: 'SET_SETTINGS', payload: parsedSettings })
					}
				}
				switch (role) {
					case "ROLE_MERCHANDISER":
						navigate("/documents-in")
						break;
					case "ROLE_ORGANIZATION":
						navigate("/organization-balance")
						break;
					case "ROLE_OWNER":
						navigate("/welcome")
						break;
					case "ROLE_ADMIN":
						navigate("/admin/clients")
						break;
					case "ROLE_REPORT":
						navigate("/aadmin/reports")
						break;
					default:
						navigate("/welcome")
						break;
				}
			}

			if (!checker) {
				toast.error(t('ERROR') + ': ' + t('YOU_DO_NOT_HAVE_ACCESS'))
				clearTemporaryStorage()
			}
		}
	}

	return (
		<>
			<div className="authentication-bg">
				<div className="w-100 h-100 vertical-center align-items-center">

					<div className="auth-card">
						<img src={logo} alt="logo" width={310} />
						<form className="form-horizontal" autoComplete="off" onSubmit={handleSubmit}>

							<div className="position-relative">
								<label htmlFor="username" className={"auth-label " + (activeInput === 'email' || user.username ? 'active' : '')}>{t('LOGIN')}</label>
								<input className="login-input"
									autoFocus
									name="username"
									value={user.username}
									onFocus={() => setActiveInput('email')}
									onBlur={() => setActiveInput('')}
									onChange={(e) => setUser({ ...user, 'username': e.target.value })} />
							</div>

							<div className="position-relative">
								<label htmlFor="username" className={"auth-label " + (activeInput === 'password' || user.password ? 'active' : '')}>{t('PASSWORD')}</label>
								<input className="login-input"
									name="password"
									type={showPassword ? "text" : "password"}
									value={user.password}
									onFocus={() => setActiveInput('password')}
									onBlur={() => setActiveInput('')}
									onChange={(e) => setUser({ ...user, 'password': e.target.value })} />
								<div className="password-icon">
									{
										showPassword ?
											<i className="uil uil-eye" onClick={() => setShowPassword(false)}></i>
											:
											<i className="uil uil-eye-slash" onClick={() => setShowPassword(true)}></i>
									}
								</div>
							</div>

							<div className="text-center">
								<button type="submit" className="login-button">
									{t('ENTER')}
								</button>
							</div>

							<div className="mt-4 text-center">
								<Link to="/auth/forgot-password" className="fw-medium text-black">{t('FORGOT_PASSWORD')}</Link>
							</div>
						</form>
					</div>
				</div>

				<div className="about-mdokon position-absolute">
					{t('VERSION')}: 1 <br />
					{t('CONTACT_CENTRE')}: +998 55 500-00-39 <br />
					{t('MADE_BY_AS')} <br />
					{t('LEGAL_ADDRESS')}: <br />
					{t('MADE_BY_AS2')}
				</div>
			</div>
		</>
	)
}

export default Login;
