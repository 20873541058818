import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'

import { useForm } from "react-hook-form"

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'
import { PGET, POST, PUT } from 'helpers/api'
import { checkRole, regexNumeric, regexPhoneNumber } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const { register, handleSubmit, formState, setValue, getValues, reset } = useForm({
		mode: "onChange"
	});
	const { errors } = formState

	const settings = useSelector(state => state.settings)

	const canCreate = checkRole('MAIN_CLIENTS_ADD')
	const canUpdate = checkRole('MAIN_CLIENTS_EDIT')

	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])
	const [showModal, setShowModal] = useState(false)

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData }
		sendData.phone1 = '998' + regexNumeric(sendData.phone1)
		if (sendData.phone2)
			sendData.phone2 = '998' + regexNumeric(sendData.phone2)

		var response;
		if (sendData.id) {
			response = await PUT('/services/web/api/clients', sendData, { loader: true })
		} else {
			response = await POST('/services/web/api/clients', sendData, { loader: true })
		}

		if (response) {
			getData()
			setShowModal(false)
			reset()
		}
	}

	function openModal(item) {
		var itemCopy = { ...item }
		if (itemCopy.phone1)
			itemCopy.phone1 = itemCopy.phone1.substring(3, 12)
		if (itemCopy.phone2)
			itemCopy.phone2 = itemCopy.phone2.substring(3, 12)

		for (const [key, value] of Object.entries(itemCopy)) {
			setValue(key, value)
		}
		setShowModal(true)
	}

	function closeModal() {
		setShowModal(false)
		reset()
	}

	async function getData() {
		const response = await PGET('/services/web/api/clients-pageList', {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET('/services/web/api/clients-pageList', { page: data.selected, size: settings.size }, { loader: true })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('CLIENTS')}
				</h4>
				<div className="d-flex">
					{canCreate &&
						<button className="btn-rounded btn btn-primary" onClick={() => setShowModal(true)}>
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</button>
					}
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={'/services/web/api/clients-pageList'} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('NAME')}</th>
									<th>{t('PHONE')}</th>
									<th>{t('PHONE')}</th>
									<th>{t('COMMENT')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.name}</td>
										<td>{regexPhoneNumber(item.phone1)}</td>
										<td>{regexPhoneNumber(item.phone2)}</td>
										<td>{item.comment}</td>
										<td className="d-flex justify-content-center">
											{canUpdate &&
												<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
													<i className="uil-edit-alt"></i>
												</div>
											}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{getValues('id') ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="form-group">
							<label>{t('TITLE')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name" {...register('name', { required: true })} />
							<span className="text-danger text-sm">{errors.name?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('PHONE')}<span className="required-mark">*</span></label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone1" {...register('phone1', { required: true, maxLength: 9, minLength: 9 })} />
							</div>
							<span className="text-danger text-sm">{errors.phone1?.type === 'required' && t('required')}</span>
							<span className="text-danger text-sm">{errors.phone1?.type === 'maxLength' && t('max', { length: 9 })}</span>
							<span className="text-danger text-sm">{errors.phone1?.type === 'minLength' && t('min', { length: 9 })}</span>
						</div>
						<div className="form-group">
							<label>{t('PHONE')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone2" {...register('phone2', { maxLength: 9, minLength: 9 })} />
							</div>
							<span className="text-danger text-sm">{errors.phone2?.type === 'maxLength' && t('max', { length: 9 })}</span>
							<span className="text-danger text-sm">{errors.phone2?.type === 'minLength' && t('min', { length: 9 })}</span>
						</div>
						<div className="form-group">
							<label>{t('NOTE')}</label>
							<input type="text" className="form-control" name="comment" {...register('comment')} />
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
