import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import Select, { components } from 'react-select'
import XLSX from 'xlsx';

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { PGET, GET } from 'helpers/api'
import { formatMoney, formatDate, returnSign, findFromArrayById } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const [logins, setLogins] = useState([])
	const [poses, setPoses] = useState([])
	const [categories, setCategories] = useState([])
	const [categoriesInitial, setCategoriesInitial] = useState([])
	const [organizations, setOrganizations] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [totalAmount, setTotalAmont] = useState({
		'totalAmount': 0,
		'currencyName': '',
	})
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: null,
		search: '',
		login: '',
	})
	const [pagination, setPagination] = useState({
		url: `/services/${reduxAccount.service}/api/report-recommended-products`,
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter(exportToExcel = false) {
		var filterUrl = `/services/${reduxAccount.service}/api/report-recommended-products`
		var urlParams = ""
		checkFilter()

		if (filterData.login)
			urlParams += returnSign(urlParams) + 'login=' + filterData.login
		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.organizationId)
			urlParams += returnSign(urlParams) + 'organizationId=' + filterData.organizationId
		if (filterData.productCategoryId)
			urlParams += returnSign(urlParams) + 'productCategoryId=' + filterData.productCategoryId
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if (`/services/${reduxAccount.service}/api/report-recommended-products` + urlParams !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = `/services/${reduxAccount.service}/api/report-recommended-products-excel` + urlParams + returnSign(filterUrl)

			var excelHeaders = [[t('POS'), t('NAME_OF_PRODUCT'), t('BARCODE'), t('BALANCE'), t('REGULATORY_BALANCE'), t('RECOMMENDED_QUANTITY'), t('AVERAGE_NUMBER_PER_DAY'), t('TOTAL_AMOUNT'), t('CURRENCY')]]

			const response = await GET(filterUrl)
			for (let i = 0; i < response.length; i++) {
				var excelItems = []
				excelItems.push(response[i].posName)
				excelItems.push(response[i].productName)
				excelItems.push(response[i].productBarcode)
				excelItems.push(response[i].balance)
				excelItems.push(response[i].normativeBalance)
				excelItems.push(response[i].needBalance)
				excelItems.push(response[i].avgProductDay)
				excelItems.push(response[i].needAmount)
				excelItems.push(response[i].currencyName)
				excelItems.push(formatDate(response[i].createdDate))
				excelHeaders.push(excelItems)
			}

			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('RECOMMENDED_ORDERS') + ".xlsx");
		} else {
			const response = await PGET(filterUrl, {}, { loader: true })
			if (response) {
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response })
			}

			filterUrl = `/services/${reduxAccount.service}/api/report-recommended-products-total` + urlParams
			const temporaryTotalAmount = await GET(filterUrl)
			setTotalAmont(temporaryTotalAmount)
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			posId: null,
			search: '',
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await PGET("/services/web/api/report-recommended-products", {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': "/services/web/api/report-recommended-products", 'response': response })
		}

		const temporaryTotalAmount = await GET("/services/web/api/report-recommended-products-total", {}, { loader: true })
		setTotalAmont(temporaryTotalAmount)
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	async function getCategories() {
		const response = await GET(`/services/${reduxAccount.service}/api/product-category-helper`)
		if (response) {
			var arr = []
			for (let i = 0; i < response.length; i++) {
				response[i]['name'] = ' ' + response[i]['name']
				response[i].child = false;
				if (response[i].parentId !== 0) {
					const index = response.findIndex(x => x.id === response[i].parentId)
					response[i].child = true;
					if (response[index].options && response[index].options.length > 0) {
						response[index].options.push(response[i])
					} else {
						response[index].options = [response[i]]
					}
				} else {
					arr.push(response[i])
				}
			}
			setCategories(arr)
			setCategoriesInitial(response)
		}
	}

	async function getOrganizations(login) {
		var urlParams = ""

		if (login)
			urlParams += returnSign(urlParams) + 'login=' + login

		const response = await GET(`/services/${reduxAccount.service}/api/organization-helper${urlParams}`)
		if (response) {
			setOrganizations(response)
		}
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	async function getLogin() {
		const response = await GET('/services/admin/api/login-helper')
		if (response) {
			setLogins(response)
		}
	}

	async function getAdminPoses(login) {
		if(!login) return
		const response = await GET(`/services/admin/api/login-pos-helper?login=${login}`)
		if (response) {
			setPoses(response)
		}
		getOrganizations(login)
	}

	useEffect(() => {
		if (reduxAccount.service === 'admin') {
			getLogin()
		}
		if (reduxAccount.service === 'web') {
			getPoses()
		}
		getCategories()
		getOrganizations()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getAdminPoses(filterData.login)
	}, [filterData.login]) // eslint-disable-line react-hooks/exhaustive-deps

	const Option = props => {
		return (
			<div style={{
				padding: props.data.child ? '0 0 0 20px' : '0', color: '#999',
				fontWeight: props.data.parentId === 0 ? 'bold' : 'normal', fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										{reduxAccount.service === 'admin' &&
											<div className="row">
												<div className="form-group col-md-6">
													<label>{t('LOGIN')}</label>
													<Select
														classNamePrefix="react-select"
														options={logins}
														value={logins.find(option => option.id=== filterData.login) || ''}
														onChange={(option) => setFilterData({ ...filterData, 'login': option.id})}
														placeholder=""
														noOptionsMessage={() => t('LIST_IS_EMPTY')}
														getOptionLabel={(option) => option.name}
														getOptionValue={(option) => option.id}
													/>
												</div>
											</div>
										}
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													classNamePrefix="react-select"
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('CATEGORY')}</label>
												<Select
													components={{ Option }}
													classNamePrefix="react-select"
													options={categories}
													value={categories.find(option => option.id === filterData.productCategoryId)}
													onChange={(option) => setFilterData({ ...filterData, 'productCategoryId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="row">
												<div className="form-group col-md-6">
													<label>{t('SUPPLIER')}</label>
													<Select
														classNamePrefix="react-select"
														options={organizations}
														value={organizations.find(option => option.id === filterData.organizationId) || ''}
														onChange={(option) => setFilterData({ ...filterData, 'organizationId': option.id })}
														placeholder=""
														noOptionsMessage={() => t('LIST_IS_EMPTY')}
														getOptionLabel={(option) => option.name}
														getOptionValue={(option) => option.id}
													/>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter()}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide" onClick={() => searchWithFilter(true)}>EXCEL</button>
							</div>
						</div>
						<div className="d-flex">
							<div className="vertical-center me-2">
								<h4>
									<b>{t('TOTAL_AMOUNT')}</b>: {formatMoney(totalAmount.totalAmount)} {totalAmount.currencyName}
								</h4>
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.organizationId &&
									<span className="me-2">
										<b>{t('ORGANIZATION')}: </b> {findFromArrayById(organizations, filterData.organizationId)}
									</span>
								}
								{filterData.productCategoryId &&
									<span className="me-2">
										<b>{t('CATEGORY')}: </b> {findFromArrayById(categoriesInitial, filterData.productCategoryId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table mb-0">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th>{t('NAME_OF_PRODUCT')}</th>
									<th className="text-center">{t('BARCODE')}</th>
									<th className="text-center">{t('BALANCE')}</th>
									<th className="text-center">{t('REGULATORY_BALANCE')}</th>
									<th className="text-center">{t('RECOMMENDED_QUANTITY')}</th>
									<th className="text-center">{t('AVERAGE_NUMBER_PER_DAY')}</th>
									<th className="text-end">{t('TOTAL_AMOUNT')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}. {item.posName}</td>
										<td>{item.productName}</td>
										<td className="text-center">{item.productBarcode}</td>
										<td className="text-center">{formatMoney(item.balance)}</td>
										<td className="text-center">{formatMoney(item.normativeBalance)}</td>
										<td className="text-center">{formatMoney(item.needBalance)}</td>
										<td className="text-center">{formatMoney(item.avgProductDay)}</td>
										<td className="text-end">{formatMoney(item.needAmount)} {item.currencyName}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>
		</>
	)
}

export default Index
