import React from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function DeleteModal({ modalDelete, setModalDelete, deleteItem }) {
	const { t } = useTranslation()

	return (
		<>
			<Modal show={modalDelete} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalDelete(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('DELETE')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('ARE_YOU_SURE_YOU_WANT_DELETE')}
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setModalDelete(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => deleteItem()}>{t('CONFIRM')}</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default DeleteModal
