import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import useDidMountEffect from "pages/parts/useDidMountEffect"

import { Modal, Dropdown } from 'react-bootstrap'
import Search from 'pages/parts/Search'
import Select, { components } from 'react-select'

import { Controller, useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from './validation'

import more_equal from "assets/images/icons/more_equal.svg"

import { DELETE, GET, PGET, POST, PUT } from 'helpers/api'
import { checkRole, findFromArrayById } from 'helpers/helpers'
import { toast } from "react-toastify"

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const { handleSubmit, formState, control, setValue, getValues, reset } = useForm({
		mode: "onChange",
		resolver: yupResolver(validationSchema),
		defaultValues: { kpiTypeId: "", posId: "", userId: "", bonusList: [] }
	});
	const { errors } = formState

	const reduxAccount = useSelector(state => state.account)

	const activates = [{ "id": null, "name": t('ALL') }, { "id": true, "name": t('YES') }, { "id": false, "name": t('NO') }]

	const canCreate = checkRole('POS_KPI_ADD')
	const canUpdate = checkRole('POS_KPI_EDIT')

	const [showModal, setShowModal] = useState(false)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: reduxAccount.posId,
		activated: null,
		search: '',
		page: 0,
		random: 0
	})
	const [selectedItem, setSelectedItem] = useState({
		kpiTypeId: 0
	})

	const [data, setData] = useState([])
	const [poses, setPoses] = useState([])
	const [users, setUsers] = useState([])
	const [bonusList, setBonusList] = useState([])
	const [kpiTypes, setKpiTypes] = useState([])
	const [categories, setCategories] = useState([])
	const [categoriesInitial, setCategoriesInitial] = useState([])

	async function changeStatus(e, index) {
		var dataCopy = [...data]
		dataCopy[index][e.target.name] = e.target.checked
		setData(dataCopy)
		var sendData = {
			kpiId: dataCopy[index]['kpiId'],
			activated: e.target.checked,
		}
		await PUT(`/services/web/api/kpi-activate`, sendData, { loader: true })
	}

	async function createUpdate(validatedData) {
		var sendData = { ...validatedData, ...selectedItem }

		for (let i = 0; i < bonusList.length; i++) {
			if (sendData.bonusType === '1') {
				if (!bonusList[i]['amount']) {
					sendData.error = true;
				}
				if (!bonusList[i]['bonus']) {
					sendData.error = true;
				}
			}
		}
		console.log(sendData);

		if (sendData.error) {
			toast.error(t('ERROR') + ': ' + t('CHECK_FILLED_INPUTS'))
			return
		}

		sendData.bonusList = bonusList;
		var response;
		if (sendData.kpiId) {
			response = await PUT('/services/web/api/kpi-users', sendData, { loader: true })
		} else {
			response = await POST('/services/web/api/kpi-users', sendData, { loader: true })
		}

		if (response) {
			getData()
			setShowModal(false)
			reset()
		}
	}

	function handleChildChange(key, value, index) {
		var bonusListCopy = [...bonusList]

		bonusListCopy[index][key] = value
		setBonusList(bonusListCopy)
	}

	function addToList() {
		var bonusListCopy = [...bonusList]
		var item = {
			amount: '',
			bonus: '',
		}
		bonusListCopy.push(item)
		setBonusList(bonusListCopy)
	}

	async function deleteItem(index) {
		var bonusListCopy = [...bonusList]
		console.log(bonusListCopy[index]);
		if (bonusListCopy[index]?.bonusId) {
			await DELETE(`services/web/api/kpi-users-bonus/${bonusListCopy[index].bonusId}`)
		}
		bonusListCopy.splice(index, 1)
		setBonusList(bonusListCopy)
	}

	async function openModal(item) {
		const response = await GET(`/services/web/api/kpi-users/${item.kpiId}`)
		if (response) {
			setBonusList(response.bonusList)
			var itemCopy = { ...response }
			for (const [key, value] of Object.entries(itemCopy)) {
				setValue(key, value)
			}
			itemCopy.name = itemCopy.kpiTypeName
			setSelectedItem(itemCopy)
			setShowModal(true)
		}
	}

	async function selectKpiType(option) {
		setValue('kpiTypeId', option.id);
		setSelectedItem({ ...selectedItem, ...option, kpiTypeId: option.id })
		// if (option.bonusType) {
		// 	getCategories()
		// }
	}

	function closeModal() {
		setShowModal(false)
		setBonusList([])
		reset()
	}

	function resetFilter() {
		setFilterData({
			...filterData,
			activated: null,
			search: '',
			random: filterData.random + 1,
		})
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.activated === null &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	async function getCategories() {
		const response = await GET('/services/web/api/product-category-helper')
		var arr = []
		for (let i = 0; i < response.length; i++) {
			response[i]['label'] = ' ' + response[i]['name']
			response[i].child = false;
			if (response[i].parentId !== 0) {
				const index = response.findIndex(x => x.id === response[i].parentId)
				response[i].child = true;
				response[i].show = false;
				if (response[index].options && response[index].options.length > 0) {
					response[index].options.push(response[i])
				} else {
					response[index].options = [response[i]]
				}
			} else {
				arr.push(response[i])
			}
		}
		setCategories(arr)
		setCategoriesInitial(response)
	}

	async function getData() {
		checkFilter()
		var filterDataCopy = { ...filterData }
		filterDataCopy.activated = filterDataCopy?.activated?.toString();
		const response = await PGET('/services/web/api/kpi-users-list', filterDataCopy, { loader: true })
		if (response) {
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	async function getPoses() {
		const response = await GET("/services/web/api/pos-helper")
		if (response) {
			setPoses(response)
		}
	}

	async function getKpiTypes() {
		const response = await GET("/services/web/api/kpi-type-helper")
		if (response) {
			setKpiTypes(response)
		}
	}

	async function getUsers() {
		const response = await GET("/services/web/api/kpi-users-helper")
		if (response) {
			setUsers(response)
			setSelectedItem({ ...selectedItem, walletId: response[0].walletId })
		}
	}

	async function search(data) {
		setData(data)
	}

	useEffect(() => {
		getData()
		getPoses()
		getUsers()
		getKpiTypes()
		getCategories()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		dispatch({ type: 'SET_FILTER_DATA', payload: { key: 'documentsIn', value: { ...filterData } } })
	}, [filterData]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getData()
	}, [filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	const Option = props => {
		return (
			<div style={{
				padding: props.data.child ? '0 0 0 20px' : '0', color: '#999',
				fontWeight: props.data.parentId === 0 ? 'bold' : 'normal', fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	const GroupHead = props => {
		return (
			<div style={{
				padding: '0',
				color: '#999',
				fontWeight: 'bold',
				backgroundColor: '#fff',
				fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('KPI')}
				</h4>
				<div className="d-flex">
					{canCreate &&
						<button className="btn-rounded btn btn-primary" onClick={() => setShowModal(true)}>
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</button>
					}
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={'/services/web/api/kpi-users-list'} search={search} getData={getData}></Search>
						</div>
						<div>
							<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('POS')}</label>
											<Select
												options={poses}
												value={poses.find(option => option.id === filterData.posId) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
										<div className="form-group col-md-6">
											<label>{t('ACTIVATED')}</label>
											<Select
												options={activates}
												value={activates.find(option => option.id === filterData.activated) || ''}
												onChange={(option) => setFilterData({ ...filterData, 'activated': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button type="button" className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button type="button" className="btn btn-primary" onClick={() => setFilterData({ ...filterData, page: 0, random: filterData.random + 1 })}>
													{t('APPLY')}
												</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block my-3">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
								{
									<span className="me-2">
										<b>{t('ACTIVATED')}: </b>
										{filterData.activated === null && t('ALL')}
										{filterData.activated === true && t('YES')}
										{filterData.activated === false && t('NO')}
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th>{t('NAME')}</th>
									<th>{t('ROLE')}</th>
									<th>{t('AMOUNT')} [ {t('BONUS')} % ]</th>
									<th className="text-center">{t('ACTIVATED')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td>{item.posName}</td>
										<td>{item.userName}</td>
										<td>{item.roleName}</td>
										<td>{item.bonus}</td>
										<td>
											<div className="d-flex justify-content-center">
												<div className="form-check form-switch form-switch-lg">
													<input type="checkbox" className="form-check-input"
														name="activated"
														checked={item.activated}
														onChange={(e) => changeStatus(e, index)} />
												</div>
											</div>
										</td>
										<td className="d-flex justify-content-center">
											{canUpdate &&
												<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
													<i className="uil-edit-alt"></i>
												</div>
											}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{getValues('id') ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="form-group">
							<label>{t('POS')}<span className="required-mark">*</span></label>
							<Controller
								name="posId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={poses}
										value={poses.find(option => option.id === getValues('posId')) || ''}
										onChange={(e) => setValue('posId', e.id)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										isDisabled={getValues('kpiId')}
									/>
								}
							/>
							<span className="text-danger text-sm">{errors.posId?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('USER')}<span className="required-mark">*</span></label>
							<Controller
								name="userId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={users}
										value={users.find(option => option.userId === getValues('userId')) || ''}
										onChange={(e) => setValue('userId', e.userId)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.userName + ` [${t(option.roleName)}]`}
										getOptionValue={(option) => option.userId}
										isDisabled={getValues('kpiId')}
									/>
								}
							/>
							<span className="text-danger text-sm">{errors.userId?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('TYPE')}<span className="required-mark">*</span></label>
							<Controller
								name="kpiTypeId"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={kpiTypes}
										value={kpiTypes.find(option => option.id === getValues('kpiTypeId')) || ''}
										onChange={(option) => selectKpiType(option)}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										isDisabled={getValues('kpiId')}
									/>
								}
							/>
							<span className="text-danger text-sm">{errors.kpiTypeId?.type === 'required' && t('required')}</span>
						</div>

						{selectedItem.bonusType &&
							<>
								<div className="d-flex justify-content-between my-3">
									<div className="vertical-center">
										<h5><b>{selectedItem.name}</b></h5>
									</div>
									<div className="d-flex justify-content-center">
										<div className="table-action-button table-action-primary-button" onClick={() => addToList()}>
											<i className="uil-plus"></i>
										</div>
									</div>
								</div>

								<div className="table-responsive">
									<table className="table table-bordered mb-0">
										<thead>
											<tr>
												{selectedItem.bonusType === 1 &&
													<th>
														{selectedItem.kpiTypeId === 3 ?
															<>
																{t('QUANTITY')}
															</>
															:
															<div className="d-flex align-items-center gap-1">
																{t('AMOUNT')} <img src={more_equal} alt="" width={16} />
															</div>
														}

													</th>
												}
												{selectedItem.bonusType === 2 &&
													<th>{t('CATEGORY')} </th>
												}
												<th>{t('BONUS')} %</th>
												<th>{t('ACTION')}</th>
											</tr>
										</thead>
										<tbody>
											{bonusList.map((item, index) => (
												<tr key={index}>
													{selectedItem.bonusType === 1 &&
														<td className="w-50">
															<div>
																<input type="number" className="form-control" name="amount"
																	value={item.amount}
																	onChange={(e) => handleChildChange('amount', e.target.value, index)} />
															</div>
														</td>
													}
													{selectedItem.bonusType === 2 &&
														<td className="w-75">
															<div className="w-100">
																<Select
																	components={{ GroupHeading: GroupHead, Option, }}
																	menuPosition="fixed"
																	value={categoriesInitial.find(option => option.id === item.productCategoryId) || ''}
																	options={categories}
																	onChange={(option) => handleChildChange('productCategoryId', option.id, index)}
																	placeholder=""
																	noOptionsMessage={() => t('LIST_IS_EMPTY')}
																	getOptionLabel={(option) => option.name}
																	getOptionValue={(option) => option.id}
																/>
															</div>
														</td>
													}
													<td className="w-50">
														<div>
															<input type="number" className="form-control" name="bonus"
																value={item.bonus}
																onChange={(e) => handleChildChange('bonus', e.target.value, index)} />
														</div>
													</td>
													<td className="vertical-middle">
														<div className="d-flex justify-content-center">
															<div className="table-action-button table-action-danger-button" onClick={() => deleteItem(index)}>
																<i className="uil uil-times"></i>
															</div>
														</div>
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</>
						}

						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100" disabled={bonusList.length === 0}>{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
