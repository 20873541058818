import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx';

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'
import TableControl from 'pages/parts/TableControl'

import { PGET, GET } from 'helpers/api'
import { formatMoney, returnSign, formatDateBackend, findFromArrayById, formatDate, formatDateWithTime } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const date = new Date()

	const reduxSettings = useSelector(state => state.settings)
	const reduxAccount = useSelector(state => state.account)

	const [logins, setLogins] = useState([])
	const [poses, setPoses] = useState([])
	const [categories, setCategories] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [totalAmount, setTotalAmont] = useState([])
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		endDate: formatDateBackend(new Date()),
		posId: null,
		productCategoryId: null,
		groupBy: false,
		search: '',
		login: '',
	})
	const [pagination, setPagination] = useState({
		url: `/services/${reduxAccount.service}/api/report-write-off`,
		response: null
	})
	const [data, setData] = useState([])
	const [modalTableControl, setModalTableControl] = useState(false)

	async function searchWithFilter(exportToExcel = false, tableSort = "") {
		var filterUrl = `/services/${reduxAccount.service}/api/report-write-off`
		var urlParams = ""
		checkFilter()

		if (filterData.login)
			urlParams += returnSign(urlParams) + 'login=' + filterData.login
		if (filterData.startDate)
			urlParams += returnSign(urlParams) + 'startDate=' + filterData.startDate
		if (filterData.endDate)
			urlParams += returnSign(urlParams) + 'endDate=' + filterData.endDate
		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.productCategoryId)
			urlParams += returnSign(urlParams) + 'productCategoryId=' + filterData.productCategoryId
		if (filterData.groupBy)
			urlParams += returnSign(urlParams) + 'groupBy=' + filterData.groupBy
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if (`/services/${reduxAccount.service}/api/report-write-off` + urlParams !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		if (tableSort) {
			var filterDataCopy = { ...filterData }
			if (filterDataCopy.tableSortKey === tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + tableSort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = `/services/${reduxAccount.service}/api/report-write-off-excel` + urlParams + returnSign(filterUrl)

			var excelHeaders = [[t('POS'), t('NAME_OF_PRODUCT'), t('BARCODE'), t('QUANTITY'), t('UNIT'),
			t('ADMISSION_PRICE'), t('SALE_PRICE'), t('RECEIPT_DATE'), t('TOTAL_AMOUNT')]]
			const response = await GET(filterUrl)
			for (let i = 0; i < response.length; i++) {
				var excelItems = []
				excelItems.push(response[i].posName)
				excelItems.push(response[i].productName)
				excelItems.push(response[i].productBarcode)
				excelItems.push(response[i].quantity)
				excelItems.push(response[i].uomName)
				excelItems.push(response[i].price)
				excelItems.push(response[i].salePrice)
				excelItems.push(formatDateWithTime(response[i].createdDate))
				excelItems.push(response[i].totalAmount)
				excelItems.push(response[i].deleted ? t('DELETED') : '')
				excelHeaders.push(excelItems)
			}
			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('INCOME_REPORT') + ".xlsx");
		} else {
			const response = await PGET(filterUrl, {}, { loader: true })
			if (response) {
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response })
			}
			filterUrl = `/services/${reduxAccount.service}/api/report-write-off-total` + urlParams
			const temporaryTotalAmount = await GET(filterUrl)
			setTotalAmont(temporaryTotalAmount)
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			startDate: null,
			endDate: null,
			posId: null,
			productCategoryId: null,
			groupBy: false,
			search: '',
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await PGET(`/services/${reduxAccount.service}/api/report-write-off`, {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': `/services/${reduxAccount.service}/api/report-write-off`, 'response': response })
		}
		const response2 = await GET(`/services/${reduxAccount.service}/api/report-write-off-total`, {}, { loader: false })
		if (response2) {
			setTotalAmont(response2)
		}
	}

	function checkFilter() {
		if (
			filterData.startDate === null &&
			filterData.endDate === null &&
			filterData.posId === null &&
			filterData.groupBy === false &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	async function getCategories() {
		const response = await GET(`/services/${reduxAccount.service}/api/product-category-helper`)
		if (response) {
			setCategories(response)
		}
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	async function getLogin() {
		const response = await GET('/services/admin/api/login-helper')
		if (response) {
			setLogins(response)
		}
	}

	async function getAdminPoses(login) {
		if (!login) return
		const response = await GET(`/services/admin/api/login-pos-helper?login=${login}`)
		if (response) {
			setPoses(response)
		}
	}

	useEffect(() => {
		if (reduxAccount.service === 'admin') {
			getLogin()
		}
		if (reduxAccount.service === 'web') {
			getPoses()
		}
		getCategories()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getAdminPoses(filterData.login)
	}, [filterData.login]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										{reduxAccount.service === 'admin' &&
											<div className="row">
												<div className="form-group col-md-6">
													<label>{t('LOGIN')}</label>
													<Select
														classNamePrefix="react-select"
														options={logins}
														value={logins.find(option => option.id === filterData.login) || ''}
														onChange={(option) => setFilterData({ ...filterData, 'login': option.id })}
														placeholder=""
														noOptionsMessage={() => t('LIST_IS_EMPTY')}
														getOptionLabel={(option) => option.name}
														getOptionValue={(option) => option.id}
													/>
												</div>
											</div>
										}
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													classNamePrefix="react-select"
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('CATEGORY')}</label>
												<Select
													classNamePrefix="react-select"
													options={categories}
													value={categories.find(option => option.id === filterData.productCategoryId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'productCategoryId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('PERIOD')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.startDate ? new Date(filterData.startDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.endDate ? new Date(filterData.endDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-6">
												<div className="d-flex justify-content-between mb-2">
													<p className="fw-600 vertical-center">{t('GROUPING')}</p>
													<div className="form-check form-switch form-switch-lg">
														<input type="checkbox" className="form-check-input"
															checked={filterData.groupBy}
															onChange={(e) => setFilterData({ ...filterData, 'groupBy': e.target.checked })} />
													</div>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide" onClick={() => searchWithFilter(true)}>EXCEL</button>
							</div>
						</div>
						<div className="d-flex">
							<div className="vertical-center me-2">
								<h4>
									<b>{t('TOTAL_AMOUNT')}</b>:
								</h4>
							</div>
							<div className="vertical-center">
								{totalAmount.map((item, index) => (
									<h5 className="text-end" key={index}>
										<span className="me-1">{formatMoney(item.totalAmount)}</span>
										<b>{item.currencyName}</b>
									</h5>
								))
								}
							</div>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.startDate || filterData.endDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.startDate &&
											<span>{formatDate(filterData.startDate)}</span>
										}
										{(filterData.startDate && filterData.endDate) &&
											<span>|</span>
										}
										{filterData.endDate &&
											<span>{formatDate(filterData.endDate)}</span>
										}
									</span>
								}
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.productCategoryId &&
									<span className="me-2">
										<b>{t('CATEGORY')}: </b> {findFromArrayById(categories, filterData.productCategoryId)}
									</span>
								}
								{filterData.groupBy &&
									<span className="me-2">
										<b>{t('GROUPING')}: </b> {t('ACTIVATED')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table mb-0">
							<thead>
								<tr>
									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'posName')}>
										{t('POS')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'posName') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'posName') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'productName')}>
										{t('NAME_OF_PRODUCT')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'productName') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'productName') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'productBarcode')}>
										{t('BARCODE')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'productBarcode') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'productBarcode') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'quantity')}>
										{t('QUANTITY')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'quantity') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'quantity') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'price')}>
										{t('ADMISSION_PRICE')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'price') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'price') && 'sort-active')}></i>
										</div>
									</th>
									{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.wholesalePrice) &&
										<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'wholesalePrice')}>
											{t('WHOLESALE_PRICE')}
											<div className="table-sort">
												<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'wholesalePrice') && 'sort-active')}></i>
												<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'wholesalePrice') && 'sort-active')}></i>
											</div>
										</th>
									}
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'createdDate')}>
										{t('DATE')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'createdDate') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'createdDate') && 'sort-active')}></i>
										</div>
									</th>
									<th className="text-center">{t('LOGIN')}</th>
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'totalAmount')}>
										<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
										{t('TOTAL_AMOUNT')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'totalAmount') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'totalAmount') && 'sort-active')}></i>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}. {item.posName}</td>
										<td>{item.productName}</td>
										<td className="text-center">{item.productBarcode}</td>
										<td className="text-center">{formatMoney(item.quantity)} {item.uomName}</td>
										<td className="text-center text-nowrap">{formatMoney(item.price)}</td>
										{(!reduxSettings.tableReportIncomes || reduxSettings.tableReportIncomes?.wholesalePrice) &&
											<td className="text-center text-nowrap">{formatMoney(item.wholesalePrice)}</td>
										}
										<td className="text-center text-nowrap">
											{item.deleted ?
												<Link to={'/documents-in/preview/' + item.id} className="bg-danger bg-border text-white cursor">
													{formatDateWithTime(item.createdDate)}
												</Link>
												:
												<>{formatDateWithTime(item.createdDate)}</>
											}
										</td>
										<td className="text-center text-nowrap">{item.createdBy}</td>
										<td className="text-end text-nowrap">{formatMoney(item.totalAmount)}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableReportIncomes'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Index
