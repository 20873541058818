import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import XLSX from 'xlsx'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'
import TableControl from 'pages/parts/TableControl'

import { PGET, GET } from 'helpers/api'
import { formatMoney, returnSign } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)

	var url = 'report-balance-product-organization'
	var totalUrl = 'report-balance-total-organization'

	const [totalAmount, setTotalAmont] = useState(0)
	const [uomModal, setUomModal] = useState(false)
	const [modalTableControl, setModalTableControl] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: null,
		dateBalance: null,
		productCategoryId: null,
		currencyId: 1,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: `/services/web/api/${url}`,
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter(exportToExcel = false, tableSort = "") {
		var filterUrl = `/services/web/api/${url}`
		var urlParams = ""

		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.organizationId)
			urlParams += returnSign(urlParams) + 'organizationId=' + filterData.organizationId
		if (filterData.productCategoryId)
			urlParams += returnSign(urlParams) + 'productCategoryId=' + filterData.productCategoryId
		if (filterData?.seasonal)
			urlParams += returnSign(urlParams) + 'seasonal=' + filterData?.seasonal
		if (filterData.dateBalance)
			urlParams += returnSign(urlParams) + 'dateBalance=' + filterData.dateBalance
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if (tableSort) {
			var filterDataCopy = { ...filterData }
			if (filterDataCopy.tableSortKey === tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + tableSort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = `/services/web/api/${url}-excel` + urlParams + returnSign(filterUrl)

			var excelHeaders = [[t('POS'), t('PRODUCT_ID'), t('NAME_OF_PRODUCT'), t('BARCODE'), t('QUANTITY'), t('UNIT'), t('TOTAL_AMOUNT')]]
			const response = await GET(filterUrl)
			for (let i = 0; i < response.length; i++) {
				var excelItems = []
				excelItems.push(response[i].posName)
				excelItems.push(response[i].productId)
				excelItems.push(response[i].productName)
				excelItems.push(response[i].productBarcode)
				excelItems.push(response[i].balance)
				excelItems.push(response[i].uomName)
				excelItems.push(response[i].totalAmount)
				excelHeaders.push(excelItems)
			}
			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('BALANCE_REPORT') + ".xlsx");
		} else {
			const response = await PGET(filterUrl, {}, { loader: true })
			if (response) {
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response })
			}

			filterUrl = `/services/web/api/${totalUrl}` + urlParams
			const temporaryTotalAmount = await GET(filterUrl)
			setTotalAmont(temporaryTotalAmount)
		}
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide" onClick={() => searchWithFilter(true)}>EXCEL</button>
							</div>
						</div>
						<div className={"vertical-center " + (totalAmount?.uomList?.length > 4 ? 'cursor' : '')} onClick={() => setUomModal(true)}>
							<h4>
								<b>{t('TOTAL_AMOUNT')}</b>: {formatMoney(totalAmount.totalAmount)}
							</h4>
							<h4 className="d-flex justify-content-between">
								<div><b>{t('QUANTITY')}</b>:</div> {formatMoney(totalAmount.countProduct)}
							</h4>
							{totalAmount?.uomList?.length < 4 &&
								totalAmount?.uomList?.map((item, index) => (
									<h4 key={index} className="d-flex justify-content-between mt-2">
										<div><b>{t(item.uomName)}</b>:</div>{formatMoney(item.countProduct)}  |  {formatMoney(item.balanceProduct)}
									</h4>
								))
							}
						</div>
					</div>

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									{(!reduxSettings.tableReport || reduxSettings.tableReport?.pos) &&
										<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'posName')}>
											{t('POS')}
											<div className="table-sort">
												<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'posName') && 'sort-active')}></i>
												<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'posName') && 'sort-active')}></i>
											</div>
										</th>
									}
									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'productName')}>
										{t('NAME_OF_PRODUCT')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'productName') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'productName') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable text-center cursor" onClick={() => searchWithFilter(false, 'productBarcode')}>
										{t('BARCODE')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'productBarcode') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'productBarcode') && 'sort-active')}></i>
										</div>
									</th>
									{(!reduxSettings.tableReport || reduxSettings.tableReport?.artikul) &&
										<th className="th-sortable text-center cursor" onClick={() => searchWithFilter(false, 'productBarcode')}>
											{t('ARTIKUL')}
											<div className="table-sort">
												<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'productArtikul') && 'sort-active')}></i>
												<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'productArtikul') && 'sort-active')}></i>
											</div>
										</th>
									}
									<th className="th-sortable text-center cursor" onClick={() => searchWithFilter(false, 'balance')}>
										{t('QUANTITY')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'balance') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'balance') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable text-center cursor table-control" onClick={() => searchWithFilter(false, 'totalAmount')}>
										<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
										{t('TOTAL_AMOUNT')}
										<div className="table-sort me-2">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'totalAmount') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'totalAmount') && 'sort-active')}></i>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										{(!reduxSettings.tableReport || reduxSettings.tableReport?.pos) &&
											<td>{item.rowNum}. {item.posName}</td>
										}
										<td>{item.productName}</td>
										<td className="text-center">{item.productBarcode}</td>
										{(!reduxSettings.tableReport || reduxSettings.tableReport?.artikul) &&
											<td className="text-center">{item.productArtikul}</td>
										}
										<td className="text-center">{formatMoney(item.balance)} {item.uomName}</td>
										<td className="text-end">{formatMoney(item.totalAmount)}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableReport'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}

			{/* MODAL PAYMENT */}
			<Modal show={uomModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setUomModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('UNIT_OF_MEASUREMENT')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<table className="table">
						<thead>
							<th>{t('UNIT_OF_MEASUREMENT')}</th>
							<th>{t('RESIDUE')}</th>
							<th className="text-end">{t('QUANTITY')}</th>
						</thead>
						<tbody>
							{totalAmount?.uomList?.map((item, index) => (
								<tr key={index}>
									<td>{item.uomName}</td>
									<td>{formatMoney(item.balanceProduct)}</td>
									<td className="text-end">{formatMoney(item.countProduct)}</td>
								</tr>
							))
							}

						</tbody>
					</table>
				</Modal.Body>
			</Modal>
			{/* MODAL PAYMENT */}


		</>
	)
}

export default Index
