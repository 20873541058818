import React from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function ConfirmModal({ modalConfirm, setModalConfirm, confrim, text='CONFIRM', description='ARE_YOU_SURE_YOU_WANT' }) {
	const { t } = useTranslation()

	return (
		<>
			<Modal show={modalConfirm} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalConfirm(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t(text)}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t(description)}
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setModalConfirm(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => confrim()}>{t('CONFIRM')}</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ConfirmModal
