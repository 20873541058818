import React, { useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
//import { useSelector } from 'react-redux'

import useDidMountEffect from 'pages/parts/useDidMountEffect'
import { PGET } from 'helpers/api'

function FilterPagination({ pagination = {}, paginate,  }) {
	//const settings = useSelector(state => state.settings)

	const [pageCount, setPageCount] = useState(0)
	const [inputPage, setInputPage] = useState(0)
	const [currentPage, setCurrentPage] = useState(0)

	async function getCurrentPage(pageNumber) {
		const response = await PGET(pagination.url, { page: pageNumber, size: 20 }, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / 20))
			paginate(response.data, response)
			setCurrentPage(pageNumber)
		}
	}

	function handlePageClick() {
		const newPage = parseInt(inputPage)

		if (newPage >= 1) {
			setCurrentPage(newPage - 1)
			getCurrentPage(newPage - 1)
		}
	}

	useEffect(() => {
		if (pagination?.response && pagination?.response?.headers['x-total-count']) {
			setPageCount(Math.ceil(pagination.response.headers['x-total-count'] / 20))
		}
	}, [pagination]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => { // if need call the same page again
		if(pagination.increment) {
			getCurrentPage(currentPage)
		}
	}, [pagination.increment]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{pageCount > 1 &&
				<div className="d-flex">
					<div className="pagination position-relative">
						<input type="number" className="pagination-input"
							onChange={(e) => setInputPage(e.target.value)}
							onKeyUp={(event) => {
								if (event.key === 'Enter') {
									handlePageClick()
								}
							}}
						/>
						<div className="pagination-input-icon" onClick={() => handlePageClick()}>
							<i className="uil uil-angle-right"></i>
						</div>
					</div>

					<ReactPaginate
						forcePage={currentPage}
						breakLabel={'...'}
						breakClassName={'break-me'}
						pageCount={pageCount}
						marginPagesDisplayed={3}
						pageRangeDisplayed={3}
						onPageChange={(page) => getCurrentPage(page.selected)}
						containerClassName={'pagination'}
						activeClassName={'active'}
						pageClassName={'page-item'}
					/>
				</div>
			}
		</>
	)
}

export default FilterPagination
