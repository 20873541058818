import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal, Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import { toast } from 'react-toastify'
import XLSX from 'xlsx';
import Select from 'react-select'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'
import TableControl from 'pages/parts/TableControl'

import { PGET, GET, PUT } from 'helpers/api'
import { formatMoney, formatDate, formatDateBackend, returnSign, formatDateWithTime, findFromArrayById, checkRole } from 'helpers/helpers'


function Nomenclature() {
	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)
	const reduxAccount = useSelector(state => state.account)

	const canUpdate = checkRole('POS_NOMENKLATURA_EDIT')

	const [pagination, setPagination] = useState({
		url: `/services/web/api/nomenclature-pageList/${reduxAccount.posId}`,
		response: null,
		increment: 0,
	})
	const [poses, setPoses] = useState([])
	const [organizations, setOrganizations] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [dataDuplicate, setDataDuplicate] = useState({})
	const [changedItem, setChangedItem] = useState({})
	const [showModal, setShowModal] = useState(false)
	const [totalAmount, setTotalAmont] = useState(0)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: reduxAccount.posId,
		organizationId: null,
		balance: '',
		search: '',
	})
	const [data, setData] = useState([])
	const [modalTableControl, setModalTableControl] = useState(false)

	async function update() {
		const response = await PUT('/services/web/api/nomenclature', changedItem, { loader: true })
		if (response) {
			setPagination({ ...pagination, increment: pagination.increment + 1 })
		}
		setShowModal(false)
	}

	function selectedItemEdit(index) {
		var dataCopy = [...data]
		setDataDuplicate({ ...dataCopy[index] })

		dataCopy[index].showInput = true
		for (let i = 0; i < dataCopy.length; i++) {
			if (!dataCopy[i].showInput) {
				dataCopy[i].visible = false
			}
		}
	}

	function cancelSelectedItemEdit(index) {
		var dataCopy = [...data]
		dataCopy[index] = { ...dataDuplicate }
		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i].visible = true
			dataCopy[i].showInput = false
		}
		setData(dataCopy)
	}

	function confirmModalShow(index) {
		var dataCopy = [...data]
		if (
			(dataCopy[index].price !== "" && dataCopy[index].price > 0.0001) &&
			(dataCopy[index].wholesalePrice !== "" && Number(dataCopy[index].wholesalePrice) >= Number(dataCopy[index].price)) &&
			(dataCopy[index].salePrice !== "" && Number(dataCopy[index].salePrice) >= Number(dataCopy[index].wholesalePrice))
		) {
			setChangedItem({ ...dataCopy[index] })
			setShowModal(true)
		} else {
			toast.dismiss();
			toast.error(t('ERROR') + ': ' + t('CHECK_FILLED_INPUTS'))
		}
	}

	function handleExpDateChange(date, index) {
		var dataCopy = [...data]
		dataCopy[index]['expDate'] = formatDateBackend(date)
		setData(dataCopy)
	}

	function handleInputChange(e, index) {
		var dataCopy = [...data]
		dataCopy[index][e.target.name] = e.target.value
		setData(dataCopy)
	}

	async function searchWithFilter(exportToExcel = false) {
		var filterUrl = `/services/web/api/nomenclature-pageList/${filterData.posId}`
		var urlParams = ""
		checkFilter()

		if (filterData.organizationId)
			urlParams += returnSign(urlParams) + 'organizationId=' + filterData.organizationId
		if (filterData.balance)
			urlParams += returnSign(urlParams) + 'balance=' + filterData.balance
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if (`/services/web/api/nomenclature-pageList/${filterData.posId}` + urlParams !== filterUrl)
			setFilterDataExist(true)

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = `/services/web/api/nomenclature-excel/${filterData.posId}` + urlParams + returnSign(urlParams)
			var excelHeaders = [[t('POS'), t('PRODUCT_ID'), t('NAME_OF_PRODUCT'), t('SUPPLIER'), t('BARCODE'), t('ARTIKUL')
				, t('ADMISSION_PRICE'), t('WHOLESALE_PRICE'), t('BANK_PRICE'), t('UNIT_PRICE'), t('CURRENCY'), t('SERIAL'), t('EXPIRATION_DATE')
				, t('IN_THE_WAREHOUSE'), t('UNIT_OF_MEASUREMENT'),]]

			const response = await GET(filterUrl)
			for (let i = 0; i < response.length; i++) {
				var excelItems = []

				excelItems.push(response[i].posName)
				excelItems.push(response[i].productId)
				excelItems.push(response[i].productName)
				excelItems.push(response[i].organizationName)
				excelItems.push(response[i].barcode)
				excelItems.push(response[i].artikul)
				excelItems.push(formatMoney(response[i].price))
				excelItems.push(formatMoney(response[i].bankPrice))
				excelItems.push(formatMoney(response[i].wholesalePrice))
				excelItems.push(formatMoney(response[i].salePrice))
				excelItems.push(response[i].currencyName)
				excelItems.push(response[i].serial)
				excelItems.push(formatDateWithTime(response[i].expDate))
				excelItems.push(formatMoney(response[i].balance))
				excelItems.push(response[i].uomName)
				excelHeaders.push(excelItems)
			}
			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('NOMENCLATURE') + ".xlsx");
		} else {
			const response = await PGET(filterUrl, {}, { loader: true })
			for (let i = 0; i < response?.data?.length; i++) {
				response.data[i]['showInput'] = false
				response.data[i]['visible'] = true
			}
			setData(response?.data)
			setPagination({ ...pagination, 'url': filterUrl, 'response': response })

			filterUrl = `/services/web/api/nomenclature-total/${filterData.posId}` + urlParams
			const temporaryTotalAmount = await GET(filterUrl)
			setTotalAmont(temporaryTotalAmount)
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			...filterData,
			organizationId: null,
			balance: '',
			search: '',
		})
		getData()
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.organizationId === null &&
			filterData.balance === '' &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getData() {
		const response = await PGET(`/services/web/api/nomenclature-pageList/${reduxAccount.posId}`, {}, { loader: true })
		if (response) {
			for (let i = 0; i < response.data.length; i++) {
				response.data[i]['showInput'] = false
				response.data[i]['visible'] = true
			}
			setData(response.data)
			setPagination({ ...pagination, 'url': `/services/web/api/nomenclature-pageList/${reduxAccount.posId}`, 'response': response })
		}

		const response2 = await GET(`/services/web/api/nomenclature-total/${reduxAccount.posId}`, {}, { loader: false })
		if (response2) {
			setTotalAmont(response2)
		}
	}

	async function paginate(newPageData) {
		for (let i = 0; i < newPageData.length; i++) {
			newPageData[i]['showInput'] = false
			newPageData[i]['visible'] = true
		}
		setData(newPageData)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	async function getOrganizations() {
		const response = await GET('/services/web/api/organization-helper')
		if (response) {
			setOrganizations(response)
		}
	}

	useEffect(() => {
		getPoses()
		getOrganizations()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex mb-2">
				<h4 className="vertical-center">
					{t('NOMENCLATURE')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('SUPPLIER')}</label>
												<Select
													options={organizations}
													value={organizations.find(option => option.id === filterData.organizationId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'organizationId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('IN_THE_WAREHOUSE')}</label>
												<input type="number" className="form-control" value={filterData.balance} onChange={(e) => setFilterData({ ...filterData, 'balance': e.target.value })} />
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button type="button" className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button type="button" className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div className="h-100">
								<button type="button" className="btn btn-outline-primary btn-wide" onClick={() => searchWithFilter(true)}>
									EXCEL
								</button>
							</div>
						</div>
						<div className="vertical-center">
							<h4>
								<b className="me-2">{t('TOTAL_AMOUNT')}:</b>
								{formatMoney(totalAmount)}
							</h4>
						</div>
					</div>


					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.organizationId &&
									<span className="me-2">
										<b>{t('SUPPLIER')}: </b> {findFromArrayById(organizations, filterData.organizationId)}
									</span>
								}
								{filterData.balance &&
									<span className="me-2">
										<b>{t('IN_THE_WAREHOUSE')}: </b>
										<span>{filterData.balance}</span>
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table mb-0">
							<thead>
								<tr>
									<th>№</th>
									{(!reduxSettings.tableNomenclature || reduxSettings.tableNomenclature?.pos) &&
										<th>{t('POS')}</th>
									}
									<th>{t('NAME_OF_PRODUCT')}</th>
									{(!reduxSettings.tableNomenclature || reduxSettings.tableNomenclature?.categoryName) &&
										<th>{t('CATEGORY')}</th>
									}
									<th>{t('SUPPLIER')}</th>
									<th className="text-center">{t('BARCODE')}</th>
									{(!reduxSettings.tableNomenclature || reduxSettings.tableNomenclature?.artikul) &&
										<th className="text-center">{t('ARTIKUL')}</th>
									}
									<th className="text-center">{t('ADMISSION_PRICE')}</th>
									{(!reduxSettings.tableNomenclature || reduxSettings.tableNomenclature?.wholesalePrice) &&
										<th className="text-center">{t('WHOLESALE_PRICE')}</th>
									}
									{(!reduxSettings.tableNomenclature || reduxSettings.tableNomenclature?.bankPrice) &&
										<th className="text-center">{t('BANK_PRICE')}</th>
									}
									<th className="text-center">{t('UNIT_PRICE')}</th>
									{(!reduxSettings.tableNomenclature || reduxSettings.tableNomenclature?.currencyName) &&
										<th className="text-center">{t('CURRENCY')}</th>
									}
									{(!reduxSettings.tableNomenclature || reduxSettings.tableNomenclature?.serial) &&
										<th className="text-center">{t('SERIAL')}</th>
									}
									{(!reduxSettings.tableNomenclature || reduxSettings.tableNomenclature?.expDate) &&
										<th className="text-center">{t('EXPIRATION_DATE')}</th>
									}
									<th className="text-center">{t('IN_THE_WAREHOUSE')}</th>
									<th className="text-center table-control">
										<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
										{t('ACTION')}
									</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}</td>
										{(!reduxSettings.tableNomenclature || reduxSettings.tableNomenclature?.pos) &&
											<td title={item.posName}>{item.posName}</td>
										}
										<td>{item.productName}</td>
										{(!reduxSettings.tableNomenclature || reduxSettings.tableNomenclature?.categoryName) &&
											<td>{item.categoryName}</td>
										}
										<td>{item.organizationName}</td>
										<td className="text-center">{item.barcode}</td>
										{(!reduxSettings.tableNomenclature || reduxSettings.tableNomenclature?.artikul) &&
											<td className="text-center">{item.artikul}</td>
										}
										<td className="text-center text-nowrap">
											{!item.showInput ?
												<span>{formatMoney(item.price)}</span>
												:
												<input type="number" step="0.01" min="0" className={"auto-width-input me-2 " + (!item.price && "required-border")}
													name="price" value={item.price} onChange={(e) => handleInputChange(e, index)} />
											}
										</td>
										{(!reduxSettings.tableNomenclature || reduxSettings.tableNomenclature?.wholesalePrice) &&
											<td className="text-center text-nowrap">
												{!item.showInput ?
													<span>{formatMoney(item.wholesalePrice)}</span>
													:
													<input type="number" step="0.01" min="0" className={"auto-width-input " + ((Number(item.price) > Number(item.wholesalePrice)) && "required-border")}
														name="wholesalePrice" value={item.wholesalePrice} onChange={(e) => handleInputChange(e, index)} />
												}
											</td>
										}
										{(!reduxSettings.tableNomenclature || reduxSettings.tableNomenclature?.bankPrice) &&
											<td className="text-center text-nowrap">
												{!item.showInput ?
													<span>{formatMoney(item.bankPrice)}</span>
													:
													<input type="number" step="0.01" min="0" className={"auto-width-input " + ((Number(item.price) > Number(item.bankPrice)) && "required-border")}
														name="bankPrice" value={item.bankPrice} onChange={(e) => handleInputChange(e, index)} />
												}
											</td>
										}
										<td className="text-center text-nowrap">
											{!item.showInput ?
												<span>{formatMoney(item.salePrice)}</span>
												:
												<input type="number" step="0.01" min="0" className={"auto-width-input " + ((Number(item.wholesalePrice) > Number(item.salePrice)) && "required-border")}
													name="salePrice" value={item.salePrice} onChange={(e) => handleInputChange(e, index)} />
											}
										</td>
										{(!reduxSettings.tableNomenclature || reduxSettings.tableNomenclature?.currencyName) &&
											<td className="text-center">{item.currencyName}</td>
										}
										{(!reduxSettings.tableNomenclature || reduxSettings.tableNomenclature?.serial) &&
											<td className="text-center">{item.serial}</td>
										}
										{(!reduxSettings.tableNomenclature || reduxSettings.tableNomenclature?.expDate) &&
											<td className="text-center">
												{!item.showInput ?
													<span>{formatDate(item.expDate)}</span>
													:
													<DatePicker
														className="auto-width-input"
														selected={item.expDate ? new Date(item.expDate) : ''}
														dateFormat="dd.MM.yyyy"
														onChange={(date) => handleExpDateChange(date, index)} />
												}
											</td>
										}
										<td className="text-center">{item.balance} {item.uomName}</td>
										<td className="d-flex justify-content-center">
											{(!item.showInput && item.visible && item.lastId && canUpdate) &&
												<div className="table-action-button table-action-primary-button" onClick={() => selectedItemEdit(index)}>
													<i className="uil-edit-alt"></i>
												</div>
											}
											{item.showInput &&
												<>
													<div className="table-action-button table-action-primary-button me-2" onClick={() => confirmModalShow(index)}>
														<i className="uil-check"></i>
													</div>
													<div className="table-action-button table-action-danger-button" onClick={() => cancelSelectedItemEdit(index)}>
														<i className="uil-times"></i>
													</div>
												</>
											}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			{/* MODAL CONFIRM */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('CONFIRM')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="table-responsive">
						<table className="table">
							<thead>
								<tr>
									<th></th>
									<th className="text-center">До</th>
									<th className="text-center">После</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="fw-700">{t('IN_THE_WAREHOUSE')}</td>
									<td className="text-center">{dataDuplicate.balance}</td>
									<td className="text-center">{changedItem.balance}</td>
								</tr>
								<tr>
									<td className="fw-700">{t('ADMISSION_PRICE')}</td>
									<td className="text-center">{dataDuplicate.price}</td>
									<td className="text-center">{changedItem.price}</td>
								</tr>
								<tr>
									<td className="fw-700">{t('WHOLESALE_PRICE')}</td>
									<td className="text-center">{dataDuplicate.wholesalePrice}</td>
									<td className="text-center">{changedItem.wholesalePrice}</td>
								</tr>
								<tr>
									<td className="fw-700">{t('UNIT_PRICE')}</td>
									<td className="text-center">{dataDuplicate.salePrice}</td>
									<td className="text-center">{changedItem.salePrice}</td>
								</tr>
								<tr>
									<td className="fw-700">{t('CURRENCY')}</td>
									<td className="text-center">{dataDuplicate.currencyName}</td>
									<td className="text-center">{changedItem.currencyName}</td>
								</tr>
								<tr>
									<td className="fw-700">{t('EXPIRATION_DATE')}</td>
									<td className="text-center">{dataDuplicate.expDate}</td>
									<td className="text-center">{changedItem.expDate}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setShowModal(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => update()}>{t('SAVE')}</button>
					</div>
				</Modal.Footer>
			</Modal>
			{/* MODAL CONFIRM */}

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableNomenclature'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Nomenclature