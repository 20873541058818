import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'

import { Modal, Dropdown } from 'react-bootstrap'
import LanguageDropdown from "components/topbarDropdown/LanguageDropdown";

//Import Scrollbar
import { exportMenu } from './menues/owner/menu'
import { exportMenuOrganization } from './menues/organization/menu'

import arrow_down from 'assets/images/icons/arrow_down.svg'
import logo from "assets/images/logo.svg";
import { GET, POST } from "helpers/api";
import { clearTemporaryStorage } from "helpers/helpers";


function Header() {
	const role = localStorage.getItem('role');

	const { t } = useTranslation()
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const reduxAccount = useSelector(state => state.account)

	const [modalLogout, setModalLogout] = useState(false)
	const [showDropdownIndex, setShowDropdownIndex] = useState(false)

	const [balance, setBalance] = useState(0)

	const [menu, setMenu] = useState([])
	const [activeItem, setActiveItem] = useState({
		index: 0,
		parentIndex: 0,
		children: []
	})

	async function logout() {
		if (process.env.NODE_ENV === 'development') {
			dispatch({ type: 'USER_LOGGED_OUT', payload: null })
			clearTemporaryStorage()
			navigate('/auth/login')
			return
		}
		const response = await POST('/auth/logout')
		if (response) {
			// dispatch({ type: 'USER_LOGGED_OUT', payload: null })
			clearTemporaryStorage()
			navigate('/auth/login')
		}
	}

	function setActiveMenu(copyMenu) {
		var menuCopy = [...menu]
		if (copyMenu?.length > 0) {
			menuCopy = copyMenu
		}
		for (let k = 0; k < menuCopy.length; k++) {
			var grandParent = menuCopy[k]
			for (let i = 0; i < grandParent?.children?.length; i++) {
				var parent = grandParent?.children[i];
				for (let j = 0; j < parent?.children?.length; j++) {
					var child = parent?.children[j]
					if (location.pathname.includes(child?.url)) {
						child['active'] = true;
						setActiveItem({ ...activeItem, children: parent.children, index: i })
					} else {
						child['active'] = false;
					}
				}
			}
		}
		setMenu(menuCopy);
	}

	function handleMouseOverParent(index) {
		setShowDropdownIndex(index)
		if (index !== activeItem.parentIndex) {
			setActiveItem({ ...activeItem, children: exportMenu[index].children[0].children, index: 0, parentIndex: index })
		}
	}

	function handleMouseOut() {
		setShowDropdownIndex(null)
	}

	async function getBalance() {
		if (localStorage.getItem('role') === 'ROLE_OWNER') {
			const response = await GET('/services/web/api/pos-balance')
			setBalance(response)
		}
	}

	function getMenu() {
		if (reduxAccount?.authorities) {
			var authorities = JSON.parse(JSON.stringify(reduxAccount?.authorities))
			var menuCopy = [...exportMenu]
			if (role === 'ROLE_ORGANIZATION') {
				menuCopy = exportMenuOrganization;
			}
			for (let k = 0; k < menuCopy.length; k++) {
				var grandParent = menuCopy[k]
				for (let i = 0; i < grandParent?.children?.length; i++) {
					var parent = grandParent?.children[i];
					for (let j = 0; j < parent?.children?.length; j++) {
						var child = parent?.children[j]
						for (let k = 0; k < authorities?.length; k++) {
							if (child['role'] === authorities[k]) {
								child['show'] = true
								parent['show'] = true
							}
						}
					}

					if (parent['show']) {
						grandParent['show'] = true
					}
				}
			}
			var activeMenu = { children: [] };
			// var activeMenuParent = { children: [] };
			for (let i = 0; i < menuCopy.reverse().length; i++) {
				if (menuCopy[i].show) {
					activeMenu = menuCopy[i]
					// activeMenuParent = menuCopy[i].children[0]
				}
			}
			setActiveItem(activeMenu)

			setMenu([...menuCopy])

			setActiveMenu(menuCopy)
		}
	}

	useEffect(() => {
		if (localStorage.getItem('role') !== 'ROLE_ORGANIZATION') {
			getBalance()
		}
		getMenu();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<header id="page-topbar">
				<div className="navbar-header">
					<div className="d-flex">
						<div className="navbar-brand-box">
							<div className="logo logo-dark text-center">
								<Link to='/dashboard'>
									<img src={logo} alt="logo" width="40" />
								</Link>
							</div>
						</div>

						{exportMenu.map((item, index) => (
							item?.show &&
							<div className={"custom-dropdown ms-3 " + (showDropdownIndex === index ? 'active ' : '')} key={index}
								onMouseOver={() => handleMouseOverParent(index)} onMouseOut={() => handleMouseOut()}>
								<div className="custom-dropdown-title">
									{t(item.title)}
									<img src={arrow_down} alt="" className="custom-dropdown-icon" />
								</div>
								<div className="custom-dropdown-items-back">
									<div className="custom-dropdown-items" >
										<div className="custom-dropdown-header">
											{item?.children?.map((item2, index2) => (
												item2?.show &&
												<div onClick={() => setActiveItem({ ...activeItem, children: item2.children, index: index2, parentIndex: index })} key={index2}>
													<div className={"custom-dropdown-header-title " + (activeItem.index === index2 ? 'active' : '')}>
														{t(item2.title)}
													</div>
												</div>
											))
											}
										</div>
										<div className="d-flex">
											<div className="w-100">
												{activeItem?.children?.slice(0, 10)?.map((item, index) => (
													item?.show &&
													<Link to={item.url} key={index} onClick={() => setShowDropdownIndex(null)}>
														<div className="custom-dropdown-item">
															<i className={'me-3 ' + item.icon}></i>
															{t(item.i18n)}
														</div>
													</Link>
												))
												}
											</div>
											{activeItem?.children?.slice(10, 18)?.length > 0 &&
												<div className="w-100">
													{activeItem?.children?.slice(10, 18)?.map((item, index) => (
														item?.show &&
														<Link to={item.url} key={index} onClick={() => setShowDropdownIndex(null)}>
															<div className="custom-dropdown-item">
																<i className={'me-3 ' + item.icon}></i>
																{t(item.i18n)}
															</div>
														</Link>
													))
													}
												</div>
											}
										</div>
									</div>

								</div>
							</div>
						))
						}
					</div>

					<div className="d-flex">

						<LanguageDropdown />
						<div className="d-lg-inline-block ms-1 dropdown">
							<button type="button" className="btn header-item noti-icon waves-effect">

								<div className="vertical-center">
									<Dropdown className="profile-dropdown">
										<Dropdown.Toggle as={Link} to="#!" variant="success" id="dropdown-basic" className="profile-dropdown-toggle">
											<i className="uil uil-user" color="#fff"></i>
										</Dropdown.Toggle>
										<Dropdown.Menu className="profile-dropdown-menu">

											<Dropdown.ItemText href="" className="profile-dropdown-item">
												<div className="d-flex justify-content-center">
													<span className="me-1">{t('LOGIN')}:</span>
													<span className="fw-500">{JSON.parse(localStorage.getItem('authUser'))?.username}</span>
												</div>
											</Dropdown.ItemText>
											<Dropdown.ItemText href="" className="profile-dropdown-item">
												<div className="d-flex justify-content-center">
													<span className="me-1">{t('BALANCE')}:</span>
													<span className="fw-500">{balance}</span>
												</div>
											</Dropdown.ItemText>
											<Dropdown.Item href="#" className="profile-dropdown-item" onClick={() => setModalLogout(true)}>
												<i className="uil-signin me-2 fz-16" />
												{t('LOGOUT')}
											</Dropdown.Item>
										</Dropdown.Menu>
									</Dropdown>


								</div>
							</button>
						</div>

					</div>
				</div>
			</header>

			<Modal show={modalLogout} animation={false}
				centered dialogClassName="update-modal-width" backdrop="static" onHide={() => setModalLogout(false)}>
				<Modal.Header closeButton>
					<Modal.Title>{t('LOGOUT')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{t('ARE_YOU_SURE_YOU_WANT_LOGOUT')}
				</Modal.Body>
				<Modal.Footer >
					<div className="d-flex w-100">
						<button className="btn btn-warning w-100 me-2" onClick={() => setModalLogout(false)}>
							{t('CANCEL')}
						</button>
						<button className="btn btn-primary w-100" onClick={() => logout()}>{t('CONFIRM')}</button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Header
