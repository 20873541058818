import React from 'react'
import { DebounceInput } from 'react-debounce-input'
import { useTranslation } from 'react-i18next'

import { PGET } from 'helpers/api'

function Search({ url, search, getData }) {
	const { t } = useTranslation()

	async function requestToServer(value) {
		var data;
		var symbol = '?'
		url.includes("?") ? symbol = '&' : symbol = '?'

		if (value.length === 0) {
			getData()
		}

		if (value.length > 1) {
			data = await PGET(url + symbol + "search=" + value)
			search(data.data, value, data.headers['x-total-count'])
		}
	};

	return (
		<div className="position-relative me-2">
			<i className="uil-search input-search-icon"></i>
			<DebounceInput
				type="text"
				className="form-control"
				placeholder={t('SEARCH') + '...'}
				minLength={3}
				debounceTimeout={1000}
				onChange={(e) => requestToServer(e.target.value)}
				onKeyUp={(e) => {
					if (e.keyCode === 13) {
						requestToServer(e.target.value)
					}
				}}
			/>
		</div>
	)
}

export default Search
