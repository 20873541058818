const initialState = {
	'documentsIn': {},
	'nomenclature': {},
	'revaluation': {},
	'history': {},
	'documentsOut': {},
	'writeOff': {},
	'inventory': {},
	'transfer': {},
	'posTransfer': {},
	'orders': {},
	'grouping': {},
	'scale': {},
	'package': {},
	'cheques': {},
	'priceTags': {},
	'notifications': {},
	'abc': {},
	'oos': {},
}

const filterData = function (state = initialState, action) {
	switch (action.type) {
		case `SET_FILTER_DATA`:
			state[action.payload.key] = action.payload.value
			return { ...state };
		default:
			return state;
	}
};

export default filterData