import React from 'react'
import Barcode from 'react-barcode'
//import { useSelector } from 'react-redux'
import { todayDate, formatMoney } from 'helpers/helpers'

function PriceTag30x20({
	data,
	selectedPriceTag,
	selectPriceTag,
	printProducts,
	printRef,
	activateWholeSalePrice,
	cashbox,
	a4 = false,
	activeDate = true,
}) {

	function calculateBarcodeWidth(barcode) {
		if (barcode.length >= 1 && barcode.length <= 6) {
			return 1.7
		}
		if (barcode.length >= 7 && barcode.length <= 11) {
			return 1.4
		}
		if (barcode.length >= 12) {
			return 1.04
		}
	}

	return (
		<>
			<div className="d-flex flex-wrap">
				<div className={"price_tag_body_30x20 price-tag-border mb-3 me-3 " + (selectedPriceTag.priceTag30x20_1 && 'border-primary')}
					onClick={() => selectPriceTag('priceTag30x20_1')}>
					<div className="vertical-center">
						<div className="price_tag_30x20_1_name">
							{data.productName}
						</div>
					</div>
					<div className="d-flex justify-content-center price_tag_30x20_1_price">
						<div className={'d-flex ' + (formatMoney(data.salePrice, 0).length > 7 ? "fz18" : "fz22")}>
							<div className="vertical-center me-1">
								<span className="fw-700 text-nowrap">
									{formatMoney(data.salePrice, 0)}
								</span>
							</div>
						</div>
						<div className={'vertical-center ' + (formatMoney(data.salePrice, 0).length > 7 ? "fz18" : "fz22")}>
							<b>{cashbox.defaultCurrency}</b>
						</div>
					</div>
					<div className="d-flex justify-content-center barcode">
						<div>
							<Barcode value={data.barcode} height={20} background="transparent" textMargin={0}
								fontOptions="bold" fontSize={10} width={1.04} />
						</div>
					</div>
					<div className="fz6 date">{todayDate()}</div>
				</div>

				<div className={"price_tag_body_30x20 price-tag-border mb-3 me-3 " + (selectedPriceTag.priceTag30x20_2 && 'border-primary')}
					onClick={() => selectPriceTag('priceTag30x20_2')}>
					<div className="vertical-center">
						<div className="price_tag_30x20_2_name">
							{data.productName}
						</div>
					</div>
					<div className="d-flex justify-content-center price_tag_30x20_2_price">
						<div className={'d-flex ' + (formatMoney(data.salePrice, 0).length > 7 ? "fz18" : "fz22")}>
							<div className="vertical-center me-1">
								<span className="fw-700 text-nowrap">{formatMoney(data.salePrice, 0)}</span>
							</div>
						</div>
						<div className={'vertical-center ' + (formatMoney(data.salePrice, 0).length > 7 ? "fz18" : "fz22")}>
							<b>{cashbox.defaultCurrency}</b>
						</div>
					</div>
					<div className="fz6 date">{todayDate()}</div>
				</div>

				<div className={"price_tag_body_30x20 price-tag-border mb-3 me-3 " + (selectedPriceTag.priceTag30x20_3 && 'border-primary')}
					onClick={() => selectPriceTag('priceTag30x20_3')}>
					<div className="vertical-center">
						<div className="price_tag_30x20_3_name">
							{data.productName}
						</div>
					</div>
					<div className="d-flex justify-content-center price_tag_30x20_3_price">
						<div className={'d-flex ' + (formatMoney(data.salePrice, 0).length > 7 ? "fz18" : "fz22")}>
							<div className="vertical-center me-1">
								<span className="fw-700 text-nowrap">{formatMoney(data.salePrice, 0)}</span>
							</div>
						</div>
						<div className={'vertical-center ' + (formatMoney(data.salePrice, 0).length > 7 ? "fz18" : "fz22")}>
							<b>{cashbox.defaultCurrency}</b>
						</div>
					</div>
					<div className="d-flex justify-content-center barcode">
						<Barcode value={data.barcode} width={1.04} height={20} background="transparent" displayValue={false} />
					</div>
				</div>

				<div className={"price_tag_body_30x20 price-tag-border mb-3 me-3 " + (selectedPriceTag.priceTag30x20_4 && 'border-primary')}
					onClick={() => selectPriceTag('priceTag30x20_4')}>
					<div className="vertical-center">
						<div className="price_tag_30x20_3_name">
							{data.productName}
						</div>
					</div>
					<div className="d-flex justify-content-center barcode">
						<div>
							<Barcode value={data.barcode} height={20} background="transparent" textMargin={0}
								fontOptions="bold" fontSize={10} width={1.04} />
						</div>
					</div>
					<div className="fz6 date">{todayDate()}</div>
				</div>
			</div>

			<div className="d-none">
				{selectedPriceTag.priceTag30x20_1 &&
					<div ref={printRef} className={"price-tag-wrap " + (a4 ? 'a4 ' : ' ') + (activeDate ? ' ' : 'hide-date ')}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_30x20 page-breaker" key={index}>
								<div className="vertical-center">
									<div className="price_tag_30x20_1_name">
										{product.productName}
									</div>
								</div>
								<div className="d-flex justify-content-center price_tag_30x20_1_price">
									<div className={'d-flex ' + (formatMoney(product.salePrice, 0).length > 7 ? "fz18" : "fz22")}>
										<div className="vertical-center me-1">
											<span className="fw-700 text-nowrap">{formatMoney(product.salePrice, 0)}</span>
										</div>
									</div>
									<div className={'vertical-center ' + (formatMoney(product.salePrice, 0).length > 7 ? "fz18" : "fz22")}>
										<b>{cashbox.defaultCurrency}</b>
									</div>
								</div>
								<div className="d-flex justify-content-center barcode">
									<div>
										<Barcode value={product.barcode} width={calculateBarcodeWidth(product.barcode)}
											height={20} background="transparent" textMargin={0} fontOptions="bold" fontSize={10} />
									</div>
								</div>
								<div className="fz6 date">{todayDate()}</div>
							</div>
						))
						}
					</div>
				}

				{selectedPriceTag.priceTag30x20_2 &&
					<div ref={printRef} className={"price-tag-wrap " + (a4 ? 'a4 ' : ' ') + (activeDate ? ' ' : 'hide-date ')}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_30x20 page-breaker" key={index}>
								<div className="vertical-center">
									<div className="price_tag_30x20_2_name">
										{product.productName}
									</div>
								</div>
								<div className="d-flex justify-content-center price_tag_30x20_2_price">
									<div className={'d-flex ' + (formatMoney(product.salePrice, 0).length > 7 ? "fz18" : "fz22")}>
										<div className="vertical-center me-1">
											<span className="fw-700 text-nowrap">{formatMoney(product.salePrice, 0)}</span>
										</div>
									</div>
									<div className={'vertical-center ' + (formatMoney(product.salePrice, 0).length > 7 ? "fz18" : "fz22")}>
										<b>{cashbox.defaultCurrency}</b>
									</div>
								</div>
								<div className="fz6 date">{todayDate()}</div>
							</div>
						))
						}
					</div>
				}

				{selectedPriceTag.priceTag30x20_3 &&
					<div ref={printRef} className={"price-tag-wrap " + (a4 ? 'a4 ' : ' ') + (activeDate ? ' ' : 'hide-date ')}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_30x20 page-breaker" key={index}>
								<div className="vertical-center">
									<div className="price_tag_30x20_3_name">
										{product.productName}
									</div>
								</div>
								<div className="d-flex justify-content-center price_tag_30x20_3_price">
									<div className={'d-flex ' + (formatMoney(product.salePrice, 0).length > 7 ? "fz18" : "fz22")}>
										<div className="vertical-center me-1">
											<span className="fw-700 text-nowrap">{formatMoney(product.salePrice, 0)}</span>
										</div>
									</div>
									<div className={'vertical-center ' + (formatMoney(product.salePrice, 0).length > 7 ? "fz18" : "fz22")}>
										<b>{cashbox.defaultCurrency}</b>
									</div>
								</div>
								<div className="d-flex justify-content-center barcode">
									<Barcode value={product.barcode} width={1.04} height={20} background="transparent" displayValue={false} />
								</div>
							</div>
						))
						}
					</div>
				}

				{selectedPriceTag.priceTag30x20_4 &&
					<div ref={printRef} className={"price-tag-wrap " + (a4 ? 'a4 ' : ' ') + + (activeDate ? ' ' : 'hide-date ')}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_30x20 page-breaker" key={index}>
								<div className="vertical-center">
									<div className="price_tag_30x20_3_name">
										{product.productName}
									</div>
								</div>
								<div className="d-flex justify-content-center barcode">
									<div>
										<Barcode value={product.barcode} height={20} background="transparent" textMargin={0}
											fontOptions="bold" fontSize={10} width={1.04} />
									</div>
								</div>
								<div className="fz6 date">{todayDate()}</div>
							</div>
						))
						}
					</div>
				}

			</div>
		</>
	)
}

export default PriceTag30x20