import React, { useEffect, useRef, useState } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import { Nav } from 'react-bootstrap'

import { store } from 'store/index'
import { showSidebar } from 'store/layout/actions'
//Import Scrollbar
//import useDidMountEffect from 'pages/parts/useDidMountEffect'

import { exportMenu } from './menu/menu'
import { exportMenuAdmin } from './menu/menuAdmin'
import { exportMenuAdminReport } from './menu/menuAdminReport'
import { exportMenuMerchendiser } from './menu/menuMerchendiser'

import organizationMenu from './menu/organizationMenu'

import logo from "assets/images/logo.svg";

function SidebarContent() {
	const { t } = useTranslation()
	const location = useLocation()
	const ref = useRef();
	const layoutSelector = useSelector(state => state.Layout)

	const [menu, setMenu] = useState(getMenu(localStorage.getItem('role')))
	const [activeItem, setActiveItem] = useState({
		children: []
	})

	function setActiveMenu() {
		var menuCopy = [...menu]
		for (let k = 0; k < menuCopy.length; k++) {
			for (let i = 0; i < menuCopy[k]['children'].length; i++) {
				if (location.pathname.includes(menuCopy[k]['children'][i]['url'])) {
					menuCopy[k]['children'][i]['active'] = true;
					setActiveItem(menuCopy[k])
				} else {
					menuCopy[k]['children'][i]['active'] = false;
				}
			}
		}
		setMenu(menuCopy);
	}

	function setActiveTab(item) {
		if (layoutSelector.showSidebar) {
			store.dispatch(showSidebar())
		} else {
			store.dispatch(showSidebar());
		}

		var copy = { ...item }
		setActiveItem(copy)
	}

	function setActiveChild(item, index) {
		store.dispatch(showSidebar())
		var copyArr = [...menu]
		const i = copyArr.findIndex(x => x.icon === activeItem.icon);
		copyArr[i]['children'][index].active = !copyArr[i]['children'][index].active
		setActiveItem(copyArr[i])
	}

	function getMenu(role) {
		if (role === 'ROLE_OWNER') {
			return exportMenu
		}
		if (role === 'ROLE_MERCHANDISER') {
			return exportMenuMerchendiser
		}
		if (role === 'ROLE_ADMIN') {
			return exportMenuAdmin
		}
		if (role === 'ROLE_REPORT') {
			return exportMenuAdminReport
		}
		if (role === 'ROLE_ORGANIZATION') {
			return organizationMenu
		}
		return exportMenu
	}

	useEffect(() => {
		setActiveMenu()
	}, [location]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div style={{ maxHeight: "100%" }} ref={ref} className={"mt-1 " + (layoutSelector.showSidebar ? 'box-shadow-none' : '')}>
				<Nav className="nav-mini" activeKey={activeItem.icon}>
					{menu?.map((item, index) => (
						<Nav.Item onClick={() => setActiveTab(item)} key={index}>
							<Nav.Link eventKey={index} className={item.id === activeItem.id ? 'active' : ''}>
								<span className="fw-bold"><i className={item.icon}></i></span>
							</Nav.Link>
						</Nav.Item>
					))}
				</Nav>
			</div>

			<div className={"second-sidebar " + (layoutSelector.showSidebar ? 'd-none' : '')}>
				<Link to='/dashboard'>
					<div className="second-sidebar-logo">
						<img src={logo} alt="" width={120} />
					</div>
				</Link>

				<div id="sidebar-menu">
					<ul className="metismenu list-unstyled" id="side-menu">
						{activeItem?.children?.map((item, index) => (
							<li className={item.active ? "mm-active" : ""} key={index}>
								{item.title == null ?
									<NavLink to={item.url}
										onClick={() => setActiveChild(item, index)} className={"waves-effect " + (item.active ? "active" : "")}>
										<i className={"second-sidebar-icon " + item.icon}></i>
										<div className="second-sidebar-text">{t(item.i18n)}</div>
									</NavLink>
									:
									<div className="second-sidebar-title">
										<span>{t(item.i18n)}</span>
									</div>
								}
							</li>
						))}
					</ul>
				</div>
			</div>
		</>
	)
}

export default SidebarContent