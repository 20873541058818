import React from 'react'
import { Outlet } from 'react-router-dom';
import { useSelector } from "react-redux";

import Header from "./Header";
import Sidebar from "./Sidebar";

function Layout() {
	const layoutSelector = useSelector(state => state.Layout)

	return (
		<>
			<div className={layoutSelector.showSidebar ? "vertical-collpsed sidebar-enable" : ""} id="layout-wrapper">
				<Header />
				<Sidebar />
				<div className="main-content">
					<div className="page-content">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
}

export default Layout
