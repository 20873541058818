import React from "react"

import Update from "../components/Update"

// Dashboard
import DashboardIndex from "../pages/dashboard/Index"
import Welcome from "pages/dashboard/Welcome"

// Authentication related pages
import Login from "../pages/authentication/Login"
import ForgotPassword from "pages/authentication/ForgotPassword"
import Cheque from "pages/authentication/Cheque"

import ChequesIndex from "../pages/pos/cheques/Index"
import DebitCreditIndex from "../pages/pos/debitCredit/Index"

import Wallet from "../pages/pos/wallet/Index"

import Kpi from "../pages/pos/kpi/Index"

import DocumentsInIndex from "../pages/pos/documentsIn/Index"
import DocumentsInCreateUpdate from "../pages/pos/documentsIn/CreateUpdate"
import DocumentsInPreview from "../pages/pos/documentsIn/Preview"

import DocumentsInPackageIndex from "../pages/pencil/documentsIn/Index"
import DocumentsInPackageCreateUpdate from "../pages/pencil/documentsIn/CreateUpdate"
import DocumentsInPackagePreview from "../pages/pencil/documentsIn/Preview"

import DocumentsOutIndex from "../pages/pos/documentsOut/Index"
import DocumentsOutCreateUpdate from "../pages/pos/documentsOut/CreateUpdate"
import DocumentsPreview from "../pages/pos/documentsOut/Preview"

import WriteOffIndex from "../pages/pos/writeOff/Index"
import WriteOffCreateUpdate from "../pages/pos/writeOff/CreateUpdate"
import WriteOffPreview from "../pages/pos/writeOff/Preview"

import GroupingIndex from "../pages/pos/grouping/Index"
import GroupingCreateUpdate from "../pages/pos/grouping/CreateUpdate"

import HistoryIndex from "../pages/pos/history/Index"

import InventoryIndex from "../pages/pos/inventory/Index"
import InventoryCreateUpdate from "../pages/pos/inventory/CreateUpdate"
import InventorySocketCreateUpdate from "../pages/pos/inventory/SocketCreateUpdate"
import InventoryPreview from "../pages/pos/inventory/Preview"

import NomenclatureIndex from "../pages/pos/nomenclature/Index"

import RevaluationIndex from "../pages/pos/revaluation/Index"

import NotificationsIndex from "../pages/pos/notifications/Index"

import PriceTagsIndex from "../pages/pos/priceTags/Index"
import ReportsIndex from "../pages/pos/reports/Index"

import OrganizationBalance from "../pages/organization/Balance"

import ScaleIndex from "../pages/pos/scale/Index"
import ScaleCreateUpdate from "../pages/pos/scale/CreateUpdate"

import TransferIndex from "../pages/pos/transfer/Index"
import TransferCreateUpdate from "../pages/pos/transfer/CreateUpdate"
import TransferPreview from "../pages/pos/transfer/Preview"

import TransferPosIndex from "../pages/pos/transferPos/Index"
import TransferPosCreateUpdate from "../pages/pos/transferPos/posToWms/CreateUpdate"
import TransferPosPreview from "../pages/pos/transferPos/posToWms/Preview"
import TransferPosToWmsPreview from "../pages/pos/transferPos/wmsToPos/Preview"

import OrderIndex from "../pages/pos/orders/Index"
import OrderCreateUpdate from "../pages/pos/orders/CreateUpdate"
import OrderPreview from "../pages/pos/orders/Preview"

import PackageIndex from '../pages/pos/package/Index'
import PackageCreateUpdate from '../pages/pos/package/CreateUpdate'

import PromotionIndex from '../pages/pos/promotion/Index'

import SalaryIndex from "../pages/pos/salary/Index"

import ReportBalance from '../pages/pos/reports/Balance'
import ReportIncomes from '../pages/pos/reports/Incomes'
import ReportWriteOff from '../pages/pos/reports/WriteOff'
import ReportSales from '../pages/pos/reports/Sales'
import ReportExpenses from '../pages/pos/reports/Expenses'
import ReportExpirationDate from '../pages/pos/reports/ExpirationDate'
import ReportReturnedItems from '../pages/pos/reports/ReturnedItems'
import ReportGrossIncome from '../pages/pos/reports/GrossIncome'
import ReportCategoryManagement from '../pages/pos/reports/CategoryManagement'
import ReportOrganizations from '../pages/pos/reports/Organizations'
import ReportOrganizationsInner from '../pages/pos/reports/OrganizationsInner'
import ReportClients from '../pages/pos/reports/Clients'
import ReportClientsInnerDebts from '../pages/pos/reports/ClientsInnerDebts'
import ReportClientsInnerCheques from '../pages/pos/reports/ClientsInnerCheques'
import ReportSummaryKPI from '../pages/pos/reports/SummaryKPI'
import ReportSummary from '../pages/pos/reports/Summary'
import ReportCashiers from '../pages/pos/reports/Cashiers'

import ReportFrozenFunds from '../pages/pos/analytics/FrozenFunds'
import ReportRecommendedOrders from '../pages/pos/analytics/RecommendedOrders'
import Abc from "pages/pos/analytics/Abc"
import Marginality from "pages/pos/analytics/Marginality"
import Oos from "pages/pos/analytics/Oos"
import ProductAction from "pages/pos/analytics/ProductAction"
import ProductUnsold from "pages/pos/analytics/ProductUnsold"

// import TransferWmsIndex from "../pages/pos/transfer/Index"
// import TransferWmsCreateUpdate from "../pages/pos/transfer/CreateUpdate"
// import TransferWmsPreview from "../pages/pos/transfer/Preview"

// WMS

import WmsOrderIndex from "../pages/wms/orders/Index"
import WmsOrderPreview from "../pages/wms/orders/Preview"

import WmsDashboardIndex from "../pages/wms/dashboard/Index"

import WmsChequesIndex from "../pages/wms/cheques/Index"
import WmsDebitCreditIndex from "../pages/wms/debitCredit/Index"

import WmsDocumentsInIndex from "../pages/wms/documentsIn/Index"
import WmsDocumentsInCreateUpdate from "../pages/wms/documentsIn/CreateUpdate"
import WmsDocumentsInPreview from "../pages/wms/documentsIn/Preview"

import WmsDocumentsOutIndex from "../pages/wms/documentsOut/Index"
import WmsDocumentsOutCreateUpdate from "../pages/wms/documentsOut/CreateUpdate"
import WmsDocumentsPreview from "../pages/wms/documentsOut/Preview"

import WmsWriteOffIndex from "../pages/wms/writeOff/Index"
import WmsWriteOffCreateUpdate from "../pages/wms/writeOff/CreateUpdate"
import WmsWriteOffPreview from "../pages/wms/writeOff/Preview"

import WmsGroupingIndex from "../pages/wms/grouping/Index"
import WmsGroupingCreateUpdate from "../pages/wms/grouping/CreateUpdate"

import WmsInventoryIndex from "../pages/wms/inventory/Index"
import WmsInventoryCreateUpdate from "../pages/wms/inventory/CreateUpdate"
import WmsInventorySocketCreateUpdate from "../pages/wms/inventory/SocketCreateUpdate"
import WmsInventoryPreview from "../pages/wms/inventory/Preview"

import WmsRevaluationIndex from "../pages/wms/revaluation/Index"

import WmsNotificationsIndex from "../pages/wms/notifications/Index"

import WmsPriceTagsIndex from "../pages/wms/priceTags/Index"
import WmsReportsIndex from "../pages/wms/reports/Index"

import WmsScaleIndex from "../pages/wms/scale/Index"
import WmsScaleCreateUpdate from "../pages/wms/scale/CreateUpdate"

import WmsTransferIndex from "../pages/wms/transfer/Index"
import WmsTransferCreateUpdate from "../pages/wms/transfer/CreateUpdate"
import WmsTransferPreview from "../pages/wms/transfer/Preview"

import WmsTransferWmsIndex from "../pages/wms/transferWms/Index"
import WmsTransferWmsCreateUpdate from "../pages/wms/transferWms/wmsToPos/CreateUpdate"
import WmsTransferWmsPreview from "../pages/wms/transferWms/posToWms/Preview"
import WmsTransferPosPreview from "../pages/wms/transferWms/wmsToPos/Preview"

import WmsCashboxIndex from "../pages/wms/cashbox/Index"
import WmsShiftIndex from "../pages/wms/cashbox/Shift"

import WmsWorkersIndex from "../pages/wms/myWorkers/Index"

// GENERAL
import Settings from "../pages/general/settings/Index"

import PosIndex from "../pages/general/poses/Index"
import PosCreateUpdate from "../pages/general/poses/CreateUpdate"

import WmsIndex from "../pages/general/wms/Index"
import WmsCreateUpdate from "../pages/general/wms/CreateUpdate"

import CashboxIndex from "../pages/general/cashbox/Index"
import ShiftIndex from "../pages/general/cashbox/Shift"

import WorkersIndex from "../pages/general/myWorkers/Index"
import WorkersCreateUpdate from "../pages/general/myWorkers/CreateUpdate"

import SuppliersIndex from "../pages/general/suppliers/Index"

import CurrencyIndex from "../pages/general/currency/Index"

import ClientsIndex from "../pages/general/clients/Index"
import MyOrganizationUpdate from "../pages/general/myOrganization/CreateUpdate"

import ProductsIndex from "../pages/general/products/Index"
import ProductsCreateUpdate from "../pages/general/products/CreateUpdate"

import ExpenseIndex from "../pages/general/expense/Index"

import AdminPosIndex from "../pages/admin/poses/Index"
import AdminPosCreateUpdate from "../pages/admin/poses/CreateUpdate"
import AdminProductsIndex from "../pages/admin/products/Index"
import AdminProductsCreateUpdate from "../pages/admin/products/CreateUpdate"

import AdminClientsIndex from "../pages/admin/clients/Index"

import AdminReportsIndex from "../pages/adminReport/Index"

const publicRoutes = [
	{ path: "/auth/login", component: <Login /> },
	{ path: "/auth/forgot-password", component: <ForgotPassword /> },
	{ path: "/cheque/:shiftId/:chequeNumber", component: <Cheque /> },
]

const privateRoutes = [
	{ path: "/dashboard", component: <DashboardIndex /> },
	{ path: "/welcome", component: <Welcome /> },

	{ path: "/cheques", component: <ChequesIndex /> },

	{ path: "/wallet", component: <Wallet /> },

	{ path: "/kpi", component: <Kpi /> },

	{ path: "/debit-credit", component: <DebitCreditIndex /> },

	{ path: "/documents-in", component: <DocumentsInIndex /> },
	{ path: "/documents-in/create", component: <DocumentsInCreateUpdate /> },
	{ path: "/documents-in/update/:id", component: <DocumentsInCreateUpdate /> },
	{ path: "/documents-in/preview/:id", component: <DocumentsInPreview /> },

	{ path: "/documents-in-package", component: <DocumentsInPackageIndex /> },
	{ path: "/documents-in-package/create", component: <DocumentsInPackageCreateUpdate /> },
	{ path: "/documents-in-package/update/:id", component: <DocumentsInPackageCreateUpdate /> },
	{ path: "/documents-in-package/preview/:id", component: <DocumentsInPackagePreview /> },

	{ path: "/documents-out", component: <DocumentsOutIndex /> },
	{ path: "/documents-out/create", component: <DocumentsOutCreateUpdate /> },
	{ path: "/documents-out/preview/:id", component: <DocumentsPreview /> },

	{ path: "/write-off", component: <WriteOffIndex /> },
	{ path: "/write-off/create", component: <WriteOffCreateUpdate /> },
	{ path: "/write-off/preview/:id", component: <WriteOffPreview /> },

	{ path: "/grouping", component: <GroupingIndex /> },
	{ path: "/grouping/create", component: <GroupingCreateUpdate /> },
	{ path: "/grouping/update/:paramsPosId/:paramsProductGroupId", component: <GroupingCreateUpdate /> },

	{ path: "/history", component: <HistoryIndex /> },

	{ path: "/inventory", component: <InventoryIndex /> },
	{ path: "/inventory/create", component: <InventoryCreateUpdate /> },
	{ path: "/inventory/update/:id", component: <InventoryCreateUpdate /> },
	{ path: "/inventory/create-socket", component: <InventorySocketCreateUpdate /> },
	{ path: "/inventory/update-socket/:id", component: <InventorySocketCreateUpdate /> },
	{
		path: "/inventory/preview/:id", component: <InventoryPreview />
	},

	{ path: "/nomenclature", component: <NomenclatureIndex /> },

	{ path: "/revaluation", component: <RevaluationIndex /> },

	{ path: "/cashbox", component: <CashboxIndex /> },
	{ path: "/cashbox/shift/:id?", component: <ShiftIndex /> },

	{ path: "/notifications", component: <NotificationsIndex /> },

	{ path: "/workers", component: <WorkersIndex /> },
	{ path: "/workers/create", component: <WorkersCreateUpdate /> },
	{ path: "/workers/update/:id", component: <WorkersCreateUpdate /> },

	{ path: "/price-tags", component: <PriceTagsIndex /> },

	{ path: "/reports", component: <ReportsIndex /> },
	{ path: "/reports/organizations/:id", component: <ReportsIndex /> },
	{ path: "/reports/clients/:id", component: <ReportsIndex /> },

	{ path: "/organization-balance", component: <OrganizationBalance /> },

	{ path: "/scale", component: <ScaleIndex /> },
	{ path: "/scale/create", component: <ScaleCreateUpdate /> },

	{ path: "/transfer", component: <TransferIndex /> },
	{ path: "/transfer/create", component: <TransferCreateUpdate /> },
	{ path: "/transfer/update/:id", component: <TransferCreateUpdate /> },
	{ path: "/transfer/preview/:id", component: <TransferPreview /> },

	{ path: "/pos-transfer", component: <TransferPosIndex /> },
	{ path: "/pos-transfer/create", component: <TransferPosCreateUpdate /> },
	{ path: "/pos-transfer/update/:id", component: <TransferPosCreateUpdate /> },
	{ path: "/pos-transfer/preview/:id", component: <TransferPosPreview /> },
	{ path: "/pos-wms-transfer/preview/:id", component: <TransferPosToWmsPreview /> },

	{ path: "/orders", component: <OrderIndex /> },
	{ path: "/orders/create", component: <OrderCreateUpdate /> },
	{ path: "/orders/update/:id", component: <OrderCreateUpdate /> },
	{ path: "/orders/preview/:id", component: <OrderPreview /> },

	{ path: "/package", component: <PackageIndex /> },
	{ path: "/package/create", component: <PackageCreateUpdate /> },
	{ path: "/package/update/:id", component: <PackageCreateUpdate /> },

	{ path: "/promotion", component: <PromotionIndex /> },

	{ path: "/salary", component: <SalaryIndex /> },

	{ path: "/report/balance", component: <ReportBalance /> },
	{ path: "/report/cashiers", component: <ReportCashiers /> },
	{ path: "/report/category-managment", component: <ReportCategoryManagement /> },
	{ path: "/report/clients", component: <ReportClients /> },
	{ path: "/report/clients-inner-cheques", component: <ReportClientsInnerCheques /> },
	{ path: "/report/clients-inner-debts", component: <ReportClientsInnerDebts /> },
	{ path: "/report/expenses", component: <ReportExpenses /> },
	{ path: "/report/expiration-date", component: <ReportExpirationDate /> },
	{ path: "/report/frozen-funds", component: <ReportFrozenFunds /> },
	{ path: "/report/gross-income", component: <ReportGrossIncome /> },
	{ path: "/report/incomes", component: <ReportIncomes /> },
	{ path: "/report/suppliers", component: <ReportOrganizations /> },
	{ path: "/report/suppliers-inner", component: <ReportOrganizationsInner /> },
	{ path: "/report/recommended-orders", component: <ReportRecommendedOrders /> },
	{ path: "/report/return-products", component: <ReportReturnedItems /> },
	{ path: "/report/sales", component: <ReportSales /> },
	{ path: "/report/summary", component: <ReportSummary /> },
	{ path: "/report/summary-kpi", component: <ReportSummaryKPI /> },
	{ path: "/report/write-off", component: <ReportWriteOff /> },

	{ path: "/report/frozen-funds", component: <ReportFrozenFunds /> },
	{ path: "/report/recommended-orders", component: <ReportRecommendedOrders /> },
	{ path: "/report/abc", component: <Abc /> },
	{ path: "/report/marginality", component: <Marginality /> },
	{ path: "/report/oos", component: <Oos /> },
	{ path: "/report/product-action", component: <ProductAction /> },
	{ path: "/report/product-unsold", component: <ProductUnsold /> },

	// WMS
	{ path: "/wms/orders", component: <WmsOrderIndex /> },
	{ path: "/wms/orders/preview/:id", component: <WmsOrderPreview /> },

	{ path: "/wms/dashboard", component: <WmsDashboardIndex /> },

	{ path: "/wms/cheques", component: <WmsChequesIndex /> },

	{ path: "/wms/debit-credit", component: <WmsDebitCreditIndex /> },

	{ path: "/wms/documents-in", component: <WmsDocumentsInIndex /> },
	{ path: "/wms/documents-in/create", component: <WmsDocumentsInCreateUpdate /> },
	{ path: "/wms/documents-in/update/:id", component: <WmsDocumentsInCreateUpdate /> },
	{ path: "/wms/documents-in/preview/:id", component: <WmsDocumentsInPreview /> },

	{ path: "/wms/documents-out", component: <WmsDocumentsOutIndex /> },
	{ path: "/wms/documents-out/create", component: <WmsDocumentsOutCreateUpdate /> },
	{ path: "/wms/documents-out/preview/:id", component: <WmsDocumentsPreview /> },

	{ path: "/wms/write-off", component: <WmsWriteOffIndex /> },
	{ path: "/wms/write-off/create", component: <WmsWriteOffCreateUpdate /> },
	{ path: "/wms/write-off/preview/:id", component: <WmsWriteOffPreview /> },

	{ path: "/wms/grouping", component: <WmsGroupingIndex /> },
	{ path: "/wms/grouping/create", component: <WmsGroupingCreateUpdate /> },
	{ path: "/wms/grouping/update/:paramsWmsId/:paramsProductGroupId", component: <WmsGroupingCreateUpdate /> },

	{ path: "/wms/inventory", component: <WmsInventoryIndex /> },
	{ path: "/wms/inventory/create", component: <WmsInventoryCreateUpdate /> },
	{ path: "/wms/inventory/update/:id", component: <WmsInventoryCreateUpdate /> },
	{ path: "/wms/inventory/create-socket", component: <WmsInventorySocketCreateUpdate /> },
	{ path: "/wms/inventory/update-socket/:id", component: <WmsInventorySocketCreateUpdate /> },
	{
		path: "/wms/inventory/preview/:id", component: <WmsInventoryPreview />
	},

	{ path: "/wms/revaluation", component: <WmsRevaluationIndex /> },

	{ path: "/wms-cashbox", component: <WmsCashboxIndex /> },
	{ path: "/wms-cashbox/shift/:id?", component: <WmsShiftIndex /> },

	{ path: "/wms/notifications", component: <WmsNotificationsIndex /> },

	{ path: "/wms/workers", component: <WmsWorkersIndex /> },

	{ path: "/wms/price-tags", component: <WmsPriceTagsIndex /> },

	{ path: "/wms/reports", component: <WmsReportsIndex /> },
	{ path: "/wms/reports/organizations/:id", component: <WmsReportsIndex /> },
	{ path: "/wms/reports/clients/:id", component: <WmsReportsIndex /> },

	{ path: "/wms/scale", component: <WmsScaleIndex /> },
	{ path: "/wms/scale/create", component: <WmsScaleCreateUpdate /> },

	{ path: "/wms/transfer", component: <WmsTransferIndex /> },
	{ path: "/wms/transfer/create", component: <WmsTransferCreateUpdate /> },
	{ path: "/wms/transfer/update/:id", component: <WmsTransferCreateUpdate /> },
	{ path: "/wms/transfer/preview/:id", component: <WmsTransferPreview /> },

	{ path: "/wms/wms-transfer", component: <WmsTransferWmsIndex /> },
	{ path: "/wms/wms-transfer/create", component: <WmsTransferWmsCreateUpdate /> },
	{ path: "/wms/wms-transfer/update/:id", component: <WmsTransferWmsCreateUpdate /> },
	{ path: "/wms/wms-transfer/preview/:id", component: <WmsTransferWmsPreview /> },
	{ path: "/wms/pos-transfer/preview/:id", component: <WmsTransferPosPreview /> },

	// GENERAL
	{ path: "/settings", component: <Settings /> },

	{ path: "/pos", component: <PosIndex /> },
	{ path: "/pos-create", component: <PosCreateUpdate /> },
	{ path: "/pos-update/:id", component: <PosCreateUpdate /> },

	{ path: "/warehouse", component: <WmsIndex /> },
	{ path: "/warehouse-create", component: <WmsCreateUpdate /> },
	{ path: "/warehouse-update/:id", component: <WmsCreateUpdate /> },

	{ path: "/clients", component: <ClientsIndex /> },

	{ path: "/my-organization", component: <MyOrganizationUpdate /> },

	{ path: "/suppliers", component: <SuppliersIndex /> },

	{ path: "/currency", component: <CurrencyIndex /> },

	{ path: "/products", component: <ProductsIndex /> },
	{ path: "/products-create", component: <ProductsCreateUpdate /> },
	{ path: "/products-update/:id", component: <ProductsCreateUpdate /> },

	{ path: "/expense", component: <ExpenseIndex /> },

	{ path: "/update/:id", component: <Update /> },
]

const adminPrivateRoutes = [
	// ADMIN
	{ path: "/admin/pos", component: <AdminPosIndex /> },
	{ path: "/admin/pos-create/:ownerLogin", component: <AdminPosCreateUpdate /> },
	{ path: "/admin/pos-update/:id", component: <AdminPosCreateUpdate /> },

	{ path: "/admin/clients", component: <AdminClientsIndex /> },

	{ path: "/admin/products", component: <AdminProductsIndex /> },
	{ path: "/admin/products-create", component: <AdminProductsCreateUpdate /> },
	{ path: "/admin/products-update/:id", component: <AdminProductsCreateUpdate /> },
	// ADMIN
]

const adminReportPrivateRoutes = [
	// ADMIN
	{ path: "/aadmin/reports", component: <AdminReportsIndex /> },
	// ADMIN
]

export { publicRoutes, privateRoutes, adminPrivateRoutes, adminReportPrivateRoutes }