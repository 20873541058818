import { combineReducers } from "redux"

import account from "./account/reducer"
import Layout from "./layout/reducer"
import loader from "./loader/reducer"
import skeleton from "./skeleton/reducer"
import settings from "./settings/reducer"
import transferData from "./transferData/reducer"
import report from "./report/reducer"
import tableFilter from "./tableFilter/reducer"
import filterData from "./filterData/reducer"


const allReducers = combineReducers({
	account,
	Layout,
	loader,
	skeleton,
	settings,
	transferData,
	report,
	tableFilter,
	filterData,
})

export const rootReducer = (state, action) => {
	// when a logout action is dispatched it will reset redux state
	if (action.type === 'USER_LOGGED_OUT') {
		state = undefined;
	}

	return allReducers(state, action);
};

export default rootReducer
