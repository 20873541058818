import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App"
import { Provider } from "react-redux"
import { throttle } from 'lodash';

import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';

import store from "./store"
import { saveState } from './store/localStorage'

store.subscribe(throttle(() => {
	saveState(store.getState())
}, 1000));

registerLocale('ru', ru)
setDefaultLocale('ru', ru)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
		<App />
	</Provider>
);