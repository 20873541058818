import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import TableControl from 'pages/parts/TableControl'

import PosToWms from './wmsToPos/Index'
import WmsToPos from './posToWms/Index'
import { SET_TRANSFER_WMS } from "store/transferData/actions"

function Index() {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const reduxTransferData = useSelector(state => state.transferData)

	const [modalTableControl, setModalTableControl] = useState(false)
	const [activeTab, setActiveTab] = useState(1)

	function changeActiveTab(tab) {
		dispatch(SET_TRANSFER_WMS({ activeTab: tab }))
		setActiveTab(tab)
	}

	useEffect(() => {
		if (reduxTransferData?.transferWms?.activeTab) {
			setActiveTab(reduxTransferData?.transferWms.activeTab)
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mt-2">
				<div className="nav nav-tabs w-100">
					<div className={"nav-link cursor fz-20 " + (activeTab === 1 ? 'active' : '')} onClick={() => changeActiveTab(1)}>
						{t('WAREHOUSE') + ' > ' + t('POS')}

					</div>
					<div className={"nav-link cursor fz-20 " + (activeTab === 2 ? 'active' : '')} onClick={() => changeActiveTab(2)}>
						{t('POS') + ' > ' + t('WAREHOUSE')}
					</div>
				</div>
			</div>

			<div>
				{
					activeTab === 1 ?
						<PosToWms></PosToWms>
						:
						<WmsToPos></WmsToPos>
				}
			</div>

			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableNomenclature'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Index
