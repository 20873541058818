import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import FilterSearch from "pages/parts/FilterSearch"
import { Dropdown } from "react-bootstrap"
import Pagination from 'pages/parts/Pagination'
import TableControl from 'pages/parts/TableControl'
import DatePicker from "react-datepicker"
import Select from 'react-select'

import { DOWNLOAD_FILE, PGET } from 'helpers/api'
import { formatMoney, formatDateWithTime, formatDateBackend, formatDate, findFromArrayById } from 'helpers/helpers'
import useDidMountEffect from "pages/parts/useDidMountEffect"

function Index() {
	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)

	const URL = "/services/wms/api/order-pos-pageList"
	const date = new Date()

	const deliveryStatuses = [{ 'id': null, 'name': t('ALL') }, { 'id': 1, 'name': t('NOT_DELIVERED') }, { 'id': 2, 'name': t('DELIVERED') }]

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [modalTableControl, setModalTableControl] = useState(false)

	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])

	const [filterData, setFilterData] = useState({
		search: '',
		startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		endDate: formatDateBackend(new Date()),
		deliveryStatus: null,
		page: 0,
		random: 0,
	})

	async function exportToExcel() {
		await DOWNLOAD_FILE(`/services/wms/api/order-pos-list-excel`, t('ORDERS'), filterData)
	}

	async function exportToExcelDetail(item) {
		await DOWNLOAD_FILE(`/services/wms/api/order-pos-excel/${item.id}`, t('ORDERS') + `_${item.id}`)
	}

	function resetFilter() {
		setFilterData({
			...filterData,
			search: '',
			startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
			endDate: formatDateBackend(new Date()),
			deliveryStatus: null,
		})
		setFilterDataExist(false)
		getData()
	}

	function checkFilter() {
		if (
			filterData.search === '' &&
			filterData.deliveryStatus === null
		) {
			setFilterDataExist(false)
		} else {
			setFilterDataExist(true)
		}
	}

	async function getData() {
		checkFilter()
		const response = await PGET(URL, filterData, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
			setData(response.data)
			setDropdownIsOpen(false)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL, { page: data.selected, size: reduxSettings.size })
		setData(response.data);
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		getData()
	}, [filterData.random]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-heade mb-2">
				<h4 className="vertical-center">
					{t('ORDERS')}
				</h4>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div>
							<FilterSearch
								filterData={filterData}
								setFilterData={setFilterData}
								reduxFilterSearch={true}
							/>
						</div>
						<div>
							<Dropdown onToggle={() => setDropdownIsOpen(!dropdownIsOpen)} show={dropdownIsOpen}>
								<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
									{t('FILTER')}
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-filter">
									<div className="row">
										<div className="form-group col-md-6">
											<label>{t('PERIOD')}</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.startDate ? new Date(filterData.startDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="form-group col-md-6">
											<label className="invisible">.</label>
											<div className="calendar-input">
												<DatePicker
													className="form-control"
													dateFormat="dd.MM.yyyy"
													selected={filterData.endDate ? new Date(filterData.endDate) : ''}
													onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
												<i className="uil uil-calendar-alt"></i>
											</div>
										</div>
										<div className="col-md-6">
											<label>{t('STATUS')}</label>
											<Select
												options={deliveryStatuses}
												value={deliveryStatuses.find(option => option.id === data.deliveryStatus)}
												onChange={(option) => setFilterData({ ...filterData, 'deliveryStatus': option.id })}
												placeholder=""
												noOptionsMessage={() => t('LIST_IS_EMPTY')}
												getOptionLabel={(option) => option.name}
												getOptionValue={(option) => option.id}
											/>
										</div>
									</div>
									<div className="row mt-3">
										<div className="col-md-12">
											<div className="d-flex justify-content-end">
												<button type="button" className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
												<button type="button" className="btn btn-primary" onClick={() => setFilterData({ ...filterData, page: 0, random: filterData.random + 1 })}>
													{t('APPLY')}
												</button>
											</div>
										</div>
									</div>
								</Dropdown.Menu>
							</Dropdown>
						</div>
						<div>
							<button className="btn btn-outline-primary btn-wide" onClick={() => exportToExcel()}>EXCEL</button>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block mb-3">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.startDate || filterData.endDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.startDate &&
											<span>{formatDate(filterData.startDate)}</span>
										}
										{(filterData.startDate && filterData.endDate) &&
											<span>|</span>
										}
										{filterData.endDate &&
											<span>{formatDate(filterData.endDate)}</span>
										}
									</span>
								}
								{filterData.deliveryStatus &&
									<span className="me-2">
										<b>{t('STATUS')}: </b> {findFromArrayById(deliveryStatuses, filterData.deliveryStatus)}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('POS')}</th>
									<th>{t('WAREHOUSE')}</th>
									<th className="text-center">{t('AMOUNT')}</th>
									<th className="text-center">{t('STATUS')}</th>
									<th className="text-center">{t('DATE_OF_CREATION')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}. {item.posName}</td>
										<td>{item.wmsName}</td>
										<td className="text-center">{formatMoney(item.totalAmount)}</td>
										<td className="text-center">
											{item.status === 1 &&
												<span className="bg-warning bg-border text-white text-nowrap">{t('NEW')}</span>
											}
											{item.status === 2 &&
												<span className="bg-success bg-border text-white">{t('CLOSED')}</span>
											}
										</td>
										<td className="text-center">{formatDateWithTime(item.createdDate)}</td>
										<td className="d-flex justify-content-center">
											{item.status ?
												<Link to={'/wms/orders/preview/' + item.id} className="me-2">
													<div className="table-action-button table-action-primary-button">
														<i className="uil-eye"></i>
													</div>
												</Link>
												:
												<Link to={'/wms/orders/preview/' + item.id} className="me-2">
													<div className="table-action-button table-action-primary-button">
														<i className="uil-edit-alt"></i>
													</div>
												</Link>
											}
											<div className="table-action-button table-action-success-button" onClick={() => exportToExcelDetail(item)}>
												<i className="uil-file-alt"></i>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableNomenclature'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Index
