import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import DatePicker from "react-datepicker"
import { Dropdown } from 'react-bootstrap'
import XLSX from 'xlsx';

import useDidMountEffect from 'pages/parts/useDidMountEffect'
import { GET } from 'helpers/api'
import { formatMoney, returnSign, formatDate, formatDateBackend } from 'helpers/helpers'

function Summary() {
	const { t } = useTranslation()

	const reduxAccount = useSelector(state => state.account)

	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: null,
		reportDate: null,
	})
	const [data, setData] = useState({})
	const [poses, setPoses] = useState([])
	const [logins, setLogins] = useState([])

	async function searchWithFilter(exportToExcel = false) {
		var filterUrl = `/services/${reduxAccount.service}/api/report-summary-total`
		var urlParams = ""

		if (filterData.login)
			urlParams += returnSign(urlParams) + 'login=' + filterData.login
		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.reportDate)
			urlParams += returnSign(urlParams) + 'reportDate=' + filterData.reportDate

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = `/services/${reduxAccount.service}/api/report-summary-total/` + urlParams + returnSign(filterUrl)

			var excelHeaders = [[
				t('DATE'), t('GOOD_RECEPTIONED'), t('RETURN_OF_GOODS'), t('SALES'), t('CASH'), t('TERMINAL'), t('BORROWED'),
				'moneyBek ' + t('BONUS'), t('DEBT_RETURNED'), t('PAYMENT_LOYALTY'), t('CONSUMPTION'), t('GROSS_INCOME'), t('TOTAL'),
			]]
			const response = await GET(filterUrl)
			for (let i = 0; i < response.reportList.length; i++) {
				var excelItems = []
				excelItems.push(formatDate(response.reportList[i].reportDate))
				excelItems.push(formatMoney(response.reportList[i].inAmount))
				excelItems.push(formatMoney(response.reportList[i].outAmount))
				excelItems.push(formatMoney(response.reportList[i].salesAmount))
				excelItems.push(formatMoney(response.reportList[i].cashAmount))
				excelItems.push(formatMoney(response.reportList[i].cardAmount))
				excelItems.push(formatMoney(response.reportList[i].debtOutAmount))
				excelItems.push(formatMoney(response.reportList[i].loyaltyBonus))
				excelItems.push(formatMoney(response.reportList[i].debtInAmount))
				excelItems.push(formatMoney(response.reportList[i].loyaltyClientAmount))
				excelItems.push(formatMoney(response.reportList[i].expenseAmount))
				excelItems.push(formatMoney(response.reportList[i].grossIncome))
				excelItems.push(formatMoney(response.reportList[i].totalAmount))
				excelHeaders.push(excelItems)
			}
			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('SUMMARY') + ".xlsx");
		} else {
			const response = await GET(filterUrl, {}, { loader: true })
			if (response) {
				setData(response)
			}
		}

		setDropdownIsOpen(false)
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/services/web/api/pos-helper')
		if (response) {
			setPoses(response)
		}
	}

	async function getLogin() {
		const response = await GET('/services/admin/api/login-helper')
		if (response) {
			setLogins(response)
		}
	}

	async function getAdminPoses(login) {
		if (!login) return
		const response = await GET(`/services/admin/api/login-pos-helper?login=${login}`)
		if (response) {
			setPoses(response)
		}
	}

	useEffect(() => {
		if (reduxAccount.service === 'admin') {
			getLogin()
		}
		if (reduxAccount.service === 'web') {
			getPoses()
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getAdminPoses(filterData.login)
	}, [filterData.login]) // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		searchWithFilter()
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">

					<div className="d-flex mb-3">
						<div className="d-flex">
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										{reduxAccount.service === 'admin' &&
											<div className="row">
												<div className="form-group col-md-6">
													<label>{t('LOGIN')}</label>
													<Select
														classNamePrefix="react-select"
														options={logins}
														value={logins.find(option => option.id === filterData.login) || ''}
														onChange={(option) => setFilterData({ ...filterData, 'login': option.id })}
														placeholder=""
														noOptionsMessage={() => t('LIST_IS_EMPTY')}
														getOptionLabel={(option) => option.name}
														getOptionValue={(option) => option.id}
													/>
												</div>
											</div>
										}

										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													classNamePrefix="react-select"
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('PERIOD')}</label>
												<div className="calendar-input">
													<DatePicker
														showMonthYearPicker
														showFullMonthYearPicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.reportDate ? new Date(filterData.reportDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'reportDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button className="btn btn-primary"
														disabled={!(filterData.posId && filterData.reportDate)}
														onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
						</div>
						<div>
							<button className="btn btn-outline-primary btn-wide" onClick={() => searchWithFilter(true)}>EXCEL</button>
						</div>
					</div>

					<div className="table-responsive">
						<table className="table mb-0">
							<thead>
								<tr>
									<th>{t('DATE')}</th>
									<th className="text-center">{t('GOOD_RECEPTIONED')}</th>
									<th className="text-center">{t('RETURN_OF_GOODS')}</th>
									<th className="text-center">{t('SALES')}</th>
									<th className="text-center">{t('CASH')}</th>
									<th className="text-center">{t('TERMINAL')}</th>
									<th className="text-center">{t('BORROWED')}</th>
									<th className="text-center">moneyBek {t('BONUS')}</th>
									<th className="text-center">{t('DEBT_RETURNED')}</th>
									<th className="text-center">{t('PAYMENT_LOYALTY')}</th>
									<th className="text-center">{t('CONSUMPTION')}</th>
									<th className="text-center">{t('GROSS_INCOME')}</th>
									<th className="text-end">{t('TOTAL')}</th>
								</tr>
							</thead>
							<tbody>
								{data?.reportList?.map((item, index) => (
									<tr key={index}>
										<td>{formatDate(item.reportDate)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.inAmount, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.outAmount, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.salesAmount, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.cashAmount, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.cardAmount, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.debtOutAmount, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.loyaltyBonus, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.debtInAmount, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.loyaltyClientAmount, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.expenseAmount, 0)}</td>
										<td className="text-center text-nowrap">{formatMoney(item.grossIncome, 0)}</td>
										<td className="text-end text-nowrap">{formatMoney(item.totalAmount, 0)}</td>
									</tr>
								))
								}
								{data?.reportList?.length > 0 &&
									<tr>
										<td><b>{t('TOTAL')}</b></td>
										<td className="text-center text-nowrap"><b>{formatMoney(data.inAmountTotal, 0)}</b></td>
										<td className="text-center text-nowrap"><b>{formatMoney(data.outAmountTotal, 0)}</b></td>
										<td className="text-center text-nowrap"><b>{formatMoney(data.salesAmountTotal, 0)}</b></td>
										<td className="text-center text-nowrap"><b>{formatMoney(data.cashAmountTotal, 0)}</b></td>
										<td className="text-center text-nowrap"><b>{formatMoney(data.cardAmountTotal, 0)}</b></td>
										<td className="text-center text-nowrap"><b>{formatMoney(data.debtOutAmountTotal, 0)}</b></td>
										<td className="text-center text-nowrap"><b>{formatMoney(data.loyaltyBonusTotal, 0)}</b></td>
										<td className="text-center text-nowrap"><b>{formatMoney(data.debtInAmountTotal, 0)}</b></td>
										<td className="text-center text-nowrap"><b>{formatMoney(data.loyaltyClientAmountTotal, 0)}</b></td>
										<td className="text-center text-nowrap"><b>{formatMoney(data.expenseAmountTotal, 0)}</b></td>
										<td className="text-center text-nowrap"><b>{formatMoney(data.grossIncomeTotal, 0)}</b></td>
										<td className="text-end text-nowrap"><b>{formatMoney(data.totalAmountTotal, 0)}</b></td>
									</tr>
								}
							</tbody>
						</table>
					</div>

				</div>
			</div>
		</>
	)
}

export default Summary