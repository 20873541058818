import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { PGET, POST, PUT, GET } from 'helpers/api'
import { checkRole, regexNumeric, regexPhoneNumber } from 'helpers/helpers'

function Index() {
	const URL = '/services/web/api/organization-pageList'
	const { t } = useTranslation()

	const settings = useSelector(state => state.settings)

	const canCreate = checkRole('MAIN_ORGANIZATION_ADD')
	const canUpdate = checkRole('MAIN_ORGANIZATION_EDIT')


	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])
	const [selectedItem, setSelectedItem] = useState({})
	const [showModal, setShowModal] = useState(false)

	async function createUpdate(e) {
		e.preventDefault();

		var sendData = { ...selectedItem }
		sendData.phone = '998' + regexNumeric(sendData.phone)
		sendData.directorPhone = '998' + regexNumeric(sendData.directorPhone)
		sendData.buxPhone = '998' + regexNumeric(sendData.buxPhone)

		var response;
		if (sendData.id) {
			response = await PUT('/services/web/api/organization', sendData, { loader: true })
		} else {
			const checkLogin = await GET(
				'/services/web/api/check-login?login=' + sendData.organizationLogin,
				{},
				{ loader: true, message: false }
			)

			if (!checkLogin.success) {
				return
			}
			response = await POST('/services/web/api/organization', sendData, { loader: true })
		}

		if (response) {
			getData()
			setShowModal(false)
		}
	}

	function openModal(item) {
		var itemCopy = { ...item }
		itemCopy.phone = itemCopy?.phone?.substring(3, 12)
		itemCopy.directorPhone = itemCopy?.directorPhone?.substring(3, 12)
		itemCopy.buxPhone = itemCopy?.buxPhone?.substring(3, 12)
		if (itemCopy.id && itemCopy?.organizationLogin?.length > 0) {
			itemCopy.organizationLoginDisabled = true;
		}
		setSelectedItem(itemCopy)
		setShowModal(true)
	}

	function closeModal() {
		setSelectedItem({})
		setShowModal(false)
	}

	async function getData() {
		const response = await PGET('/services/web/api/organization-pageList', {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET('/services/web/api/organization-pageList', { page: data.selected, size: settings.size }, { loader: true })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('SUPPLIERS')}
				</h4>
				<div className="d-flex">
					{canCreate && <button className="btn-rounded btn btn-primary" onClick={() => setShowModal(true)}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>
					}
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('NAME')}</th>
									<th>{t('PHONE')}</th>
									<th>{t('PHONE_DIRECTOR')}</th>
									<th>{t('PHONE_ACCOUNTANT')}</th>
									<th>{t('TIN')}</th>
									<th>{t('LEGAL_NAME')}</th>
									<th>{t('LEGAL_ADDRESS2')}</th>
									<th className="text-center">{t('HIDE')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.name}</td>
										<td>{regexPhoneNumber(item.phone)}</td>
										<td>{regexPhoneNumber(item.directorPhone)}</td>
										<td>{regexPhoneNumber(item.buxPhone)}</td>
										<td>{item.tin}</td>
										<td>{item.legalName}</td>
										<td>{item.legalAddress}</td>
										<td className="text-center">
											{item.hide ?
												<span>{t('YES')}</span>
												:
												<span>{t('NO')}</span>
											}
										</td>
										<td className="d-flex justify-content-center">
											{canUpdate &&
												<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
													<i className="uil-edit-alt"></i>
												</div>
											}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedItem.id ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ height: '700px', overflow: 'auto' }}>
					<form onSubmit={createUpdate} autoComplete="off">
						<div className="form-group">
							<label>{t('TITLE')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="name"
								value={selectedItem.name}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'name': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('LOGIN')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="organizationLogin" value={selectedItem.organizationLogin}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'organizationLogin': e.target.value.toLowerCase() })}
								disabled={selectedItem.organizationLoginDisabled}
							/>
						</div>
						{!selectedItem.id &&
							<div className="form-group">
								<label>{t('PASSWORD')}<span className="required-mark">*</span></label>
								<input type="text" className="form-control" name="password"
									onChange={(e) => setSelectedItem({ ...selectedItem, 'password': e.target.value })}
								/>
								<span className="text-danger text-sm">{selectedItem?.password?.length < 8 && t('MIN_LENGTH', { length: 8 })}</span>
							</div>
						}
						{selectedItem.id &&
							<div className="form-group">
								<label>{t('PASSWORD')}</label>
								<input type="text" className="form-control" name="password"
									onChange={(e) => setSelectedItem({ ...selectedItem, 'password': e.target.value })}
								/>
							</div>
						}
						<div className="form-group">
							<label>{t('PHONE')}</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone"
									value={selectedItem.phone}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'phone': e.target.value })} />
							</div>
						</div>
						<div className="form-group">
							<label>Юридик номи</label>
							<input type="text" className="form-control" name="legalName"
								value={selectedItem.legalName}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'legalName': e.target.value })} />
						</div>
						<div className="form-group">
							<label>Юридик манзил</label>
							<input type="text" className="form-control" name="legalAddress"
								value={selectedItem.legalAddress}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'legalAddress': e.target.value })} />
						</div>
						<div className="form-group">
							<label>ИНН</label>
							<input type="number" className="form-control" name="tin"
								value={selectedItem.tin}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'tin': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('PHONE')} директор</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="directorPhone"
									value={selectedItem.directorPhone}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'directorPhone': e.target.value })} />
							</div>
						</div>
						<div className="form-group">
							<label>{t('PHONE')} бугалтер</label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="buxPhone"
									value={selectedItem.buxPhone}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'buxPhone': e.target.value })} />
							</div>
						</div>
						<div className="form-group">
							<label>НДС туловчи коди</label>
							<input type="number" className="form-control" name="vatCode"
								value={selectedItem.vatCode}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'vatCode': e.target.value })} />
						</div>

						<div className="form-group">
							<label>{t('DEBT_PAYMENT_DATE')}</label>
							<input type="number" className="form-control" name="returnDay"
								value={selectedItem.returnDay}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'returnDay': e.target.value })} />
						</div>
						<div className="d-flex justify-content-between">
							<p className="fw-600 vertical-center">{t('HIDE')}</p>
							<div className="form-check form-switch form-switch-lg">
								<input type="checkbox" className="form-check-input" name="hide"
									checked={selectedItem.hide}
									onChange={(e) => setSelectedItem({ ...selectedItem, 'hide': e.target.checked })}
								/>
							</div>
						</div>

						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100" disabled={!selectedItem.id && (!selectedItem.organizationLogin || !selectedItem.password)}>
								{t('SAVE')}
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
