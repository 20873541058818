const initialState = {}

const filterData = function (state = initialState, action) {
	switch (action.type) {
		case "SET_ACCOUNT":
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

export default filterData