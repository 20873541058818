import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
//import { toast } from 'react-toastify'

import { useForm, Controller } from "react-hook-form";

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'

import { PGET, GET, POST, PUT } from 'helpers/api'
import { regexNumeric, regexPhoneNumber } from 'helpers/helpers'

function Index() {
	const URL = "/services/wms/api/users-pageList"
	const { t } = useTranslation()

	const { register, handleSubmit, formState, control, setValue, getValues, reset } = useForm({
		mode: "onChange",
		//resolver: yupResolver(validationSchema)
	});
	const { errors } = formState

	const options = [
		{ id: '1', name: 'ROLE_CASHIER', i18n: t('CASHIER'), authority: [{ name: 'ROLE_CASHIER' }] },
		{ id: '2', name: 'ROLE_MERCHANDISER', i18n: t('MERCHANDISER'), authority: [{ name: 'ROLE_MERCHANDISER' }] },
		{ id: '3', name: 'ROLE_AGENT', i18n: t('AGENT'), authority: [{ name: 'ROLE_AGENT' }] },
	];

	const settings = useSelector(state => state.settings)
	const [pageCount, setPageCount] = useState(0)
	const [data, setData] = useState([])
	const [validData, setValidData] = useState({ 'isValid': 3 })
	const [showModal, setShowModal] = useState(false)

	async function createUpdate(data) {
		var sendData = { ...data }
		sendData.phone = '998' + regexNumeric(sendData.phone)

		if (validData.isValid === 5) {
			return
		}

		var response;
		if (sendData.id) {
			response = await PUT('/services/wms/api/users', sendData, { loader: true })
		} else {
			const checkLogin = await GET(
				'/services/wms/api/check-login?login=' + sendData.userLogin,
				{},
				{ loader: true, message: false }
			)

			if (!checkLogin.success) {
				return
			}
			sendData.userLogin = sendData.userLogin.toLowerCase()
			response = await POST('/services/wms/api/users', sendData, { loader: true })
		}

		if (response) {
			getData()
			reset()
			setShowModal(false)
		}
	}

	function openModal(item) {
		setValidData({ ...validData, 'isValid': 3 })
		var itemCopy = { ...item }
		var authorities = []
		for (let i = 0; i < itemCopy.authorities.length; i++) {
			if (itemCopy.authorities[i]['name'] === 'ROLE_CASHIER') {
				authorities.push(options[0])
			}
			if (itemCopy.authorities[i]['name'] === 'ROLE_MERCHANDISER') {
				authorities.push(options[1])
			}
			if (itemCopy.authorities[i]['name'] === 'ROLE_AGENT') {
				authorities.push(options[2])
			}
		}
		if (authorities.length > 0) {
			itemCopy.authorities = authorities
		}
		if (itemCopy.phone != null) {
			itemCopy.phone = itemCopy.phone.substring(3, 12)
		}
		for (const [key, value] of Object.entries(itemCopy)) {
			setValue(key, value)
		}
		setShowModal(true)
	}

	function closeModal() {
		reset()
		setShowModal(false)
	}

	async function getData() {
		const response = await PGET(URL, {}, { loader: true })
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / settings.size))
			setData(response.data)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL, { page: data.selected, size: settings.size })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	function validatePassword(password) {
		var regexm = new RegExp("^.*[a-zA-Z].*$")
		var regexm2 = new RegExp("^.*[0-9].*$")

		if (password === "") {
			setValidData({ ...validData, 'isValid': 3 })
		}
		if (regexm.test(password) && regexm2.test(password)) {
			setValidData({ ...validData, 'password': password, 'isValid': 4 })
		} else {
			setValidData({ ...validData, 'password': password, 'isValid': 5 })
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('MY_USERS')}
				</h4>
				<div className="d-flex">
					<button className="btn-rounded btn btn-primary" onClick={() => setShowModal(true)}>
						<i className="uil uil-plus me-2"></i>
						{t('CREATE')}
					</button>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									<th>{t('FIO')}</th>
									<th>{t('LOGIN')}</th>
									<th>{t('PHONE')}</th>
									<th>{t('ROLE')}</th>
									<th className="text-center">{t('ACTION')}</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{index + 1}. {item.firstName + " " + item.lastName}</td>
										<td>{item.userLogin}</td>
										<td>{regexPhoneNumber(item.phone)}</td>
										<td>
											{item.authorities.map((item, index) => (
												<div key={index}>
													{item.name === "ROLE_CASHIER" &&
														<span>{t('CASHIER')}</span>
													}
													{item.name === "ROLE_MERCHANDISER" &&
														<span>{t('MERCHANDISER')}</span>
													}
													{item.name === "ROLE_AGENT" &&
														<span>{t('AGENT')}</span>
													}
												</div>
											))}
										</td>
										<td className="d-flex justify-content-center">
											<div className="table-action-button table-action-primary-button" onClick={() => openModal(item)}>
												<i className="uil-edit-alt"></i>
											</div>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			{/* MODAL */}
			<Modal show={showModal} animation={false} centered dialogClassName="update-modal-width" backdrop="static" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{getValues('id') ?
							<>{t('EDIT')}</>
							:
							<>{t('CREATE')}</>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleSubmit(createUpdate)} autoComplete="off">
						<div className="form-group">
							<label>{t('ROLE')}<span className="required-mark">*</span></label>
							<Controller
								name="authorities"
								control={control}
								render={({ field }) =>
									<Select
										{...field}
										options={options}
										isMulti
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.i18n}
										getOptionValue={(option) => option.id}
									/>}
							/>
						</div>
						<div className="form-group">
							<label>{t('NAME')}<span className="required-mark">*</span></label>
							<input type="text" className="form-control" name="firstName" {...register('firstName', { required: true })} />
							<span className="text-danger text-sm">{errors.firstName?.type === 'required' && t('required')}</span>
						</div>
						<div className="form-group">
							<label>{t('LAST_NAME')}</label>
							<input type="text" className="form-control" name="lastName" {...register('lastName')} />
						</div>
						<div className="form-group">
							<label>{t('PHONE')}<span className="required-mark">*</span></label>
							<div className="input-group">
								<span className="input-group-text">998</span>
								<input type="number" className="form-control" name="phone"
									{...register('phone', { required: true })}

								/>
							</div>
							<span className="text-danger text-sm">{errors.phone?.type === 'required' && t('required')}</span>
						</div>
						{!getValues('id') &&
							<div className="form-group">
								<label>{t('LOGIN')}<span className="required-mark">*</span></label>
								<input type="text" className="form-control" name="userLogin" {...register('userLogin', { required: true })} />
								<span className="text-danger text-sm">{errors.userLogin?.type === 'required' && t('required')}</span>
							</div>
						}
						{!getValues('id') &&
							<div className="form-group">
								<label>{t('PASSWORD')}<span className="required-mark">*</span></label>
								<input type="text" className="form-control" name="password"
									{...register('password', { required: true, minLength: 8 })}
									onChange={(e) => {
										validatePassword(e.target.value)
									}}
								/>
								<div className="text-danger text-sm">{errors.password?.type === 'required' && t('required')}</div>
								<div className="text-danger text-sm">{errors.password?.type === 'minLength' && 'Минимум 8 символов'}</div>
								{validData.isValid === 5 &&
									<div className="text-danger text-sm">Пароль должен содержать буквы и цифры</div>
								}
							</div>
						}
						{getValues('id') &&
							<div className="form-group">
								<label>{t('PASSWORD')}</label>
								<input type="text" className="form-control" name="password" {...register('password', { required: false, minLength: 8 })}
									onChange={(e) => {
										validatePassword(e.target.value)
									}} />
								<div className="text-danger text-sm">{errors.password?.type === 'required' && t('required')}</div>
								<div className="text-danger text-sm">{errors.password?.type === 'minLength' && 'Минимум 8 символов'}</div>
								{validData.isValid === 5 &&
									<div className="text-danger text-sm">Пароль должен содержать буквы и цифры</div>
								}
							</div>
						}
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}
		</>
	)
}

export default Index
