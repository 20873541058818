export let exportMenuOrganization = [
	{
		icon: "uil-chart-pie-alt",
		children: [
			{
				icon: "uil-chart-pie-alt",
				children: [
					{
						url: "/organization-balance",
						i18n: "BALANCE_REPORT",
						icon: "uil-chart-pie-alt",
						active: false,
					},
				]
			}
		],
	}
]