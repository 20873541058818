import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select from 'react-select'
import XLSX from 'xlsx'

import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'

import { PGET, GET } from 'helpers/api'
import { formatMoney, returnSign, formatDateBackend, findFromArrayById, formatDate } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const reduxAccount = useSelector(state => state.account)

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]

	const [logins, setLogins] = useState([])
	const [poses, setPoses] = useState([])
	const [organizations, setOrganizations] = useState([])
	const [categories, setCategories] = useState([])
	const [totalAmount, setTotalAmont] = useState(0)
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		posId: null,
		dateBalance: null,
		productCategoryId: null,
		currencyId: 1,
		search: '',
		login: '',
	})
	const [pagination, setPagination] = useState({
		url: `/services/${reduxAccount.service}/api/report-balance-product/` + filterData.currencyId,
		response: null
	})
	const [data, setData] = useState([])

	async function searchWithFilter(exportToExcel = false, tableSort = "") {
		var filterUrl = `/services/${reduxAccount.service}/api/report-balance-product/` + filterData.currencyId
		var urlParams = ""
		checkFilter()

		if (filterData.login)
			urlParams += returnSign(urlParams) + 'login=' + filterData.login
		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.organizationId)
			urlParams += returnSign(urlParams) + 'organizationId=' + filterData.organizationId
		if (filterData.productCategoryId)
			urlParams += returnSign(urlParams) + 'productCategoryId=' + filterData.productCategoryId
		if (filterData.dateBalance)
			urlParams += returnSign(urlParams) + 'dateBalance=' + filterData.dateBalance
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if (`/services/${reduxAccount.service}/api/report-balance-product/` + filterData.currencyId + urlParams !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		if (tableSort) {
			var filterDataCopy = { ...filterData }
			if (filterDataCopy.tableSortKey === tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + tableSort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			filterUrl = "/services/web/api/report-balance-product-excel/" + filterData.currencyId + urlParams + returnSign(filterUrl)

			var excelHeaders = [[t('POS'), t('NAME_OF_PRODUCT'), t('BARCODE'), t('QUANTITY'), t('UNIT'), t('TOTAL_AMOUNT')]]
			const response = await GET(filterUrl)
			for (let i = 0; i < response.length; i++) {
				var excelItems = []
				excelItems.push(response[i].posName)
				excelItems.push(response[i].productName)
				excelItems.push(response[i].productBarcode)
				excelItems.push(response[i].balance)
				excelItems.push(response[i].uomName)
				excelItems.push(response[i].totalAmount)
				excelHeaders.push(excelItems)
			}
			const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
			XLSX.writeFile(wb, t('BALANCE_REPORT') + ".xlsx");
		} else {
			const response = await PGET(filterUrl, {}, { loader: true })
			if (response) {
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response })
			}

			filterUrl = `/services/${reduxAccount.service}/api/report-balance-total/` + filterData.currencyId + urlParams
			const temporaryTotalAmount = await GET(filterUrl)
			setTotalAmont(temporaryTotalAmount.totalAmount)
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			posId: null,
			dateBalance: null,
			productCategoryId: null,
			currencyId: 1,
			search: '',
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await PGET("/services/web/api/report-balance-product/" + filterData.currencyId, {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': "/services/web/api/report-balance-product/" + filterData.currencyId, 'response': response })
		}

		const response2 = await GET("/services/web/api/report-balance-total/" + filterData.currencyId, {}, { loader: false })
		if (response2) {
			setTotalAmont(response2['totalAmount'])
		}
	}

	function checkFilter() {
		if (
			filterData.posId === null &&
			filterData.dateBalance === null &&
			filterData.currencyId === 1 &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}


	async function getPoses() {
		const response = await GET(`/services/web/api/pos-helper`)
		if (response) {
			setPoses(response)
		}
	}

	async function getOrganizations(login) {
		var urlParams = ""

		if (login)
			urlParams += returnSign(urlParams) + 'login=' + login

		const response = await GET(`/services/${reduxAccount.service}/api/organization-helper${urlParams}`)
		if (response) {
			setOrganizations(response)
		}
	}

	async function getCategories() {
		const response = await GET(`/services/${reduxAccount.service}/api/product-category-helper`)
		if (response) {
			setCategories(response)
		}
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	async function getLogin() {
		const response = await GET('/services/admin/api/login-helper')
		if (response) {
			setLogins(response)
		}
	}

	async function getAdminPoses(login) {
		if (!login) return
		const response = await GET(`/services/admin/api/login-pos-helper?login=${login}`)
		if (response) {
			setPoses(response)
		}
		getOrganizations(login)
	}

	useEffect(() => {
		if (reduxAccount.service === 'admin') {
			getLogin()
		}
		if (reduxAccount.service === 'web') {
			getPoses()
		}
		getOrganizations()
		getCategories()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		getAdminPoses(filterData.login)
	}, [filterData.login]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										{reduxAccount.service === 'admin' &&
											<div className="row">
												<div className="form-group col-md-6">
													<label>{t('LOGIN')}</label>
													<Select
														classNamePrefix="react-select"
														options={logins}
														value={logins.find(option => option.id === filterData.login) || ''}
														onChange={(option) => setFilterData({ ...filterData, 'login': option.id })}
														placeholder=""
														noOptionsMessage={() => t('LIST_IS_EMPTY')}
														getOptionLabel={(option) => option.name}
														getOptionValue={(option) => option.id}
													/>
												</div>
											</div>
										}
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													classNamePrefix="react-select"
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('CURRENCY')}</label>
												<Select
													classNamePrefix="react-select"
													options={currencies}
													value={currencies.find(option => option.id === filterData.currencyId)}
													onChange={(option) => setFilterData({ ...filterData, 'currencyId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('SUPPLIER')}</label>
												<Select
													classNamePrefix="react-select"
													options={organizations}
													value={organizations.find(option => option.id === filterData.organizationId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'organizationId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('CATEGORY')}</label>
												<Select
													classNamePrefix="react-select"
													options={categories}
													value={categories.find(option => option.id === filterData.productCategoryId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'productCategoryId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('DATE')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.dateBalance ? new Date(filterData.dateBalance) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'dateBalance': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide" onClick={() => searchWithFilter(true)}>EXCEL</button>
							</div>
						</div>
						<div className="vertical-center">
							<h4>
								<b>{t('TOTAL_AMOUNT')}</b>: {formatMoney(totalAmount)}
							</h4>
						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.organizationId &&
									<span className="me-2">
										<b>{t('SUPPLIER')}: </b> {findFromArrayById(organizations, filterData.organizationId)}
									</span>
								}
								{filterData.productCategoryId &&
									<span className="me-2">
										<b>{t('CATEGORY')}: </b> {findFromArrayById(categories, filterData.productCategoryId)}
									</span>
								}
								{filterData.currencyId &&
									<span className="me-2">
										<b>{t('CURRENCY')}: </b> {findFromArrayById(currencies, filterData.currencyId)}
									</span>
								}
								{filterData.dateBalance &&
									<span className="me-2">
										<b>{t('DATE')}: </b>
										<span>{formatDate(filterData.dateBalance)}</span>
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table mb-0">
							<thead>
								<tr>
									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'posName')}>
										{t('POS')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'posName') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'posName') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'productName')}>
										{t('NAME_OF_PRODUCT')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'productName') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'productName') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable text-center cursor" onClick={() => searchWithFilter(false, 'productBarcode')}>
										{t('BARCODE')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'productBarcode') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'productBarcode') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable text-center cursor" onClick={() => searchWithFilter(false, 'balance')}>
										{t('QUANTITY')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'balance') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'balance') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable text-center cursor" onClick={() => searchWithFilter(false, 'totalAmount')}>
										{t('TOTAL_AMOUNT')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'totalAmount') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'totalAmount') && 'sort-active')}></i>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<td>{item.rowNum}. {item.posName}</td>
										<td>{item.productName}</td>
										<td className="text-center">{item.productBarcode}</td>
										<td className="text-center">{formatMoney(item.balance)} {item.uomName}</td>
										<td className="text-end">{formatMoney(item.totalAmount)}</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>
		</>
	)
}

export default Index
