import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Dropdown } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import Select, { components } from 'react-select'

import Rightbar from 'pages/pos/cheques/Rightbar'
import FilterSearch from 'pages/parts/FilterSearch'
import FilterPagination from 'pages/parts/FilterPagination'
import TableControl from 'pages/parts/TableControl'

import { PGET, GET, DOWNLOAD_FILE } from 'helpers/api'
import { formatMoney, returnSign, formatDateBackend, findFromArrayById, formatDate, formatDateWithTime } from 'helpers/helpers'

function Index() {
	const { t } = useTranslation()
	const date = new Date()

	const reduxSettings = useSelector(state => state.settings)

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]

	const [categoriesInitial, setCategoriesInitial] = useState([])
	const [isOpen, setIsOpen] = useState(false)
	const [showRightBar, setShowRightBar] = useState(false)
	const [selectedItem, setSelectedItem] = useState({})
	const [poses, setPoses] = useState([])
	const [paymentTypes, setPaymentTypes] = useState([])
	const [organiations, setOrganizations] = useState([])
	const [categories, setCategories] = useState([])
	const [cashiers, setCashiers] = useState([])
	const [uoms, setUoms] = useState([])
	const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
	const [totalAmount, setTotalAmont] = useState([])
	const [filterDataExist, setFilterDataExist] = useState(false)
	const [filterData, setFilterData] = useState({
		startDate: formatDateBackend(new Date(date.getFullYear(), date.getMonth(), 1)),
		endDate: formatDateBackend(new Date()),
		posId: null,
		organizationId: null,
		productCategoryId: null,
		groupBy: false,
		search: '',
	})
	const [pagination, setPagination] = useState({
		url: '/services/wms/api/report-sales',
		response: null
	})
	const [data, setData] = useState([])
	const [modalTableControl, setModalTableControl] = useState(false)

	async function toggleRightbar(boolean = false, item, index) {
		var dataCopy = [...data]

		for (let i = 0; i < dataCopy.length; i++) {
			dataCopy[i]['showSidebar'] = false
		}

		if (item.chequeId !== 0) {
			if (boolean) {
				const response = await GET("/services/wms/api/cheque-byId/" + item.chequeId, {}, { loader: true })
				dataCopy[index]['itemsList'] = response
				dataCopy[index]['showSidebar'] = boolean
				setSelectedItem(response)
				setShowRightBar(boolean)
			} else {
				setShowRightBar(boolean)
				setSelectedItem({})
			}
		}
	}

	async function searchWithFilter(exportToExcel = false, tableSort = "") {
		var filterUrl = "/services/wms/api/report-sales"
		var urlParams = ""
		checkFilter()

		if (filterData.startDate)
			urlParams += returnSign(urlParams) + 'startDate=' + filterData.startDate
		if (filterData.endDate)
			urlParams += returnSign(urlParams) + 'endDate=' + filterData.endDate
		if (filterData.posId)
			urlParams += returnSign(urlParams) + 'posId=' + filterData.posId
		if (filterData.organizationId)
			urlParams += returnSign(urlParams) + 'organizationId=' + filterData.organizationId
		if (filterData.productCategoryId)
			urlParams += returnSign(urlParams) + 'productCategoryId=' + filterData.productCategoryId
		if (filterData.cashierId)
			urlParams += returnSign(urlParams) + 'cashierId=' + filterData.cashierId
		if (filterData.uomId)
			urlParams += returnSign(urlParams) + 'uomId=' + filterData.uomId
		if (filterData.paymentTypeId)
			urlParams += returnSign(urlParams) + 'paymentTypeId=' + filterData.paymentTypeId
		if (filterData.currencyId)
			urlParams += returnSign(urlParams) + 'currencyId=' + filterData.currencyId
		if (filterData.groupBy)
			urlParams += returnSign(urlParams) + 'groupBy=' + filterData.groupBy
		if (filterData.search)
			urlParams += returnSign(urlParams) + 'search=' + filterData.search

		if ("/services/wms/api/report-sales" + urlParams !== filterUrl && !exportToExcel)
			setFilterDataExist(true)

		if (tableSort) {
			var filterDataCopy = { ...filterData }
			if (filterDataCopy.tableSortKey === tableSort) {
				filterDataCopy.tableSortDirection === ',asc' ? filterDataCopy.tableSortDirection = ',desc' : filterDataCopy.tableSortDirection = ',asc'
			} else {
				filterDataCopy.tableSortDirection = ',asc'
			}
			filterDataCopy.tableSortKey = tableSort

			setFilterData(filterDataCopy)
			urlParams += returnSign(urlParams) + 'sort=' + tableSort + filterDataCopy.tableSortDirection
		}

		filterUrl = filterUrl + urlParams

		if (exportToExcel) {
			await DOWNLOAD_FILE(`/services/wms/api/report-sales-excel${urlParams}`, t('SALES_REPORT'))

		} else {
			const response = await PGET(filterUrl, {}, { loader: true })
			if (response) {
				setData(response.data)
				setPagination({ ...pagination, 'url': filterUrl, 'response': response })
			}
			filterUrl = "/services/wms/api/report-sales-total" + urlParams
			const temporaryTotalAmount = await GET(filterUrl)
			setTotalAmont(temporaryTotalAmount)
		}

		setDropdownIsOpen(false)
	}

	function resetFilter() {
		setFilterData({
			startDate: null,
			endDate: null,
			posId: null,
			organizationId: null,
			productCategoryId: null,
			groupBy: false,
			search: '',
		})
		setFilterDataExist(false)
		getData()
	}

	async function getData() {
		const response = await PGET("/services/wms/api/report-sales", {}, { loader: true })
		if (response) {
			setData(response.data)
			setPagination({ ...pagination, 'url': "/services/wms/api/report-sales", 'response': response })
		}
		const response2 = await GET("/services/wms/api/report-sales-total", {}, { loader: false })
		if (response2) {
			setTotalAmont(response2)
		}
	}

	function checkFilter() {
		if (
			filterData.startDate === null &&
			filterData.endDate === null &&
			filterData.posId === null &&
			filterData.organizationId === null &&
			filterData.groupBy === false &&
			filterData.search === ''
		) {
			setFilterDataExist(false)
		}
	}

	function toggleDropdown() {
		setDropdownIsOpen(!dropdownIsOpen)
	}

	async function getPoses() {
		const response = await GET('/services/wms/api/wms-helper')
		if (response) {
			setPoses(response)
		}
	}

	async function getOrganizations() {
		const response = await GET('/services/web/api/organization-helper')
		if (response) {
			setOrganizations(response)
		}
	}

	async function getUoms() {
		const response = await GET('/services/wms/api/product-uom-helper')
		if (response) {
			setUoms(response)
		}
	}

	async function getCashiers() {
		const response = await GET('/services/wms/api/cashier-helper')
		if (response) {
			setCashiers(response)
		}
	}

	async function getPaymentTypes() {
		const response = await GET('/services/wms/api/payment-type-helper')
		if (response) {
			setPaymentTypes(response)
		}
	}

	async function getCategories() {
		var response = await GET('/services/web/api/product-category-helper')
		//response.unshift({ 'id': 0, 'name': t('ALL') })
		var arr = []
		for (let i = 0; i < response.length; i++) {
			response[i]['label'] = ' ' + response[i]['name']
			response[i].child = false;
			if (response[i].parentId !== 0) {
				const index = response.findIndex(x => x.id === response[i].parentId)
				response[i].child = true;
				if (response[index].options && response[index].options.length > 0) {
					response[index].options.push(response[i])
				} else {
					response[index].options = [response[i]]
				}
			} else {
				arr.push(response[i])
			}
		}
		setCategories(arr)
		setCategoriesInitial(response)
	}

	async function paginate(newPageData) {
		setData(newPageData)
	}

	useEffect(() => {
		getPoses()
		getPaymentTypes()
		getOrganizations()
		getUoms()
		getCashiers()
		getCategories()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		searchWithFilter(false)
	}, [filterData.search]) // eslint-disable-line react-hooks/exhaustive-deps

	const Option = props => {
		return (
			<div style={{
				padding: props.data.child ? '0 0 0 20px' : '0', color: '#999',
				fontWeight: props.data.parentId === 0 ? 'bold' : 'normal', fontSize: '.8rem'
			}}>
				<components.Option {...props} />
			</div>
		);
	};

	const GroupHead = props => {
		return (
			<div style={{
				padding: '0',
				color: filterData.productCategoryId === props.data.id ? '#fff' : '#999',
				fontWeight: 'bold',
				backgroundColor: filterData.productCategoryId === props.data.id ? '#2684FF' : '#fff',
				fontSize: '.8rem'
			}}
				onClick={(e) => selectCategory(props.data)}>
				<components.Option {...props} />
			</div>
		);
	};

	async function selectCategory(option) {
		setIsOpen(!isOpen)
		if (option === null) {
			getData()
			setFilterData({ ...filterData, 'productCategoryId': null })
			return
		}
		setFilterData({ ...filterData, 'productCategoryId': option.id })
	}

	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="d-flex justify-content-between flex-wrap mb-3">
						<div className="d-flex flex-wrap">
							<div>
								<FilterSearch
									filterData={filterData}
									setFilterData={setFilterData}>
								</FilterSearch>
							</div>
							<div>
								<Dropdown onToggle={toggleDropdown} show={dropdownIsOpen}>
									<Dropdown.Toggle variant="outline-primary" className="btn-wide h-100 me-2">
										{t('FILTER')}
									</Dropdown.Toggle>

									<Dropdown.Menu className="dropdown-filter">
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('POS')}</label>
												<Select
													options={poses}
													value={poses.find(option => option.id === filterData.posId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'posId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('SUPPLIER')}</label>
												<Select
													options={organiations}
													value={organiations.find(option => option.id === filterData.organizationId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'organizationId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('CASHIER')}</label>
												<Select
													options={cashiers}
													value={cashiers.find(option => option.id === filterData.cashierId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'cashierId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('PAYMENT_METHOD')}</label>
												<Select
													options={paymentTypes}
													value={paymentTypes.find(option => option.id === filterData.paymentTypeId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'paymentTypeId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('CURRENCY')}</label>
												<Select
													options={currencies}
													value={currencies.find(option => option.id === filterData.currencyId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'currencyId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
											<div className="form-group col-md-6">
												<label>{t('UNIT_OF_MEASUREMENT')}</label>
												<Select
													options={uoms}
													value={uoms.find(option => option.id === filterData.uomId) || ''}
													onChange={(option) => setFilterData({ ...filterData, 'uomId': option.id })}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.name}
													getOptionValue={(option) => option.id}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('CATEGORY')}</label>
												<Select
													isClearable
													components={{ GroupHeading: GroupHead, Option }}
													classNamePrefix="react-select"
													options={categories}
													value={categoriesInitial.find(option => option.id === filterData.productCategoryId) || ''}
													onChange={(option) => selectCategory(option)}
													placeholder=""
													noOptionsMessage={() => t('LIST_IS_EMPTY')}
													getOptionLabel={(option) => option.label}
													getOptionValue={(option) => option.id}
													onMenuOpen={() => setIsOpen(true)}
													onBlur={() => setIsOpen(false)}
													menuIsOpen={isOpen}
												/>
											</div>
										</div>
										<div className="row">
											<div className="form-group col-md-6">
												<label>{t('PERIOD')}</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.startDate ? new Date(filterData.startDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'startDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
											<div className="form-group col-md-6">
												<label className="invisible">.</label>
												<div className="calendar-input">
													<DatePicker
														className="form-control"
														dateFormat="dd.MM.yyyy"
														selected={filterData.endDate ? new Date(filterData.endDate) : ''}
														onChange={(date) => setFilterData({ ...filterData, 'endDate': formatDateBackend(date) })} />
													<i className="uil uil-calendar-alt"></i>
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-md-6">
												<div className="d-flex justify-content-between mb-2">
													<p className="fw-600 vertical-center">{t('GROUPING')}</p>
													<div className="form-check form-switch form-switch-lg">
														<input type="checkbox" className="form-check-input"
															checked={filterData.groupBy}
															onChange={(e) => setFilterData({ ...filterData, 'groupBy': e.target.checked })} />
													</div>
												</div>
											</div>
										</div>
										<div className="row mt-3">
											<div className="col-md-12">
												<div className="d-flex justify-content-end">
													<button className="btn btn-secondary me-2" onClick={() => setDropdownIsOpen(false)}>{t('CANCEL')}</button>
													<button className="btn btn-primary" onClick={() => searchWithFilter(false)}>{t('APPLY')}</button>
												</div>
											</div>
										</div>
									</Dropdown.Menu>
								</Dropdown>
							</div>
							<div>
								<button className="btn btn-outline-primary btn-wide" onClick={() => searchWithFilter(true)}>EXCEL</button>
							</div>
						</div>
						<div>
							<div className="d-flex justify-content-between">
								<div className="vertical-center me-2">
									<h4>
										<b>{t('TOTAL_AMOUNT')}</b>:
									</h4>
								</div>
								<div className="vertical-center">
									{totalAmount.map((item, index) => (
										<h5 className="text-end" key={index}>
											<span className="me-1">{formatMoney(item.totalAmount)}</span>
											<b>{item.currencyName}</b>
										</h5>
									))}
								</div>
							</div>

							<div className="d-flex justify-content-between">
								<div className="vertical-center me-2">
									<h4>
										<b>{t('TOTAL_AMOUNT_RETURN')}</b>:
									</h4>
								</div>
								<div className="vertical-center">
									{totalAmount.map((item, index) => (
										<h5 className="text-end" key={index}>
											<span className="me-1">{formatMoney(item.returnAmount)}</span>
											<b>{item.currencyName}</b>
										</h5>
									))}
								</div>
							</div>

						</div>
					</div>

					{filterDataExist &&
						<div className="filter-block">
							<div className="filter">
								<strong>{t('FILTER')}</strong>
								<br />
								{(filterData.startDate || filterData.endDate) &&
									<span className="me-2">
										<b>{t('PERIOD')}: </b>
										{filterData.startDate &&
											<span>{formatDate(filterData.startDate)}</span>
										}
										{(filterData.startDate && filterData.endDate) &&
											<span>|</span>
										}
										{filterData.endDate &&
											<span>{formatDate(filterData.endDate)}</span>
										}
									</span>
								}
								{filterData.posId &&
									<span className="me-2">
										<b>{t('POS')}: </b> {findFromArrayById(poses, filterData.posId)}
									</span>
								}
								{filterData.cashierId &&
									<span className="me-2">
										<b>{t('CASHIER')}: </b> {findFromArrayById(cashiers, filterData.cashierId)}
									</span>
								}
								{filterData.organizationId &&
									<span className="me-2">
										<b>{t('SUPPLIER')}: </b> {findFromArrayById(organiations, filterData.organizationId)}
									</span>
								}
								{filterData.productCategoryId &&
									<span className="me-2">
										<b>{t('CATEGORY')}: </b> {findFromArrayById(categoriesInitial, filterData.productCategoryId)}
									</span>
								}
								{filterData.currencyId &&
									<span className="me-2">
										<b>{t('CURRENCY')}: </b> {findFromArrayById(currencies, filterData.currencyId)}
									</span>
								}
								{filterData.paymentTypeId &&
									<span className="me-2">
										<b>{t('PAYMENT_METHOD')}: </b> {findFromArrayById(paymentTypes, filterData.paymentTypeId)}
									</span>
								}
								{filterData.groupBy &&
									<span className="me-2">
										<b>{t('GROUPING')}: </b> {t('ACTIVATED')}
									</span>
								}
								{filterData.search &&
									<span className="me-2">
										<b>{t('SEARCH')}: </b>
										<span>{filterData.search}</span>
									</span>
								}
							</div>
							<div className="vertical-center">
								<button className="btn btn-outline-dark text-uppercase" onClick={resetFilter}>{t('RESET')}</button>
							</div>
						</div>
					}

					<div className="table-responsive">
						<table className="table mb-0">
							<thead>
								<tr>
									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'posName')}>
										{t('POS')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'posName') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'posName') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'organizationName')}>
										{t('SUPPLIER')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'organizationName') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'organizationName') && 'sort-active')}></i>
										</div>
									</th>
									{(!reduxSettings.tableReportSales || reduxSettings.tableReportSales?.cashier) &&
										<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'cashierName')}>
											{t('CASHIER')}
											<div className="table-sort">
												<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'cashier') && 'sort-active')}></i>
												<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'cashier') && 'sort-active')}></i>
											</div>
										</th>
									}
									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'productName')}>
										{t('NAME_OF_PRODUCT')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'productName') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'productName') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'categoryName')}>
										{t('CATEGORY')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'categoryName') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'categoryName') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'productBarcode')}>
										{t('BARCODE')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'productBarcode') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'productBarcode') && 'sort-active')}></i>
										</div>
									</th>

									<th className="th-sortable cursor" onClick={() => searchWithFilter(false, 'quantity')}>
										{t('QUANTITY')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'quantity') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'quantity') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'salePrice')}>
										{t('SALE_PRICE')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'salePrice') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'salePrice') && 'sort-active')}></i>
										</div>
									</th>
									{(!reduxSettings.tableReportSales || reduxSettings.tableReportSales?.currencyName) &&
										<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'saleCurrencyName')}>
											{t('CURRENCY')}
											<div className="table-sort">
												<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'saleCurrencyName') && 'sort-active')}></i>
												<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'saleCurrencyName') && 'sort-active')}></i>
											</div>
										</th>
									}
									{(!reduxSettings.tableReportSales || reduxSettings.tableReportSales?.discount) &&
										<th className="text-center">{t('DISCOUNT')}</th>
									}
									<th className="text-center">
										№ {t('CHEQUE')}
									</th>
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'chequeDate')}>
										{t('CHEQUE_DATE')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'chequeDate') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'chequeDate') && 'sort-active')}></i>
										</div>
									</th>
									<th className="th-sortable cursor text-center" onClick={() => searchWithFilter(false, 'totalPrice')}>
										<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
										{t('TOTAL_AMOUNT')}
										<div className="table-sort">
											<i className={'uil uil-angle-up ' + ((filterData.tableSortDirection === ',asc' && filterData.tableSortKey === 'totalPrice') && 'sort-active')}></i>
											<i className={'uil uil-angle-down ' + ((filterData.tableSortDirection === ',desc' && filterData.tableSortKey === 'totalPrice') && 'sort-active')}></i>
										</div>
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index} className={'cursor ' + (item.showSidebar ? 'table-tr-active' : '')} onClick={() => toggleRightbar(true, item, index)}>
										<td>{item.rowNum}. {item.posName}</td>
										<td>{item.organizationName}</td>
										{(!reduxSettings.tableReportSales || reduxSettings.tableReportSales?.cashier) &&
											<td>{item.cashierName}</td>
										}
										<td>{item.productName}</td>
										<td>{item.categoryName}</td>
										<td className="text-center">{item.productBarcode}</td>

										<td className="text-center">{formatMoney(item.quantity)} {item.uomName}</td>
										<td className="text-center">{formatMoney(item.salePrice)}</td>
										{(!reduxSettings.tableReportSales || reduxSettings.tableReportSales?.currencyName) &&
											<td className="text-center">{item.saleCurrencyName}</td>
										}
										{(!reduxSettings.tableReportSales || reduxSettings.tableReportSales?.discount) &&
											<td className="text-center">{formatMoney(item.discount)}</td>
										}
										<td className="text-center">{item.chequeNumber}</td>
										<td className="text-center text-nowrap">
											{item.returned === 0 &&
												<span>
													{formatDateWithTime(item.chequeDate)}
												</span>
											}
											{item.returned === 1 &&
												<span className="bg-warning bg-border text-white cursor" title={t('PRODUCT_PARTIALLY_RETURNED')}>
													{formatDateWithTime(item.chequeDate)}
												</span>
											}
											{item.returned === 2 &&
												<span className="bg-danger bg-border text-white cursor" title={t('PRODUCT_RETURNED')}>
													{formatDateWithTime(item.chequeDate)}
												</span>
											}
										</td>
										<td className="text-end text-nowrap">
											<span className={item.clientAmount > 0 ? "text-danger" : ""} title={item.clientAmount > 0 ? t('SOLD_ON_CREDIT') : ""}>
												{formatMoney(item.totalAmount)}
											</span>
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					{pagination.response &&
						<FilterPagination
							pagination={pagination}
							paginate={paginate}>
						</FilterPagination>
					}
				</div>
			</div>

			<Rightbar showRightBar={showRightBar} setShowRightBar={setShowRightBar} selectedItem={selectedItem}></Rightbar>

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableReportSales'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Index
