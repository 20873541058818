import React from 'react'
import { Route, Routes, Outlet, Navigate } from 'react-router-dom';
import { publicRoutes, privateRoutes, adminPrivateRoutes, adminReportPrivateRoutes } from './routes'

import Layout from "../components/verticalLayout/Layout";
import NoAuthLayout from "../components/NoAuthLayout";

function createPublicRoutes() {
	return publicRoutes.map((item, key) => {
		return (
			<Route key={key} path={item.path} element={item.component} />
		);
	});
}

function createPrivateRoutes() {
	return privateRoutes.map((item, key) => {
		return (
			<Route key={key} element={<ProtectedRoute />}>
				<Route path={item.path} element={item.component} />
			</Route>
		);
	});
}

function createAdminPrivateRoutes() {
	return adminPrivateRoutes.map((item, key) => {
		return (
			<Route key={key} element={<ProtectedAdminRoute />}>
				<Route path={item.path} element={item.component} />
			</Route>
		);
	});
}

function createAdminReportPrivateRoutes() {
	return adminReportPrivateRoutes.map((item, key) => {
		return (
			<Route key={key} element={<ProtectedAdminReportRoute />}>
				<Route path={item.path} element={item.component} />
			</Route>
		);
	});
}

const ProtectedRoute = () => {
	const login = localStorage.getItem("username");
	var isAuthenticated = false;
	if (login) {
		isAuthenticated = true
	} else {
		isAuthenticated = false
	}

	if (!isAuthenticated) {
		localStorage.removeItem('username')
		localStorage.removeItem('role')
		localStorage.removeItem('token')
		return <Navigate to="/auth/login" />;
	}

	return <Outlet />;
};

const ProtectedAdminRoute = () => {
	const role = localStorage.getItem("role");

	var isAuthenticated = false;
	if (role === 'ROLE_ADMIN') {
		isAuthenticated = true
	} else {
		isAuthenticated = false
	}

	if (!isAuthenticated) {
		localStorage.removeItem('username')
		localStorage.removeItem('role')
		localStorage.removeItem('token')
		return <Navigate to="/auth/login" />;
	}

	return <Outlet />;
};

const ProtectedAdminReportRoute = () => {
	const role = localStorage.getItem("role");

	var isAuthenticated = false;
	if (role === 'ROLE_REPORT') {
		isAuthenticated = true
	} else {
		isAuthenticated = false
	}

	if (!isAuthenticated) {
		localStorage.removeItem('username')
		localStorage.removeItem('role')
		localStorage.removeItem('token')
		return <Navigate to="/auth/login" />;
	}

	return <Outlet />;
};

const Index = () => {
	return (
		<Routes>
			<Route element={<NoAuthLayout />}>
				{createPublicRoutes()}
			</Route>

			<Route element={<Layout />}>
				{createPrivateRoutes()}
			</Route>

			<Route element={<Layout />}>
				<Route path="*" element={<Navigate to="/auth/login" replace />} />
			</Route>

			<Route path="/admin" element={<Layout />}>
				<Route path="/admin" element={<Navigate to="/admin/users" replace />} />
				{createAdminPrivateRoutes()}
			</Route>

			<Route path="/aadmin" element={<Layout />}>
				<Route path="/aadmin" element={<Navigate to="/aadmin/users" replace />} />
				{createAdminReportPrivateRoutes()}
			</Route>

		</Routes>
	)
}

export default Index;