import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Search from 'pages/parts/Search'
import Pagination from 'pages/parts/Pagination'
import DeleteModal from 'pages/parts/DeleteModal'
import TableControl from 'pages/parts/TableControl'

import { PGET, DELETE } from 'helpers/api'
import { checkRole } from "helpers/helpers"

function Index() {
	const URL = "/services/wms/api/product-group-pageList"
	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)

	const canCreate = checkRole('WMS_PRODUCT_GROUP_ADD')
	const canUpdate = checkRole('WMS_PRODUCT_GROUP_EDIT')
	const canDelete = checkRole('WMS_PRODUCT_GROUP_DELETE')

	const [pageCount, setPageCount] = useState(0)
	const [modalDelete, setModalDelete] = useState(false)
	const [modalTableControl, setModalTableControl] = useState(false)
	const [deleteModalParams, setDeleteModalParams] = useState({ wmsId: 0, productGroupId: 0, index: 0 })
	const [data, setData] = useState([])

	async function getData() {
		const response = await PGET(URL)
		if (response) {
			setPageCount(Math.ceil(response.headers['x-total-count'] / reduxSettings.size))
			setData(response.data)
		}
	}

	async function deleteItem() {
		const response = await DELETE("/services/wms/api/product-group/"
			+ deleteModalParams.wmsId + '/' + deleteModalParams.productGroupId, {}, { loader: true })
		if (response) {
			getData()
			setModalDelete(false)
		}
	}

	async function paginate(data) {
		const response = await PGET(URL, { page: data.selected, size: reduxSettings.size })
		setData(response.data);
	}

	async function search(data, searchValue) {
		setData(data);
		if (searchValue.length > 0) {
			setPageCount(0)
		}
	}

	useEffect(() => {
		getData()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<h4 className="vertical-center">
					{t('GROUPING')}
				</h4>
				<div className="d-flex">
					{canCreate &&
						<Link className="btn-rounded btn btn-primary" to="/wms/grouping/create">
							<i className="uil uil-plus me-2"></i>
							{t('CREATE')}
						</Link>
					}
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="d-flex mb-3">
						<div className="me-2">
							<Search url={URL} search={search} getData={getData}></Search>
						</div>
					</div>
					<div className="table-responsive">
						<table className="table table-striped mb-0">
							<thead>
								<tr>
									{(!reduxSettings.tableGrouping || reduxSettings.tableGrouping?.pos) &&
										<th>{t('WAREHOUSE')}</th>
									}
									<th>{t('NAME_OF_PRODUCT')}</th>
									<th className="text-center">{t('BARCODE')}</th>
									<th className="text-center table-control">
										<i className="uil-setting table-control-icon" onClick={() => setModalTableControl(true)}></i>
										{t('ACTION')}
									</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										{(!reduxSettings.tableGrouping || reduxSettings.tableGrouping?.pos) &&
											<td>{item.wmsName}</td>
										}
										<td>{item.productGroupName}</td>
										<td className="text-center">{item.productGroupBarcode}</td>
										<td className="d-flex justify-content-center">
											{canUpdate &&
												<Link to={`/wms/grouping/update/${item.wmsId}/${item.productGroupId}`} className="me-2">
													<div className="table-action-button table-action-primary-button">
														<i className="uil-edit-alt"></i>
													</div>
												</Link>
											}
											{canDelete &&
												<div className="table-action-button table-action-danger-button"
													onClick={() => {
														setDeleteModalParams({ wmsId: item.wmsId, productGroupId: item.productGroupId, index: index });
														setModalDelete(true)
													}}>
													<i className="uil-times"></i>
												</div>
											}
										</td>
									</tr>
								))
								}
							</tbody>
						</table>
					</div>

					<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
				</div>
			</div>

			<DeleteModal
				modalDelete={modalDelete}
				setModalDelete={setModalDelete}
				deleteItem={deleteItem}>
			</DeleteModal>

			{/* MODAL TABLE CONTROL */}
			<TableControl
				modalTableControl={modalTableControl}
				setModalTableControl={setModalTableControl}
				keyName={'tableGrouping'}>
			</TableControl>
			{/* MODAL TABLE CONTROL */}
		</>
	)
}

export default Index
