import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import Pagination from 'pages/parts/Pagination'
import { DebounceInput } from 'react-debounce-input'
import Select from 'react-select'

import { formatMoney } from 'helpers/helpers'
import { GET, PGET } from 'helpers/api'

function CreateUpdate() {
	const { t } = useTranslation()

	const reduxSettings = useSelector(state => state.settings)

	const currencies = [{ "id": 1, "name": "So`m" }, { "id": 2, "name": "USD" }]

	const searchRef = useRef(null);

	const [pageCount, setPageCount] = useState(0)
	const [searchDataExist, setSearchDataExist] = useState(0)

	const [barcode, setBarcode] = useState(false)

	const [search, setSearch] = useState('')

	const [searchData, setSearchData] = useState([])
	const [poses, setPoses] = useState([])

	const [totalAmount, setTotalAmount] = useState({ totalIncome: 0, totalSale: 0, totalQuantity: 0 })
	const [data, setData] = useState({
		productList: [],
		error: false,
		currencyId: 1,
		exchange_rate: 12500,
		cube_price: 500,
	})

	function handleChange(e) {
		var dataCopy = { ...data }
		dataCopy[e.target.name] = e.target.value.replace(/,/g, '.').replace(/[^0-9.]/g, '')

		countTotalAmount(dataCopy)
	}

	function handleProductListChange(e, index) {
		var dataCopy = { ...data }
		dataCopy.productList[index][e.target.name] = e.target.value.replace(/,/g, '.').replace(/[^0-9.]/g, '')

		countTotalAmount(dataCopy)
	}

	function countTotalAmount(dataCopy) {
		for (let i = 0; i < dataCopy.productList.length; i++) {
			var item = dataCopy.productList[i]
			console.log(item);
			item.pieces_total = Number(item.box_quantity) * Number(item.pieces_boxes)
			item.price_usdt = Number(item.price) / 6.4
			item.total_packages_price = Number(item.box_quantity) * Number(item.price_usdt)
			item.total_packages_price_sum = Number(item.box_quantity) * Number(item.pieces_boxes) * Number(item.price)

			item.weight_total = item.box_quantity * item.weight_kg
			item.cube = (item.length * item.width * item.height) / (1000000 / item.box_quantity)
			item.travel_expenses_per_box = item.cube / item.box_quantity * Number(dataCopy.cube_price)
			item.travel_expenses_total = item.travel_expenses_per_box * item.box_quantity
			item.price_boxes_including_travel = Number(item.package_price_usdt) + Number(item.travel_expenses_per_box)
			// item.price_per_piece = item.price_boxes_including_travel / item.piece_quantity * Number(dataCopy.exchange_rate)
			item.price_arrival_usdt = Number(item.price_boxes_including_travel) / Number(item.pieces_boxes)
			item.price_arrival = Number(item.price_arrival_usdt) / Number(data.exchange_rate)
		}

		setData(dataCopy)
		setTotalAmount({ ...totalAmount })
	}

	function addToList(index, arr = [], putCursor = false) {
		var searchDataCopy;
		if (arr.length > 0) {
			searchDataCopy = arr
		} else {
			searchDataCopy = [...searchData]
		}

		if (!searchDataCopy[index].price) {
			searchDataCopy[index].price = ''
		}
		if (!searchDataCopy[index].wholesalePrice) {
			searchDataCopy[index].wholesalePrice = ''
		}
		if (!searchDataCopy[index].bankPrice) {
			searchDataCopy[index].bankPrice = ''
		}
		if (!searchDataCopy[index].salePrice) {
			searchDataCopy[index].salePrice = ''
		}
		if (data.defaultVat) {
			searchDataCopy[index].vat = data.defaultVat
		} else {
			searchDataCopy[index].vat = 0
		}
		if (searchDataCopy[index].originalPrice) {
			searchDataCopy[index].price = searchDataCopy[index].originalPrice
		}

		searchDataCopy[index].quantity = ''
		searchDataCopy[index].label = ''
		searchDataCopy[index].price_usdt = ''
		searchDataCopy[index].originalPrice = searchDataCopy[index].price
		searchDataCopy[index].originalSalePrice = searchDataCopy[index].salePrice
		searchDataCopy[index].originalWholesalePrice = searchDataCopy[index].wholesalePrice
		searchDataCopy[index].originalBankPrice = searchDataCopy[index].bankPrice
		searchDataCopy[index].checked = false
		searchDataCopy[index].serialNumbers = []

		var dataCopy = { ...data }
		dataCopy.productList.push(searchDataCopy[index])
		setData(dataCopy)

		if (index === 0 && putCursor) { // put cursor automatically
			setTimeout(() => {
				const byId = document.getElementById(dataCopy.productList.length)
				byId?.select()
			}, 200);
		}

		searchDataCopy.splice(index, 1)
		setSearchData(searchDataCopy)

		if (searchDataCopy.length === 0) {
			setSearch('')
		}
		searchRef.current.focus()
	}

	function deleteProduct(index) {
		var dataCopy = { ...data }
		dataCopy.productList.splice(index, 1)
		countTotalAmount(dataCopy)
	}

	async function showProductCreateModal(bool) {
		// if (!bool) {
		// 	reset()
		// }
		// if (uoms.length === 0) {
		// 	const response = await GET('/services/web/api/product-uom-helper')
		// 	setUoms(response)
		// }
		// if (search.length > 0) {
		// 	setValue('barcode', search)
		// } else {
		// 	setValue('barcode', '')
		// }
		// setModalProduct(bool)
	}

	async function searchProduct(value) {
		setSearchDataExist(0)
		setSearch(value)
		if (value.length === 0) {
			setSearchData([])
			setSearchDataExist(0)
		} else {
			const response = await PGET(
				"/services/web/api/product-in-helper",
				{ 'name': value, 'posId': data.posId, 'barcode': barcode, 'currencyId': data.currencyId },
				{ loader: true }
			)
			setPageCount(Math.ceil(response.headers['x-total-count'] / 20))

			var dataCopy = { ...data }
			var responseCopy = [...response.data]
			var responseCopy2 = [...response.data]
			for (let i = 0; i < responseCopy.length; i++) {
				for (let j = 0; j < dataCopy.productList.length; j++) {
					if (
						responseCopy[i]['productId'] ===
						dataCopy.productList[j]['productId']
					) {
						if (responseCopy.length === 1) {
							setSearchDataExist(2)
							setSearch('')
							return;
						}
						responseCopy2 = responseCopy2.filter(e => e.productId !== dataCopy.productList[j]['productId'])
					}
				}
			}
			if (responseCopy2.length === 0) {
				setSearchData([])
				setSearchDataExist(1)
				return
			}

			if (/^\d+$/.test(value) && responseCopy2.length === 1) {
				setSearchData(responseCopy2)
				if (responseCopy2[0]['productShow']) {
					addToList(0, responseCopy2, true)
					setSearch('')
				}
			} else {
				setSearchData(responseCopy2)
			}
		}
	}

	async function paginate(pageNumber) {
		const response = await PGET('/services/web/api/product-in-helper',
			{ 'name': search, 'posId': data.posId, 'barcode': barcode, 'currencyId': data.currencyId, page: pageNumber.selected, size: 20 }
		)
		setSearchData(response.data);
	}

	async function getPoses() {
		const temporaryPoses = await GET('/services/web/api/pos-helper')
		if (temporaryPoses) {
			setPoses(temporaryPoses)
			setData({ ...data, posId: temporaryPoses[0]['id'] })
		}
	}

	useEffect(() => {
		getPoses()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps	

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-2">
				<div className="d-flex text-truncate">
					<h4 className="vertical-center">
						{t('GOOD_RECEPTION_PACKAGE')}
					</h4>
					<div className="vertical-center mt-1">
						<i className="uil uil-angle-double-right fz-20"></i>
					</div>
					<div className="vertical-center mt-1">
						<h6>{t('CREATE')}</h6>
					</div>
				</div>
			</div>

			<div className="card mb-3">
				<div className="card-body position-relative">
					<div className="row">
						<div className="col-md-2">
							<div className="form-group">
								<label>{t('POS')}<span className="required-mark">*</span></label>
								<Select
									isDisabled={data.productList.length > 0 || searchData.length > 0}
									options={poses}
									value={poses.find(option => option.id === data.posId)}
									onChange={(option) => setData({ ...data, 'posId': option.id })}
									placeholder=""
									noOptionsMessage={() => t('LIST_IS_EMPTY')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								<label>{t('CURRENCY')}<span className="required-mark">*</span></label>
								<Select
									isDisabled={data.productList.length > 0 || searchData.length > 0}
									options={currencies}
									value={currencies.find(option => option.id === data.currencyId)}
									onChange={(option) => setData({ ...data, 'currencyId': option.id })}
									placeholder=""
									noOptionsMessage={() => t('LIST_IS_EMPTY')}
									getOptionLabel={(option) => option.name}
									getOptionValue={(option) => option.id}
								/>
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								<label>Валюты курс<span className="required-mark">*</span></label>
								<input type="text" className="form-control" name="exchange_rate" value={data.exchange_rate}
									onChange={(e) => handleChange(e)} />
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group">
								<label>Цена куба<span className="required-mark">*</span></label>
								<input type="text" className="form-control" name="cube_price" value={data.cube_price}
									onChange={(e) => handleChange(e)} />
							</div>
						</div>

					</div>
				</div>
			</div>

			<div className="card">
				<div className="card-body">
					<div className="table-responsive mb-3">
						<table className="table table-striped tr-nowrap mb-0">
							<thead>
								<tr>
									<th>{t('NAME_OF_PRODUCT')}</th>
									<th className="text-center">Кол-во коробок</th>  {/* box_quantity */}
									<th className="text-center">Штук всего</th> {/* pieces_total */}
									<th className="text-center">Штук в коробках</th> {/* pieces_boxes */}
									<th className="text-center">Цена юань</th> {/* price */}
									<th className="text-center">Цена $</th> {/* price_usdt */}
									<th className="text-center">Цена коробок ($)</th> {/* box_price_usdt */}
									<th className="text-center">Цена коробок Всего</th> {/* box_price_total */}
									<th className="text-center">Цена коробок Всего на ЮАНЬ</th> {/* box_price */}
									<th className="text-center">Вес кг</th> {/* weight */}
									<th className="text-center">Вес Всего</th> {/* weight_total */}
									<th className="text-center">Длина</th> {/* length  */}
									<th className="text-center">Ширина</th> {/* width */}
									<th className="text-center">Высота</th> {/* height */}
									<th className="text-center">КУБ</th> {/* cube */}
									<th className="text-center">Дорожные расходы на коробок</th> {/* travel_expenses_per_box */}
									<th className="text-center">Дорожные расходы  Всего</th> {/* travel_expenses_total */}
									<th className="text-center">Цена коробок с учетом дорожных</th> {/* price_boxes_including_travel */}
									<th className="text-center">Цена поступление $</th> {/* price_arrival_usdt */}
									<th className="text-center">Оптом цена</th> {/* wholesale_price_usdt */}
									<th className="text-center">Цена перечисления</th> {/* listing_price_usdt */}
									<th className="text-center">Цена за единицу</th> {/* price_per_piece_usdt */}
									<th className="text-center">Цена поступление Sum</th> {/* price_arrival */}
									<th className="text-center">Оптом цена</th> {/* wholesale_price */}
									<th className="text-center">Цена перечисления</th> {/* listing_price */}
									<th className="text-center">Цена за единицу</th> {/* price_per_piece */}
									<th className="text-center table-control">
										<i className="uil-setting table-control-icon"></i>
									</th>
								</tr>
							</thead>
							<tbody>
								{data.productList.map((item, index) => (
									<tr key={index} className="vertical-middle tr-nowrap tr-center">
										{/* pieces_total =  box_quantity * pieces_boxes */}
										<td>{index + 1}. {item.name}</td>
										<td>
											<input type="text" className="auto-width-input" name="box_quantity" value={item.box_quantity}
												onChange={(e) => handleProductListChange(e, index)} />
										</td>
										<td>
											{item.pieces_total}
										</td>
										<td>
											<input type="text" className="auto-width-input" name="pieces_boxes" value={item.pieces_boxes}
												onChange={(e) => handleProductListChange(e, index)} />
										</td>
										<td>
											<input type="text" className="auto-width-input" name="price" value={item.price}
												onChange={(e) => handleProductListChange(e, index)} />
										</td>
										<td>{formatMoney(item.price_usdt, 2)}</td>
										<td>
											<input type="text" className="auto-width-input" name="package_price_usdt" value={item.package_price_usdt}
												onChange={(e) => handleProductListChange(e, index)} />
										</td>
										<td>{formatMoney(item.total_packages_price)}</td>
										<td>{formatMoney(item.total_packages_price_sum)}</td>
										<td>
											<input type="text" className="auto-width-input" name="weight_kg" value={item.weight_kg}
												onChange={(e) => handleProductListChange(e, index)} />
										</td>
										<td>{`${item.weight_total ?? 0}`}</td>
										<td>
											<input type="text" className="auto-width-input" name="length" value={item.length}
												onChange={(e) => handleProductListChange(e, index)} />
										</td>
										<td>
											<input type="text" className="auto-width-input" name="width" value={item.width}
												onChange={(e) => handleProductListChange(e, index)} />
										</td>
										<td>
											<input type="text" className="auto-width-input" name="height" value={item.height}
												onChange={(e) => handleProductListChange(e, index)} />
										</td>
										<td>{formatMoney(item.cube, 4)}</td>
										<td>{formatMoney(item.travel_expenses_per_box)}</td>
										<td>{formatMoney(item.travel_expenses_total)}</td>
										<td>{formatMoney(item.price_boxes_including_travel)}</td>
										<td>{formatMoney(item.price_arrival_usdt)}</td>
										<td>
											<input type="text" className="auto-width-input" name="wholesale_price_usdt" value={item.wholesale_price_usdt}
												onChange={(e) => handleProductListChange(e, index)} />
										</td>
										<td>
											<input type="text" className="auto-width-input" name="listing_price_usdt" value={item.listing_price_usdt}
												onChange={(e) => handleProductListChange(e, index)} />
										</td>
										<td>
											<input type="text" className="auto-width-input" name="price_per_piece_usdt" value={item.price_per_piece_usdt}
												onChange={(e) => handleProductListChange(e, index)} />
										</td>
										<td>{item.price_arrival}</td>
										<td>
											<input type="text" className="auto-width-input" name="wholesale_price" value={item.wholesale_price}
												onChange={(e) => handleProductListChange(e, index)} />
										</td>
										<td>
											<input type="text" className="auto-width-input" name="listing_price" value={item.listing_price}
												onChange={(e) => handleProductListChange(e, index)} />
										</td>
										<td>
											<input type="text" className="auto-width-input" name="price_per_piece" value={item.price_per_piece}
												onChange={(e) => handleProductListChange(e, index)} />
										</td>
										<td>
											{/* <div className="d-flex justify-content-center gap-2">
												<div className="table-action-button table-action-danger-button" onClick={() => deleteProduct(index)}>
													<i className="uil uil-times"></i>
												</div>
											</div> */}
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>

					<div className="row">
						<div className="col-md-6 vertical-center">
							<div className="form-group position-relative m-0">
								<DebounceInput
									inputRef={searchRef}
									disabled={!data.posId}
									tabIndex={data.productList.length * 10 + 7}
									//tabIndex={1}
									value={search}
									onChange={(e) => searchProduct(e.target.value)}
									onKeyUp={(e) => {
										if (e.keyCode === 13) {
											e.preventDefault()
											searchProduct(e.target.value)
										}
									}}
									type="text"
									className="form-control"
									placeholder={t('SEARCH') + '...'}
									debounceTimeout={1000}
								/>
								{(!reduxSettings?.ofd) &&
									<div className="table-action-button table-action-primary-button input-plus-icon me-2" onClick={() => showProductCreateModal(true)}>
										<i className="uil uil-plus"></i>
									</div>
								}
								{searchData.length > 0 ?
									<i className="uil uil-times search-barcode" onClick={() => { setSearchData([]); setSearch('') }}></i>
									:
									<i className={'uil uil-qrcode-scan search-barcode ' + (barcode && 'text-primary')} onClick={() => setBarcode(!barcode)}></i>
								}

								{searchData.length > 0 &&
									<span className="dropdown-search-menu">
										<div className="dropdown-menu-list">
											<span className="dropdown-menu-result">
												<div className="table-responsive p-3">
													<table className="table-striped table">
														<thead>
															<tr>
																<th>{t('NAME_OF_PRODUCT')}</th>
																<th className="text-center">{t('BARCODE')}</th>
																<th className="text-center">{t('QUANTITY')}</th>
																<th className="text-center">{t('ACTION')}</th>
															</tr>
														</thead>
														<tbody>
															{searchData.map((item, index) => (
																<tr key={index}>
																	<td>{item.name}</td>
																	<td className="text-center">{item.barcode}</td>
																	<td className="text-center">{item.balance} {item.uomName}</td>
																	{item.productShow ?
																		<td className="d-flex justify-content-center" onClick={() => addToList(index)}>
																			<div className="table-action-button table-action-primary-button">
																				<i className="uil uil-plus"></i>
																			</div>
																		</td>
																		:
																		<td>
																			<div className="text-center text-danger">{t('CHOOSE_ANOTHER_CURRENCY')}</div>
																		</td>
																	}
																</tr>
															))
															}
														</tbody>
													</table>

													<Pagination pageCount={pageCount} paginate={paginate}></Pagination>
												</div>
											</span>
										</div>
									</span>
								}
								{(searchData.length === 0 && searchDataExist === 1) &&
									<span className="dropdown-search-menu">
										<div className="dropdown-menu-list d-flex justify-content-between p-2">
											<span className="dropdown-menu-result fz20">{t('PRODUCT_NOT_FOUND')}</span>
										</div>
									</span>
								}
								{(searchData.length >= 0 && searchDataExist === 2) &&
									<span className="dropdown-search-menu">
										<div className="dropdown-menu-list d-flex justify-content-between p-2">
											<span className="dropdown-menu-result fz20">{t('PRODUCT_ADDED')}</span>
										</div>
									</span>
								}

							</div>
						</div>
						{/* <div className="col-md-6 d-flex justify-content-end">
							<div className="flex-column">
								<h5 className="d-flex justify-content-between mb-1"><strong className="me-2">{t('TOTAL_QUANTITY')}:</strong>{formatMoney(totalAmount.totalQuantity)}</h5>
								<h5 className="d-flex justify-content-between mb-1"><strong className="me-2">{t('RECEIPT_AMOUNT')}:</strong>{formatMoney(totalAmount.totalIncome)} {data.currencyName}</h5>
								<h5 className="d-flex justify-content-between"><strong className="me-2">{t('SALE_AMOUNT')}:</strong>{formatMoney(totalAmount.totalSale)} {data.currencyName}</h5>
							</div>
						</div> */}
					</div>
				</div>
			</div>
		</>
	)
}

export default CreateUpdate