import React from 'react'
import Barcode from 'react-barcode'
//import { useSelector } from 'react-redux'
import { todayDate, formatMoney } from 'helpers/helpers'

function PriceTag40x30({
	data,
	selectedPriceTag,
	selectPriceTag,
	printProducts,
	printRef,
	activatePrice,
	cashbox,
	a4 = false,
	activeDate = true,
}) {

	return (
		<>
			<div className="d-flex flex-wrap">
				<div className="price_tag_1 mb-3 me-3" onClick={() => selectPriceTag('priceTag40x30_1')} >
					<div className={"price_tag_body_40x30 price-tag-border " + (selectedPriceTag.priceTag40x30_1 && 'border-primary')}>
						<div className="fz6 text-center">{data.posName}</div>
						<div className="vertical-center">
							<div className="price_tag_body_40x30_1_name">{data.productName}</div>
						</div>
						<div className="d-flex justify-content-center price_tag_body_40x30_1_price">
							<div className={'d-flex ' + (formatMoney(data.salePrice, 0).length > 7 ? "fz18" : "fz24")}>
								<div className="vertical-center me-1">
									<span className="fw-700 text-nowrap">
										{formatMoney(data.salePrice, 0)}
									</span>
								</div>
							</div>
							<div className={'vertical-center ' + (formatMoney(data.salePrice, 0).length > 7 ? "fz18" : "fz24")}>
								<b>{cashbox.defaultCurrency}</b>
							</div>
						</div>
						<div className="d-flex justify-content-center barcode">
							<div>
								<Barcode value={data.barcode} width={1} height={16} background="transparent" textMargin={0}
									fontOptions="bold" fontSize={10} />
							</div>
						</div>
						<div className="fz8 date">{todayDate()}</div>
					</div>
				</div>

				<div className="price_tag_1 mb-3 me-3" onClick={() => selectPriceTag('priceTag40x30_2')} >
					<div className={"price_tag_body_40x30 price-tag-border " + (selectedPriceTag.priceTag40x30_2 && 'border-primary')}>
						<div className="fz6 text-center">{data.posName}</div>
						<div className="vertical-center">
							<div className="price_tag_body_40x30_2_name">{data.productName}</div>
						</div>
						<div className="d-flex justify-content-center price_tag_body_40x30_2_price">
							<div className={'d-flex ' + (formatMoney(data.salePrice, 0).length > 7 ? "fz18" : "fz24")}>
								<div className="vertical-center me-1">
									<span className="fw-700 text-nowrap">
										{formatMoney(data.salePrice, 0)}
									</span>
								</div>
							</div>
							<div className={'vertical-center ' + (formatMoney(data.salePrice, 0).length > 7 ? "fz18" : "fz24")}>
								<b>{cashbox.defaultCurrency}</b>
							</div>
						</div>
						<div className="fz10 date">{todayDate()}</div>
					</div>
				</div>

				<div className="price_tag_1 mb-3 me-3" onClick={() => selectPriceTag('priceTag40x30_3')} >
					<div className={"price_tag_body_40x30 price-tag-border " + (selectedPriceTag.priceTag40x30_3 && 'border-primary')}>
						<div className="fz6 text-center">{data.posName}</div>
						<div className="vertical-center mb-2">
							<div className="price_tag_body_40x30_1_name">{data.productName}</div>
						</div>
						<div className="d-flex justify-content-center barcode">
							<div>
								<Barcode value={data.barcode} width={1} height={16} background="transparent" textMargin={0}
									fontOptions="bold" fontSize={10} />
							</div>
						</div>
						<div className="fz8 date">{todayDate()}</div>
					</div>
				</div>
			</div>

			<div className="d-none">
				{selectedPriceTag.priceTag40x30_1 &&
					<div id="40x30" ref={printRef} className={"price-tag-wrap " + (a4 ? 'a4 ' : ' ') + (activeDate ? ' ' : 'hide-date ')}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_40x30 page-breaker" key={index} >
								<div className="fz6 text-center">{product.posName}</div>
								<div className="vertical-center">
									<div className="price_tag_body_40x30_1_name">{product.productName}</div>
								</div>
								<div className="d-flex justify-content-center price_tag_body_40x30_1_price">
									<div className={'d-flex ' + (formatMoney(product.salePrice, 0).length > 7 ? "fz18" : "fz24")}>
										<div className="vertical-center me-1">
											<span className="fw-700 text-nowrap">
												{formatMoney(product.salePrice, 0)}
											</span>
										</div>
									</div>
									<div className={'vertical-center ' + (formatMoney(product.salePrice, 0).length > 7 ? "fz18" : "fz24")}>
										<b>{cashbox.defaultCurrency}</b>
									</div>
								</div>
								<div className="d-flex justify-content-center barcode">
									<div>
										<Barcode value={product.barcode} width={1} height={16} background="transparent" textMargin={0}
											fontOptions="bold" fontSize={10} />
									</div>
								</div>
								<div className="fz8 date">{todayDate()}</div>
							</div>
						))
						}
					</div>
				}

				{selectedPriceTag.priceTag40x30_2 &&
					<div id="40x30" ref={printRef} className={"price-tag-wrap " + (a4 ? 'a4 ' : ' ') + (activeDate ? ' ' : 'hide-date ')}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_40x30 page-breaker" key={index} >
								<div className="fz6 text-center">{product.posName}</div>
								<div className="vertical-center">
									<div className="price_tag_body_40x30_2_name">{product.productName}</div>
								</div>
								<div className="d-flex justify-content-center price_tag_body_40x30_2_price">
									<div className={'d-flex ' + (formatMoney(product.salePrice, 0).length > 7 ? "fz18" : "fz24")}>
										<div className="vertical-center me-1">
											<span className="fw-700 text-nowrap">
												{formatMoney(product.salePrice, 0)}
											</span>
										</div>
									</div>
									<div className={'vertical-center ' + (formatMoney(product.salePrice, 0).length > 7 ? "fz18" : "fz24")}>
										<b>{cashbox.defaultCurrency}</b>
									</div>
								</div>
								<div className="fz10 date">{todayDate()}</div>
							</div>
						))
						}
					</div>
				}

				{selectedPriceTag.priceTag40x30_3 &&
					<div id="40x30" ref={printRef} className={"price-tag-wrap " + (a4 ? 'a4 ' : ' ') + (activeDate ? ' ' : 'hide-date ')}>
						{printProducts.map((product, index) => (
							<div className="price_tag_body_40x30 page-breaker" key={index} >
								<div className="fz6 text-center">{product.posName}</div>
								<div className="vertical-center mb-2">
									<div className="price_tag_body_40x30_1_name">{product.productName}</div>
								</div>
								<div className="d-flex justify-content-center barcode">
									<div>
										<Barcode value={product.barcode} width={1} height={16} background="transparent" textMargin={0}
											fontOptions="bold" fontSize={10} />
									</div>
								</div>
								<div className="fz8 date">{todayDate()}</div>
							</div>
						))
						}
					</div>
				}
			</div>
		</>
	)
}

export default PriceTag40x30