export let exportMenuAdmin = [
	{
		icon: 'sidebar-icon uil-chart-bar',
		id: 1,
		children: [
			{
				url: "/admin/pos",
				i18n: "POS",
				icon: 'uil-shop',
				active: true,
			},
			{
				url: "/admin/clients",
				i18n: "CLIENTS",
				icon: 'uil-users-alt',
				active: false,
			},
			{
				url: "/admin/products",
				i18n: "PRODUCTS",
				icon: 'uil-shopping-cart',
				active: false,
			},
		]
	},
]